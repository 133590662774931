import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'sepa/framework/src/lib/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalVisibilitySource = new BehaviorSubject<boolean>(false);
  modalVisibility$ = this.modalVisibilitySource.asObservable();

  private modalMessageSource = new BehaviorSubject<string | null>(null);
  modalMessage$ = this.modalMessageSource.asObservable();

  constructor(private authService: AuthenticationService) {
    this.authService.userLoggedIn$.subscribe((isLoggedIn) => {
      if (!isLoggedIn) {
        this.hideModal();
      }
    });
  }

  showModal(message: string) {
    if (this.authService.isLoggedIn()) {
      this.modalMessageSource.next(message);
      this.modalVisibilitySource.next(true);
    }
  }

  hideModal() {
    this.modalVisibilitySource.next(false);
    this.modalMessageSource.next(null);
  }
}
