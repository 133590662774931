import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdleTimeoutService } from 'src/app/services/idle-timeout.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  display: boolean = false;
  message: string | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    private modalService: ModalService,
    private idleTimeoutService: IdleTimeoutService
  ) {}

  ngOnInit(): void {
    this.modalService.modalVisibility$
      .pipe(takeUntil(this.destroy$))
      .subscribe((visible) => {
        this.display = visible;
      });

    this.modalService.modalMessage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((message) => {
        this.message = message;
      });
  }

  onClose() {
    this.modalService.hideModal();
  }

  onLogout() {
    this.idleTimeoutService.stopIdleTimeout();
    this.idleTimeoutService.logout();
    this.onClose();
  }

  onExtendSession() {
    this.idleTimeoutService.extendSession();
    this.onClose();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
