import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
  constructor(
    public http : ApiHttpService,
  ) { }


  getAllAdminUsers(body){
    const url = apis.people.allAdminUsers;
    return this.http.post(url, body);
  }

  create(body){
    let bodyFinal = {
      "person": {
        "firstName": {
          "name": body.firstName,
          "order": 0,
          "abbreviation": "string",
          "language": "string",
          "native": "string",
          "suffix": "string",
          "prefix": "string"
        },
        "lastName": {
          "name": body.lastName,
          "order": 0,
          "abbreviation": "string",
          "language": "string",
          "native": "string",
          "suffix": "string",
          "prefix": "string"
        },
        "fullName": body.firstName + " " + body.lastName,
        "groupId": body.groupId,
        "password": body.password,
        "email": body.email
      }
    }
    const url = apis.people.create;
    return this.http.post(url, bodyFinal);
  }
  
  updateUserDetails(body){
    const url = apis.people.updateUserDetails;
    return this.http.put(url, body);
  }

  revokeComponentAccessRight(body){
    body.application = "admin_wallet";
    const url = apis.people.revokeComponentAccessRight;
    return this.http.post(url, body);
  }

  addComponentAccessRight(body){
    body.application = "admin_wallet";
    const url = apis.people.addComponentAccessRight;
    return this.http.post(url, body);
  }

}
