import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { DepositWithdrawTypes } from "../enums/deposit-withdraw/deposit-withraw-types.enum";
import { DepositTypes } from "../enums/deposit-withdraw/deposit-types.enum";
import { WalletService } from "./wallet.service";

@Injectable({
  providedIn: "root",
})
export class DepositWithdrawService {
  activeType = new BehaviorSubject(DepositWithdrawTypes.DEFAULT);
  activeDepositType = new BehaviorSubject(DepositTypes.DEFAULT);
  isBankAccountOnEdit = new BehaviorSubject(false);

  currencies = new BehaviorSubject<any[]>([]);

  // For crypto flows
  publicId = "";

  constructor(private walletService: WalletService) {}

  clearData() {
    this.walletService.depositCardData = {};
    this.walletService.withdrawCardData = {};
    this.walletService.bankTransferData = {};
    this.publicId = "";
  }

  getCurrencies(type: string) {
    this.walletService.getCurrencies(type).subscribe({
      next: (response) => {
        this.currencies.next(response.value);
      },
    });
  }
}
