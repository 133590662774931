import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  ApiEndpoint,
  HttpMethod,
  HttpRequestData,
} from "../models/backend-api.model";
import { Observable, throwError } from "rxjs";
import { STATIC_DI } from "../staticdi";
import { KYCConfirmation } from "../models/verification-status.model";
import { Store } from "./store.service";
import * as uuid from "uuid";

@Injectable({
  providedIn: "root",
})
export class BackendApiService {
  env: any = STATIC_DI.ENVIRONMENT;
  initProvider: any;
  options: any = {};
  apiEndpoints: any = {};
  apimToken = '';
  userToken = '';

  constructor(private http: HttpClient, private store: Store) {
    this.store.userToken$.subscribe(token => {
      this.userToken = token;
      this.setOptions();
    });
   }

  initApi() {
    this.setOptions();
    this.setApiEndpoints();
    this.getApiManagerToken().subscribe({
      next: (response) => {
        this.apimToken = "Bearer " + response.access_token;
        localStorage.setItem('apimToken', this.apimToken);
        this.store.updateLocalStorage('apimToken', this.apimToken);
        this.setOptions();
        this.setApiEndpoints();
      }
    });
  }

  setOptions() {
    const apimToken = this.store.apimToken;
    const userToken = this.store.userToken;
    const tenantId = this.store.tenantId;
    const ipAddress = this.store.ipAddress;

    const baseHeaders = new HttpHeaders({
      Authorization: apimToken || '',
      tenantId: tenantId || '',
      "X-Forwarded-For": ipAddress || '',
      token: userToken || ''
    });

    const baseOption = {
      headers: baseHeaders,
    };

    this.options = {
      httpOption: {
        ...baseOption,
        headers: baseHeaders.set("Content-Type", "application/json"),
      },
      loginOption: {
        headers: baseHeaders.set("Content-Type", "application/json"),
      },
      httpOptionsApim: {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(this.env.apiManagerKey),
        }),
      },
      documentOption: {
        headers: baseHeaders,
      },
      downloadDocumentOption: {
        ...baseOption,
        responseType: "blob",
      },
      sessionOption: (token: string) => ({
        headers: baseHeaders.set("token", token),
      }),
    };
  }
  

  setApiEndpoints() {
    this.apiEndpoints = {
      createAccount: {
        sendCode: new ApiEndpoint(
          `customer/:apiVer/addCredentials`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        reSendCode: new ApiEndpoint(
          `customer/:apiVer/resendCredentialsCode`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        getCountryList: new ApiEndpoint(
          `countrydata/:apiVer/listData`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "countryVersion"
        ),
        getCredentials: new ApiEndpoint(
          `customer/:apiVer/getCredentials`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        verifyCredentials: new ApiEndpoint(
          `customer/:apiVer/verifyCredentials`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        resetCredential: new ApiEndpoint(
          `customer/:apiVer/resetCredential`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        recoverCredentials: new ApiEndpoint(
          `customer/:apiVer/recoverCredentials`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        forgetPassword: (data: any) =>
          new ApiEndpoint(
            "user/forgotPassword",
            HttpMethod.Post,
            this.options.httpOption,
            null,
            data
          ),
        createCustomer: new ApiEndpoint(
          `customer/:apiVer/create`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        createBusinessAccount: new ApiEndpoint(
          `customer/:apiVer/organization/create`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        getCustomerById: (customerId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/getDetailsById/${customerId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        updateCustomer: (customerId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/update/${customerId}`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
      },
      settings: {
        getAuthenticationKey: (customerId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/twoFA/twoFACredentials/${customerId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        verifyAuthenticationKey: (customerId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/twoFA/validateToken/${customerId}`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        update2FA: (customerId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/twoFA/changeTwoFa/${customerId}`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
      },
      profile: {
        personalDetails: {
          getCustomerById: (customerId: any) =>
            new ApiEndpoint(
              `customer/:apiVer/getDetailsById/${customerId}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          getSepaId: (customerId: any) =>
            new ApiEndpoint(
              `customer/:apiVer/sepaId/${customerId}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          getSepaIdByChannel: (customerId: any, channel: string) =>
            new ApiEndpoint(
              `customer/:apiVer/sepaId/${customerId}/by/${channel}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          updateKycConfirmationStatus: (
            customerId: string,
            kycStatus: KYCConfirmation
          ) =>
            new ApiEndpoint(
              `customer/:apiVer/update/${customerId}/kycConfirmation/${kycStatus}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          getCustomerByOrgId: (orgId: string) =>
            new ApiEndpoint(
              `customer/:apiVer/getByOrganizationId/${orgId}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
        },
      },
      accounts: {
        login: new ApiEndpoint(
          `customer/:apiVer/login`,
          HttpMethod.Post,
          this.options.loginOption,
          ["apiVer"],
          ["email", "password"],
          null,
          false,
          "customerVersion"
        ),
        apiManager: new ApiEndpoint(
          "oauth2/token",
          HttpMethod.Post,
          this.options.httpOptionsApim,
          null
        ),
        getWalletId: (customerId: any) => {
          return new ApiEndpoint(
            `wallets/:apiVer/customer/:apiVer/${customerId}/includeChild/true`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
      },
      dashboard: {
        getasset: (customerId: any) => {
          return new ApiEndpoint(
            `wallets/:apiVer/customer/:apiVer/${customerId}/includeChild/true`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        createwallet: new ApiEndpoint(
          `wallets/:apiVer`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "walletVersion"
        ),
        getAssetByCustomer: (customerId: any) => {
          return new ApiEndpoint(
            `wallets/:apiVer/customer/:apiVer/${customerId}/includeChild/true`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        wallets: (walletId: any) => {
          return new ApiEndpoint(
            walletId,
            HttpMethod.Get,
            this.options.httpOption
          );
        },
        createasset: (walletId: any, data: any) => {
          return new ApiEndpoint(
            `wallets/:apiVer/${walletId}/asset`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            data,
            null,
            false,
            "walletVersion"
          );
        },
        getAssetsByType: (walletId: any, assetType: any) => {
          return new ApiEndpoint(
            `${walletId}/asset/${assetType}`,
            HttpMethod.Get,
            this.options.httpOption
          );
        },
      },

      business: {
        getOrgById: (orgId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/getById/${orgId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        updateOrg: (orgId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/update/${orgId}`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        createAddress: (orgId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/createAddress/${orgId}`,
            HttpMethod.Patch,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        getAddress: (orgId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/getAddress/${orgId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        createDocument: (orgId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/document/create/${orgId}`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        uploadDocument: () =>
          new ApiEndpoint(
            `aml/:apiVer/document/upload`,
            HttpMethod.Post,
            this.options.documentOption,
            ["apiVer"],
            null,
            null,
            false,
            "amlVersion"
          ),
        updateDocument: (docid: string) =>
          new ApiEndpoint(
            `aml/:apiVer/document/update/${docid}`,
            HttpMethod.Put,
            this.options.documentOption,
            ["apiVer"],
            null,
            null,
            false,
            "amlVersion"
          ),
        getDocuments: (orgId: string, purpose: string) =>
          new ApiEndpoint(
            `aml/:apiVer/get-documents/${purpose}/${orgId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "amlVersion"
          ),
        createEntity: (organizationId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/entity/create/${organizationId}`,
            HttpMethod.Patch,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        getEntities: (orgId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/entity/${orgId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        updateEntity: (organizationId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/entity/update/${organizationId}`,
            HttpMethod.Patch,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        deleteEntity: (shareHolderId: any) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/entity/${shareHolderId}`,
            HttpMethod.Delete,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        assignGroupToUser: new ApiEndpoint(
          `customer/:apiVer/addComponentAccessRight`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        getAllGroup: new ApiEndpoint(
          `customer/:apiVer/getAllGroup`,
          HttpMethod.Post,
          this.options.httpOption,
          ["apiVer"],
          null,
          null,
          false,
          "customerVersion"
        ),
        updateCustomerStatus: (customerId: any, status: string) =>
          new ApiEndpoint(
            `customer/:apiVer/update/${customerId}/status/${status}`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        updateKybStatus: (orgId: string, section: string, status: string) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/updateKYBStatus/${orgId}/${section}/${status}`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        updateGeneralStatus: (orgId: string, status: string) =>
          new ApiEndpoint(
            `customer/:apiVer/organization/updateStatus/${orgId}/${status}`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
      },
      accessGroups: {
        getAllGroups: () =>
          new ApiEndpoint(
            `customer/:apiVer/getAllGroup`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        getAccessControl: () =>
          new ApiEndpoint(
            `customer/:apiVer/getAccessControl`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        addGroup: () =>
          new ApiEndpoint(
            `customer/:apiVer/addGroup`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        updateGroup: (groupId?: string) =>
          new ApiEndpoint(
            `customer/:apiVer/updateGroup/${groupId}`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
      },

      activeSessions: {
        getActiveSessions: (customerId: string) =>
          new ApiEndpoint(
            `customer/:apiVer/activeSession/${customerId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        logoutActiveSession: (customerId: string, sessionId: string) =>
          new ApiEndpoint(
            `customer/:apiVer/activeSession/${customerId}/logout/${sessionId}`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        logoutAllActiveSessions: (customerId: string) =>
          new ApiEndpoint(
            `customer/:apiVer/activeSession/${customerId}/logoutAll`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
      },

      wallet: {
        createWallet: () => {
          return new ApiEndpoint(
            "wallet",
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getWalletByCustomerId: (customerId: any) => {
          return new ApiEndpoint(
            `wallet/:apiVer/customer/${customerId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },

        getWalletByOrgId: (orgId: any) => {
          return new ApiEndpoint(
            `wallet/:apiVer/b2b-customers/${orgId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getAssetAvailableBalance: (
          walletId: string,
          assetId: string,
          percentage: string
        ) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/asset/${assetId}/available?percentage=${percentage}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },

        createAsset: (walletId: string) => {
          return new ApiEndpoint(
            "wallet/:apiVer/" + walletId + "/asset",
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getBalanceAggregate: () => {
          return new ApiEndpoint(
            "wallet/:apiVer/dashboard/total-balance/aggregates",
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getBalanceSeries: () => {
          return new ApiEndpoint(
            "wallet/:apiVer/dashboard/total-balance/series",
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },

        getCurrencyPairs: (walletId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/currency-pairs/${walletId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getCurrency: () => {
          return new ApiEndpoint(
            "wallet/:apiVer/getAll/currencies",
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            ["type"],
            null,
            false,
            "walletVersion"
          );
        },

        createOrder: (walletId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/order`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getOpenOrders: (walletId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/openedOrders`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },

        getOrderHistory: (walletId: string, time: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/orderHistory?time=${time}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getFunds: (walletId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/asset/funds`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getTradeHistory: (walletId: string, time: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/tradeHistory?time=${time}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },

        getOrders: (walletId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/orders`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getTransactions: (walletId: string) => {
          return new ApiEndpoint(
            "wallets/" + walletId + "/transactions",
            HttpMethod.Get,
            this.options.httpOption
          );
        },
        cancelOrder: (walletId: string, orderId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/${walletId}/order/${orderId}/cancel`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getBankDetailByCustomer: (customerId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/misc/customer/bankAccount/${customerId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        getBankDetailByTenant: () => {
          return new ApiEndpoint(
            `wallet/:apiVer/misc/tenant/bankAccount`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        createBankDetail: (walletId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/misc`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        updateBankDetail: (walletId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/misc/${walletId}`,
            HttpMethod.Put,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
        deleteBankDetail: (bankAccountId: string) => {
          return new ApiEndpoint(
            `wallet/:apiVer/misc/${bankAccountId}`,
            HttpMethod.Delete,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "walletVersion"
          );
        },
      },

      ipg: {
        getTerminalInformation: (orgId: string) => {
          return new ApiEndpoint(
            `ipg/:apiVer/payment/organizations/${orgId}/terminals`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ipgVersion"
          );
        },
        getSavedCard: (customerId: string, orgId: string) => {
          return new ApiEndpoint(
            `ipg/:apiVer/payment/organizations/${orgId}/customers/${customerId}/saved-cards`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ipgVersion"
          );
        },
        deleteBankCard: (organizationId: string, registrationId: string) => {
          return new ApiEndpoint(
            `ipg/:apiVer/payment/token/delete/${organizationId}/${registrationId}`,
            HttpMethod.Delete,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ipgVersion"
          );
        },
        registerToken: () => {
          return new ApiEndpoint(
            `ipg/:apiVer/payment/token/register`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ipgVersion"
          );
        },
        deleteByRegistrationId: () => {
          return new ApiEndpoint(
            `ipg/:apiVer/payment/token/deleteByRegistrationId`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ipgVersion"
          );
        },
        configureOrganization: () => {
          return new ApiEndpoint(
            `ipg/:apiVer/backoffice/configure-organization`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ipgVersion"
          );
        },
        getConfigurationByOrganization: (id: any) => {
          return new ApiEndpoint(
            `ipg/:apiVer/backoffice/organizations/${id}/configurations`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ipgVersion"
          );
        },
      },

      crypto: {
        getohlc: new ApiEndpoint(
          "crypto/ohlc",
          HttpMethod.Post,
          this.options.httpOption
        ),
      },

      erc: {
        //Todo: support pagination : page=1&limits=5
        getSmartCoinTransactions: (customerId: string) => {
          return new ApiEndpoint(
            `erc20/:apiVer/transactionHistoryByCustomerId?customerId=${customerId}`,
            HttpMethod.Get,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "erc20Version"
          );
        },
        sellCoin: () => {
          return new ApiEndpoint(
            `erc20/:apiVer/customerSellTokens`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "erc20Version"
          );
        },
      },

      ruleManagement: {
        getFee: () => {
          return new ApiEndpoint(
            "rules/:apiVer/filter",
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "ruleVersion"
          );
        },
      },

      identity: {
        createApimUser: () => {
          return new ApiEndpoint(
            "api/identity/user/v1.0/me",
            HttpMethod.Post,
            this.options.httpOption
          );
        },
      },

      adminPanel: {
        wallet: {
          getWalletById: (walletId: any) => {
            return new ApiEndpoint(
              `wallet/:apiVer/${walletId}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          updateAssetById: (walletId: any, assetId: any) => {
            return new ApiEndpoint(
              `wallet/:apiVer/${walletId}/asset/${assetId}`,
              HttpMethod.Put,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          getTradingHistory: (id: any, type: any) => {
            return new ApiEndpoint(
              `wallet/:apiVer/${id}/trading-order-history?businessType=${type}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          getOrders: () => {
            return new ApiEndpoint(
              `wallet/:apiVer/orders`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          getBulkAmounts: () => {
            return new ApiEndpoint(
              `wallet/:apiVer/withdraw-bulk-ready-aggregate-orders-amount`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          getAvailableBalace: (type: string) => {
            return new ApiEndpoint(
              `wallet/:apiVer/spot-trading/available-balance?assetType=${type}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          approve: () => {
            return new ApiEndpoint(
              `wallet/:apiVer/status`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          getKrakenTxnHistory: () => {
            return new ApiEndpoint(
              "wallet/:apiVer/getAll/krakenLedgerInfo",
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              ["type"],
              null,
              false,
              "walletVersion"
            );
          },
          getInformations: () => {
            return new ApiEndpoint(
              "wallet/:apiVer/misc/tenant/bankAccount",
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              ["type"],
              null,
              false,
              "walletVersion"
            );
          },
          updateInfo: (id: any) => {
            return new ApiEndpoint(
              `wallet/:apiVer/misc/${id}`,
              HttpMethod.Put,
              this.options.httpOption,
              ["apiVer"],
              ["type"],
              null,
              false,
              "walletVersion"
            );
          },
          confirmBulkWithdraw: () => {
            return new ApiEndpoint(
              `wallet/:apiVer/bulk-withdraw-confirm`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          completeWithdraw: () => {
            return new ApiEndpoint(
              `wallet/:apiVer/complete-withdraw`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          web3Transfer: () => {
            return new ApiEndpoint(
              `wallet/:apiVer/web3Transfer`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          getDepositWithdrawHistory: (id: any, type: any) => {
            return new ApiEndpoint(
              `wallet/:apiVer/${id}/get-deposit-withdraw-order-history?businessType=${type}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "walletVersion"
            );
          },
          getSmartCoinWalletAvailableBalance: () => {
            return new ApiEndpoint(
              "wallet/:apiVer/smart-coin/wallet-available-balance",
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              ["type"],
              null,
              false,
              "walletVersion"
            );
          },
        },
        erc20: {
          getTransactionHistoryByTenant: (page: number, limits: number) => {
            return new ApiEndpoint(
              `erc20/:apiVer/transactionHistoryByTenant?page=${page}&limits=${limits}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          getTransactionHistoryByTenantAndStatus: (
            status: any,
            page: number,
            limits: number
          ) => {
            return new ApiEndpoint(
              `erc20/:apiVer/transactionHistoryByTenantAndStatus?page=${page}&limits=${limits}&orderStatus=${status}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          getTransactionHistoryByCustomerId: (customerId: any) => {
            return new ApiEndpoint(
              `erc20/:apiVer/transactionHistoryByCustomerId?customerId=${customerId}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          getTransactionHistorySumByTenantAndStatus: (
            status: any,
            page: number,
            limits: number
          ) => {
            return new ApiEndpoint(
              `erc20/:apiVer/getTransactionHistorySumByTenantAndStatus?page=${page}&limits=${limits}&orderStatus=${status}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          reserveToken: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/getDataForTokenReserve`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          transferToken: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/getDataForTokenTransfer`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          swapToken: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/getDataForSwap`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          transferUSDT: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/transferUSDTtoESCROW`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          getGlobalSettings: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/getGlobalSetting`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          updateGlobalSetting: (id: any) => {
            return new ApiEndpoint(
              `erc20/:apiVer/updateGlobalSetting/${id}`,
              HttpMethod.Put,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          getRuleByOperationType: (type: any) => {
            return new ApiEndpoint(
              `erc20/:apiVer/rule/getRulesByOperationType?page=0&limits=0&operationType=${type}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          createRule: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/rule/createRule`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          deleteRule: (ruleId: string) => {
            return new ApiEndpoint(
              `erc20/:apiVer/rule/${ruleId}`,
              HttpMethod.Delete,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          bulkReserve: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/bulkReserve`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          transferEthereum: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/transferEthereum`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
          transferUSDTtoPublicId: () => {
            return new ApiEndpoint(
              `erc20/:apiVer/transferUSDTtoPublicId`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "erc20Version"
            );
          },
        },
        rule: {
          getAllRules: () => {
            return new ApiEndpoint(
              "rules/:apiVer/get-all",
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "ruleVersion"
            );
          },
          updateRule: (id: any) => {
            return new ApiEndpoint(
              `rules/:apiVer/put/${id}`,
              HttpMethod.Put,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "ruleVersion"
            );
          },
          getRuleById: (id: any) => {
            return new ApiEndpoint(
              `rules/:apiVer/get/${id}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "ruleVersion"
            );
          },
        },
        user: {
          getAllUsers: () => {
            return new ApiEndpoint(
              "customer/:apiVer/getAllUsersByGroup",
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            );
          },
          createUser: () => {
            return new ApiEndpoint(
              "customer/:apiVer/create",
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            );
          },
          updateUser: (id: string) => {
            return new ApiEndpoint(
              `customer/:apiVer/update/${id}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            );
          },
          bockUser: (id: string, status: string) => {
            return new ApiEndpoint(
              `customer/:apiVer/update/${id}/status/${status}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            );
          },
          revokeGroup: () => {
            return new ApiEndpoint(
              `customer/:apiVer/revokeComponentAccessRight`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            );
          },
          assignGroup: () => {
            return new ApiEndpoint(
              `customer/:apiVer/addComponentAccessRight`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            );
          },
        },
        business: {
          sendForRevision: (customerId: any, type: any) =>
            new ApiEndpoint(
              `customer/:apiVer/organization/updateKYBStatus/${customerId}/${type}/SENT_FOR_REVISION`,
              HttpMethod.Put,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          getPepById: (customerId: any) =>
            new ApiEndpoint(
              `customer/:apiVer/getByOrganizationId/${customerId}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          updateStatus: (customerId: any, type: string) =>
            new ApiEndpoint(
              `customer/:apiVer/organization/updateKYBStatus/${customerId}/${type}/VERIFIED`,
              HttpMethod.Put,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          getBusinessOwnerDetail: (customerId: any) =>
            new ApiEndpoint(
              `customer/:apiVer/organization/entity/${customerId}`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          updateGeneralStatus: (orgId: string, status: string) =>
            new ApiEndpoint(
              `customer/:apiVer/organization/updateStatus/${orgId}/${status}`,
              HttpMethod.Put,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
        },
        aml: {
          downloadDocument: (id: string) =>
            new ApiEndpoint(
              `aml/:apiVer/document/${id}`,
              HttpMethod.Get,
              this.options.downloadDocumentOption,
              ["apiVer"],
              null,
              null,
              false,
              "amlVersion"
            ),
        },
        customer: {
          updateCustomerStatus: (custId: string, status: string) =>
            new ApiEndpoint(
              `customer/:apiVer/update/${custId}/status/${status}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          updateKycStatus: (custId: string, status: string) =>
            new ApiEndpoint(
              `customer/:apiVer/update/${custId}/kycConfirmation/${status}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          updatePEPStatus: (custId: string, status: string) =>
            new ApiEndpoint(
              `customer/:apiVer/update/${custId}/pepCheckStatus/${status}`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          updateRevisions: (custId: string) =>
            new ApiEndpoint(
              `customer/:apiVer/update/${custId}/revisions`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          getBusinessCustomers: () =>
            new ApiEndpoint(
              `customer/:apiVer/organization/getAll`,
              HttpMethod.Post,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
          getBusinessCustomerPersonOpenings: () =>
            new ApiEndpoint(
              `customer/:apiVer/organization/getOrganization`,
              HttpMethod.Get,
              this.options.httpOption,
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
        },
      },
      oauth: {
        logout: () =>
          new ApiEndpoint(
            `oauth/:apiVer/auth/logout`,
            HttpMethod.Post,
            this.options.httpOption,
            ["apiVer"],
            null,
            null,
            false,
            "customerVersion"
          ),
        login: () =>
          new ApiEndpoint(
            `oauth/:apiVer/auth/token`,
            HttpMethod.Post,
            this.options.loginOption,
            ["apiVer"],
            ["email", "password"],
            null,
            false,
            "customerVersion"
          ),
          session: (token: string) =>
            new ApiEndpoint(
              `oauth/:apiVer/auth/session`,
              HttpMethod.Get,
              this.options.sessionOption(token),
              ["apiVer"],
              null,
              null,
              false,
              "customerVersion"
            ),
      },
    };
  }

  request(endpoint: ApiEndpoint, data?: any, apiFor?: string): Observable<any> {
    //set customerVersion on urlParam from Environment
    if (endpoint.apiVersion) {
      const apiVer = this.env[endpoint.apiVersion];
      if (apiVer) {
        if (data) {
          if (data.urlParams) data.urlParams.apiVer = apiVer;
          else data.urlParams = { apiVer: apiVer };
        } else {
          data = new HttpRequestData();
          data.urlParams = { apiVer: apiVer };
        }
      } else {
        return throwError(() => "Api version not found on environment");
      }
    }

    const options =
      endpoint.options == null ? this.options.httpOption : endpoint.options;
    // const baseUrl = apiFor == "mockData" ? this.env.mockDataUrl : apiFor == 'microServices' ? this.env.microServiceUrl : this.env.serverUrl;
    let baseUrl;
    switch (apiFor) {
      case "mockData":
        baseUrl = this.env.mockDataUrl;
        break;
      case "countryList":
        baseUrl = this.env.countryListUrl;
        break;
      case "walletMicroService":
        baseUrl = this.env.walletMicroServiceUrl;
        break;
      case "customerMicroService":
        baseUrl = this.env.customerMicroServiceUrl;
        break;
      case "apiManager":
        baseUrl = this.env.apiManagerUrl;
        break;
      case "krakenMicroService":
        baseUrl = this.env.krakenMicroServiceUrl;
        break;
      case "businessMicroservice":
        baseUrl = this.env.customerMicroServiceUrl;
        break;
      case "amlMs":
        baseUrl = this.env.amlMsURL;
        break;
      case "ercMs":
        baseUrl = this.env.erc20MsURL;
        break;
      case "ruleManagementMs":
        baseUrl = this.env.ruleManagementURL;
        break;
      case "ipgMs":
        baseUrl = this.env.ipgMsURL;
        break;
      case "oauth":
        baseUrl = this.env.customerMicroServiceUrl;
        break;

      default:
        baseUrl = this.env.serverUrl;
    }

    let url = ` ${baseUrl}${endpoint.route}`;

    if (endpoint.dontUseServerUrl) {
      url = endpoint.route;
    }

    // replaces parameters in the endpoint route with supplied values.
    if (endpoint.urlParams != null && data && data.urlParams != null) {
      Object.keys(data.urlParams).forEach((key) => {
        url = url.replace(":" + key, data.urlParams[key]);
      });
    }

    // adds query parameters in the endpoint route with supplied values.
    if (data && data.urlAppend) {
      url = url + "/" + data.urlAppend;
    }

    // adds query parameters in the endpoint route with supplied values.
    if (endpoint.queryParams != null && data && data.queryParams != null) {
      let first = true;
      Object.keys(data.queryParams).forEach((key) => {
        if (first) {
          url = url + "?";
          first = false;
        } else {
          url = url + "&";
        }
        url = url + key + "=" + data.queryParams[key];
      });
    }

    if (data === null || data === undefined) {
      data = {};
    }

    switch (endpoint.method) {
      case HttpMethod.Get:
        return this.http.get(url, options);
      case HttpMethod.Post:
        return this.http.post(url, data.bodyParams, options);
      case HttpMethod.Put:
        return this.http.put(url, data.bodyParams, options);
      case HttpMethod.Patch:
        return this.http.patch(url, data.bodyParams, options);
      case HttpMethod.Delete:
        return this.http.delete(url, options);
      case HttpMethod.Head:
        return this.http.head(url, options);
      default:
        return throwError("Unknown http method.");
    }
  }

  getApiManagerToken(): Observable<any> {
    const data = new HttpRequestData();
    const uniqueId = uuid.v4();
    data.bodyParams = {
      grant_type: "client_credentials",
      scope: `device_${uniqueId}`,
    };
    return this.request(
      this.apiEndpoints.accounts.apiManager,
      data,
      "apiManager"
    );
  }

}
