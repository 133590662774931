import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService, HelperService } from 'src/app/shared/services';
import { BehaviorSubject, from, of, pipe } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { BackendApiService } from 'sepa/framework/src/public-api';
import { HttpRequestData } from 'sepa/framework/src/lib/models/backend-api.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(
    public http: ApiHttpService,
    public help: HelperService,
    public api: BackendApiService
  ) {}

  allCustomers = [];
  getAllCustomers(pageObject) {
    // let res = [{"customerDto":{"id":"8c1589e5-9574-4a5a-a1c4-d41f7359a25b","customerType":1,"customerName":"string","customerStatus":0,"categorization":0,"ownerId":"string","person":{"id":"230d513b-3c8e-4a29-9dc0-5a3bfcde91a2","names":[{"id":"0d14ae58-e0e9-4cfe-a979-48c311e60554","createdAt":"2022-03-09T11:51:20.835Z","updatedAt":"2022-03-09T11:51:20.835Z","name":"Sepa","order":0,"abbreviation":"string","language":"string","native":"string","suffix":"string","prefix":"string"},{"id":"5f612bc6-d51f-4734-83de-06b7abccfc9c","createdAt":"2022-03-09T11:51:20.841Z","updatedAt":"2022-03-09T11:51:20.841Z","name":"Cyber","order":1,"abbreviation":"string","language":"string","native":"string","suffix":"string","prefix":"string"}],"gender":0,"dateOfBirth":"2000-03-09T11:21:56.000Z","placeOfBirth":"string","nationality":"string","postCode":"string","password":"string","email":"sepa@sepa-cyber.com"}}},{"customerDto":{"id":"036b67fa-4392-47a6-bffd-a657dc26852f","customerType":"","customerName":"d","customerStatus":"","categorization":0,"ownerId":"incididunt culpa do in ullamco","person":{"id":"ab27b52b-cca2-4632-99cc-9402777b6d8c","names":[{"id":"56bb64cc-beeb-487e-a0e9-9cc5782a00bd","createdAt":"2022-03-09T13:05:37.274Z","updatedAt":"2022-03-09T13:05:37.274Z","name":"Ut","order":2,"abbreviation":"aute aliquip ","language":"exercitation cillum dolore","native":"Lorem","suffix":"aliquip eu anim est","prefix":"Lorem sint ex consectetur"},{"id":"c99cc8d6-595b-4394-ab7e-dcdc0aa50c7f","createdAt":"2022-03-09T13:05:37.255Z","updatedAt":"2022-03-09T13:05:37.255Z","name":"labore elit dolore","order":1,"abbreviation":"sit consequat ea","language":"aliqu","native":"nostrud","suffix":"labore occaecat","prefix":"labore nostrud nisi sint cill"}],"gender":"","dateOfBirth":"1983-08-09T13:41:31.000Z","placeOfBirth":"eu esse enim","nationality":"sunt reprehend","postCode":"ut sit","password":"ea nostrud consectetur qui ipsum","email":"eiusmod@test.com"}}}]
    // let result:any = {data:{}}
    // result.data.customersArray = res.map(i=>i.customerDto);
    // result.data.totalPages = 1;
    // this.allCustomers = result
    // return of(result)

    let url;
    if (pageObject.searchValue) {
      let searchValue = pageObject.searchValue;
      searchValue = searchValue.trim();
      let firstIndex = searchValue.indexOf(' ');
      if (firstIndex > -1) {
        pageObject.firstName = searchValue.substr(0, searchValue.indexOf(' '));
        pageObject.lastName = searchValue.substr(searchValue.indexOf(' ') + 1);
      } else {
        pageObject.firstName = searchValue;
        pageObject.lastName = searchValue;
      }
      url = apis.customer.getCustomersByName;
    } else {
      url = apis.customer.getAllCustomers;
    }
    return this.http.post(url, pageObject).pipe(
      map((res: any) => {
        console.log(res);
        let result: any = { data: {} };
        result.data.customersArray = res.customers;
        result.data.totalPages = res.pagination.totalSize;
        return result;
      }),
      tap((res: any) => (this.allCustomers = res.data))
    );
  }

  _isCustomerTenant: BehaviorSubject<string> = new BehaviorSubject('b_y'); //b_y => business customer and is tenant =true
  currentIsCustomerTenantStatus = this._isCustomerTenant.asObservable();
  setIsCustomerOrTenantStatus(status: string) {
    this._isCustomerTenant.next(status);
  }

  customerById: any = {};
  getCustomerById(id) {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    return this.api.request(
      this.api.apiEndpoints.business.getOrgById(id),
      data,
      'customerMicroService'
    ).pipe(
      map((res: any) => {
        if (res.value?.dateOfIncorporation) {
          res.value.dateOfIncorporation = this.help.convertDataFormat(
            res.value?.dateOfIncorporation
          );
        }
        res.value.updatedAt = this.help.convertDataFormat(res.value?.updatedAt);
        res.value.createdAt = this.help.convertDataFormat(res.value?.createdAt);
        return { data: res.value };
      })
    )
  }

  getIndividualCustomerById(id) {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    return this.api.request(
      this.api.apiEndpoints.createAccount.getCustomerById(id),
      data,
      'customerMicroService'
    );
  }

  getIndividualCustomerByOrgId(id) {
    let body = {
      type: 'person',
      created: 'string',
      status: 'string',
      pageNumber: 0,
      pageSize: 0,
    };
    const url = apis.customer.getIndividualCustomerByOrgId(id);
    return this.http.post(url, body);
  }

  getAllBusinessCustomers(pageObject) {
    const payload = {
      bodyParams: { ...pageObject },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.customer.getBusinessCustomers(),
      payload,
      'customerMicroService'
    ).pipe(
        map((res: any) => {
          console.log(res);
          let result: any = { data: { customersArray: [], totalPages: 1 } };
          if (res.data) {
            result.data.customersArray = res.data;
            result.data.totalPages = res.totalSize;
          }
          return result;
        }),
        tap((res: any) => (this.allCustomers = res.data))
      );
  }

  getBusinessCustomerPersonOpenings(pageObject) {
    const payload = {
      bodyParams: { ...pageObject },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.customer.getBusinessCustomerPersonOpenings(),
      payload,
      'customerMicroService'
    ).pipe(
        map((res: any) => {
          console.log(res);
          let result: any = { data: { customersArray: [], totalPages: 1 } };
          if (res) {
            result.data.customersArray = res;
          }
          return result;
        }),
        tap((res: any) => (this.allCustomers = res))
      );
  }

  getAllBusinessCustomersForDropDown() {
    let url;
    url = apis.customer.getAllBusinessCustomers;

    let body = {
      skip: 0,
      limit: 0,
      page: 0,
    };
    return this.http.post(url, body).pipe(
      map((res: any) => {
        console.log(res);
        let result: any = { data: { customersArray: [], totalPages: 1 } };
        if (res.data) {
          result.data.customersArray = res.data;
          result.data.totalPages = res.totalSize;
        }
        return result;
      }),
      tap((res: any) => (this.allCustomers = res.data))
    );
  }

  getBusinessCustomerById(businessId) {
    const url = apis.customer.getBusinessById + businessId;
    return this.http.get(url).pipe(
      map((res: any) => {
        return { data: res.value };
      }),
      tap((res: any) => (this.customerById = res.data))
    );
  }
  getdocs(purpose, customerId) {
    return this.api.request(
      this.api.apiEndpoints.business.getDocuments(customerId, purpose),
      null,
      'amlMs'
    );
  }
  fileupload(body) {
    const url = apis.muse.upload;
    // console.log(body);
    // console.log(url);
    body = this.http.toFormData(body);
    // const payload = {
    //   bodyParams: body
    // }
    // const data = new HttpRequestData();
    // data.bodyParams = this.http.toFormData(body);
    return this.http.post(url, body);
    //return this.api.request(apiEndpoints.business.uploadDocument(), data, "amlMs")
  }
  smsEmail(body) {
    const url = apis.muse.email;
    console.log(body);
    console.log(url);
    return this.http.post(url, body);
  }
  sendsms(body) {
    const url = apis.muse.sms;
    console.log(body);
    console.log(url);
    return this.http.post(url, body);
  }
  getAllViewers(pageObject) {
    let url;
    if (pageObject.searchValue) {
      let searchValue = pageObject.searchValue;
      searchValue = searchValue.trim();
      let firstIndex = searchValue.indexOf(' ');
      if (firstIndex > -1) {
        pageObject.firstName = searchValue.substr(0, searchValue.indexOf(' '));
        pageObject.lastName = searchValue.substr(searchValue.indexOf(' ') + 1);
      } else {
        pageObject.firstName = searchValue;
        pageObject.lastName = searchValue;
      }
      url = apis.customer.getCustomersByName;
    } else {
      url = apis.customer.getAllViewers;
    }
    return this.http.post(url, pageObject).pipe(
      map((res: any) => {
        if (res.data.viewersArray) {
          res.data.customersArray = res.data.viewersArray;
          res.data.totalPages = res.data.totalPages;
        }
        return res;
      }),
      tap((res: any) => (this.allCustomers = res.data))
    );
  }

  updateViewerById(body) {
    const url = apis.customer.updateViewerById;
    return this.http.post(url, body);
  }

  bulkUploadHold(businessName, array) {
    const body = { businessName, array };
    const url = apis.customer.bulkUpload;
    return this.http.post(url, body);
  }

  bulkUploadHold2(businessName, array) {
    return from(JSON.parse(array)).pipe(
      // concatMap(entry => this.http.post('http://localhost:4000/consumer/customers/individual', entry)),
      concatMap((entry) => this.http.post(apis.customer.bulkUpload, entry)),
      tap((t) => {
        console.log(t);
      })
    );
    // const body = { businessName, array };
    // const url = apis.customer.bulkUpload;
    // return this.http.post(url, body);
  }

  bulkUploadHold3(orgId, array) {
    const body = {
      shareholders: array,
    };
    const url = apis.customer.bulkUpload + orgId;
    return this.http.patch(url, body).pipe(
      tap((res: any) => {
        let customers: any[] = res.value.props.shareHolders;
        console.log(customers);
        customers.forEach((customer: any) => {
          this.uploadPepSanctionDocument(customer);
        });
      })
    );
  }

  bulkUpload(orgId, array) {
    return from(array).pipe(
      concatMap((entry) => {
        const body = {
          shareholders: [entry],
        };
        return this.http.patch(apis.customer.bulkUpload + orgId, body).pipe(
          tap((res: any) => {
            let customers: any[] = res.value.props.shareHolders;
            console.log(customers);
            customers.forEach((customer: any) => {
              this.uploadPepSanctionDocument({ customer, entry });
            });
          })
        );
      })
    );
  }

  bulkUploadIndividual(array) {
    return from(array).pipe(
      concatMap((entry) => {
        const body = [entry];
        return this.http.post(apis.customer.bulkUploadIndividual, body).pipe(
          tap((res: any) => {
            console.log(res);
            let customers: any[] = res;
            console.log(customers);
            // customers.forEach((customer: any) => {
            //     // this.uploadPepSanctionDocument({ customer, entry });
            // })
          })
        );
      })
    );
  }

  uploadPepSanctionDocument(object: any) {
    console.log(object.customer);
    let customerId = object.customer.id;
    let person = object.entry.person;
    let dateofbirth = new Date(person.dateOfBirth) || new Date();
    let name = person.firstName.name || 'firstname';
    let obj = {
      organization: customerId || 'testid',
      organizationName: name,
      purpose: 'pep-sanction',
      documentType: 'pep-sanction',
      metadata: JSON.stringify({
        name: name,
        day: dateofbirth.getDate(),
        month: dateofbirth.getMonth() + 1,
        year: dateofbirth.getFullYear(),
      }),
    };

    this.fileupload(obj).subscribe(
      (res: any) => {
        console.log('success', res);
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  bulkUploadBusiness(businessName, array) {
    //console.log(array)
    return from(JSON.parse(array)).pipe(
      // concatMap(entry => this.http.post('http://localhost:4000/consumer/customers/individual', entry)),
      concatMap((entry) =>
        this.http.post(apis.customer.bulkUploadBusiness, entry)
      )
      // tap((t:any) => {
      //     console.log('bulk upload bussiness service error',t)
      //     if(t.error){
      //         this.help.notify('error', t.error.message);
      //     }else{
      //         this.help.notify('success', 'Success Upload');
      //     }
      // })
    );
  }

  getstatus(data, doctype: string) {
    let item = data.find((element) => {
      return element.documentType == doctype;
    });
    if (item) {
      return item.status;
    } else {
      return 'NA';
    }
  }
  setDocumentstatus(document, status: string) {
    const url = apis.aml.setDocumentstatus + document.id.props.value;
    console.log(document, status);
    console.log(url);
    return this.http.put(url, { status });
  }

  downloadDocument(data, doctype: string) {
    let item = data.find((element) => {
      return element.documentType == doctype;
    });
    if (item) {
      return apis.muse.downloaddoc + item.id.props.value;
    } else {
      return 'NA';
    }
  }

  toDownloadDocumentId(data, doctype: string) {
    let items = data.filter((element) => {
      return element.documentType == doctype;
    });

    let item;
    if (items.length == 1) {
      item = items[0];
    } else if (items.length > 1) {
      item = items[items.length - 1];
    }

    if (item) {
      return item;
    } else {
      return 'NA';
    }
  }

  checkKYBHOLD(customer: any, purpose: string, docList: any[]) {
    let promiseResolve;
    let promise = new Promise((resolve) => {
      promiseResolve = resolve;
    });
    this.getdocs(purpose, customer.id).subscribe((docs: any) => {
      let verifiedDocs = docs
        .filter((i) => i.status == 'verified')
        .map((i) => i.documentType);
      let status = docList.every((v) => verifiedDocs.includes(v))
        ? 'Verified'
        : 'Pending';
      if (status == 'Pending') {
        status =
          docs.filter((i) => i.status == 'declined').length > 0
            ? 'Declined'
            : 'Pending';
      }
      customer.kyb = status;
      customer.kyc = status;

      let pepDoc = docs.find((i) => i.purpose == 'pep-sanction');
      if (pepDoc && pepDoc.verificationResponse) {
        let verfiyRes = JSON.parse(pepDoc.verificationResponse);
        if (verfiyRes?.value?.meta?.count > 0) {
          console.log(verfiyRes.value.data[0].attributes.icon_hints);
          let icon_hints = verfiyRes.value.data[0].attributes.icon_hints;
          customer.peps = icon_hints.indexOf('PEP') > -1 ? 'YES' : 'NO';
          customer.sanctions =
            icon_hints.indexOf('SAN-PERSON') > -1 ? 'YES' : 'NO';
        }
      }
      promiseResolve(customer);
    });
    return promise;
  }

  checkKYB(customer: any, purpose: string, docList: any[]) {
    let promiseResolve;
    let promise = new Promise((resolve) => {
      promiseResolve = resolve;
    });
    this.getdocs(purpose, customer.id).subscribe((docs: any) => {
      let verifiedDocs = docs
        .filter((i) => i.status == 'verified')
        .map((i) => i.documentType);
      let status = docList.every((v) => verifiedDocs.includes(v))
        ? 'Verified'
        : 'Pending';
      if (status == 'Pending') {
        status =
          docs.filter((i) => i.status == 'declined').length > 0
            ? 'Declined'
            : 'Pending';
      }
      customer.kyb = status;
      customer.kyc = status;

      if (customer?.person?.label) {
        customer.peps =
          customer?.person?.label?.indexOf('PEP') > -1 ? 'YES' : 'NO';
        customer.sanctions =
          customer?.person?.label?.indexOf('SAN-PERSON') > -1 ? 'YES' : 'NO';
      } else {
        customer.peps = 'Pending';
        customer.sanctions = 'Pending';
      }

      promiseResolve(customer);
    });
    return promise;
  }

  sendForRevision(id: any, data: any, type: any) {
    const payload = {
      bodyParams: { ...data },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.business.sendForRevision(id, type),
      payload,
      'customerMicroService'
    );
  }

  getBusinessOwnerDetails(id: any) {
    return this.api.request(
      this.api.apiEndpoints.adminPanel.business.getBusinessOwnerDetail(id),
      null,
      'customerMicroService'
    );
  }

  getPepById(id: any) {
    let data = {
      type: 'string',
      created: 'string',
      status: 'string',
      isB2B: true,
      pageSize: 0,
      pageNumber: 0,
    };
    const payload = {
      bodyParams: { ...data },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.business.getPepById(id),
      payload,
      'customerMicroService'
    );
  }

  getSanctionById(id: any) {
    return this.api.request(
      this.api.apiEndpoints.business.getOrgById(id),
      null,
      'customerMicroService'
    );
  }

  getCountries() {
    let data = {
      tenantId: '7',
    };
    return this.api.request(
      this.api.apiEndpoints.createAccount.getCountryList,
      data,
      'countryList'
    );
  }

  updateStatus(id: any, type: string) {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    return this.api.request(
      this.api.apiEndpoints.adminPanel.business.updateStatus(id, type),
      data,
      'customerMicroService'
    );
  }

  updateGeneralStatus(id: any, type: string) {
    return this.api.request(
      this.api.apiEndpoints.adminPanel.business.updateGeneralStatus(id, type),
      null,
      'customerMicroService'
    );
  }

  updateCustomerGeneralStatus(id: any, type: string) {
    return this.api.request(
      this.api.apiEndpoints.adminPanel.customer.updateCustomerStatus(id, type),
      null,
      'customerMicroService'
    );
  }

  updateKycStatus(id: any, status: string) {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    return this.api.request(
      this.api.apiEndpoints.adminPanel.customer.updateKycStatus(id, status),
      data,
      'customerMicroService'
    );
  }

  updatePEPStatus(id: any, status: string) {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    return this.api.request(
      this.api.apiEndpoints.adminPanel.customer.updatePEPStatus(id, status),
      data,
      'customerMicroService'
    );
  }

  sendForRevisionIndividualCustomer(id: any, data: any, status: any) {
    const payload = {
      bodyParams: { ...data },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.customer.updateKycStatus(id, status),
      payload,
      'customerMicroService'
    );
  }

  sendForRevisionPersonalDetail(id: any, data: any) {
    // var url = `https://mss-customer-restapi-beta.local.sepa-cyber.com/api/v1/customer/update/${id}/revisions`;
    // return this.http.post(url, data);
    //updateRevisions
    const payload = {
      bodyParams: { ...data },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.customer.updateRevisions(id),
      payload,
      'customerMicroService'
    );
  }
}
