import { Component, OnInit, OnDestroy } from "@angular/core";
import { UtilsService } from "../../../services/utils.service";
import { Country } from "../../../models/country.model";
import { LanguageService } from "../../../services/language.service";
import { LoaderService } from "../../../services/loader.service";
import { Store } from "../../../services/store.service";
import { Subscription } from "rxjs";
import { AuthenticationService } from "../../../services/authentication.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public userName = "";
  public displayModal!: boolean;
  languages: Country[] = [];

  showFormatSetting = false;
  logoWidth = "100";
  customerDataSubscription?: Subscription;

  constructor(
    private ls: LoaderService,
    private store: Store,
    public utils: UtilsService,
    private authService: AuthenticationService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.initConfig();
    this.customerDataSubscription = this.store.customerData.subscribe(customerData => {
      if(customerData.id) {
        const firstName = customerData.person.names?.length ? customerData.person.names[0]?.name ?? '' : '';
        const surname =  customerData.person.names?.length > 1 ? customerData.person.names[1]?.name ?? '' : '';
        this.userName = customerData.person.fullName ?? `${firstName} ${surname}`;
      }
    });
  }

  initConfig() {
    this.logoWidth = this.ls.clientConfig.header?.logoWidth ?? "100";
    this.languages = [
      {
        name: "Austria",
        code: "AT",
        language: "German",
        languageCode: "de",
        currencyIsoCode: "EUR",
      },
      {
        name: "Belgium",
        code: "BE",
        language: "Dutch",
        languageCode: "nl",
        currencyIsoCode: "EUR",
      },
      {
        name: "Bulgaria",
        code: "BG",
        language: "Bulgarian",
        languageCode: "bg",
        currencyIsoCode: "BGN",
      },
      {
        name: "Croatia",
        code: "HR",
        language: "Croatian",
        languageCode: "hr",
        currencyIsoCode: "HRK",
      },
      {
        name: "Cyprus",
        code: "CY",
        language: "Greek",
        languageCode: "el",
        currencyIsoCode: "EUR",
      },
      {
        name: "Czechia",
        code: "CZ",
        language: "Czech",
        languageCode: "cs",
        currencyIsoCode: "CZK",
      },
      {
        name: "Denmark",
        code: "DK",
        language: "Danish",
        languageCode: "da",
        currencyIsoCode: "DDK",
      },
      {
        name: "Estonia",
        code: "EE",
        language: "Estonian",
        languageCode: "et",
        currencyIsoCode: "EUR",
      },
      {
        name: "Finland",
        code: "FI",
        language: "Finnish",
        languageCode: "fi",
        currencyIsoCode: "EUR",
      },
      {
        name: "France",
        code: "FR",
        language: "French",
        languageCode: "fr",
        currencyIsoCode: "EUR",
      },
      {
        name: "Germany",
        code: "GE",
        language: "German",
        languageCode: "de",
        currencyIsoCode: "EUR",
      },
      {
        name: "Greece",
        code: "GR",
        language: "Greek",
        languageCode: "el",
        currencyIsoCode: "EUR",
      },
      {
        name: "Hungary",
        code: "HU",
        language: "Hungarian",
        languageCode: "hu",
        currencyIsoCode: "HUF",
      },
      {
        name: "Ireland",
        code: "IE",
        language: "Irish",
        languageCode: "ga",
        currencyIsoCode: "EUR",
      },
      {
        name: "Italy",
        code: "IT",
        language: "Italian",
        languageCode: "it",
        currencyIsoCode: "EUR",
      },
      {
        name: "Latvia",
        code: "LV",
        language: "Latvian",
        languageCode: "lv",
        currencyIsoCode: "EUR",
      },
      {
        name: "Lithuania",
        code: "LT",
        language: "Lithuanian",
        languageCode: "lt",
        currencyIsoCode: "EUR",
      },
      {
        name: "Luxembourg",
        code: "LU",
        language: "Luxembourgish",
        languageCode: "lb",
        currencyIsoCode: "EUR",
      },
      {
        name: "Malta",
        code: "MT",
        language: "Maltese",
        languageCode: "mt",
        currencyIsoCode: "EUR",
      },
      {
        name: "Netherlands",
        code: "NL",
        language: "Dutch",
        languageCode: "nl",
        currencyIsoCode: "EUR",
      },
      {
        name: "Poland",
        code: "PL",
        language: "Polish",
        languageCode: "pl",
        currencyIsoCode: "PLN",
      },
      {
        name: "Portugal",
        code: "PT",
        language: "Portuguese",
        languageCode: "pt",
        currencyIsoCode: "EUR",
      },
      {
        name: "Romania",
        code: "RO",
        language: "Romanian",
        languageCode: "ro",
        currencyIsoCode: "RON",
      },
      {
        name: "Slovakia",
        code: "SK",
        language: "Slovak",
        languageCode: "sk",
        currencyIsoCode: "EUR",
      },
      {
        name: "Slovenia",
        code: "SL",
        language: "Slovenian",
        languageCode: "sl",
        currencyIsoCode: "EUR",
      },
      {
        name: "Spain",
        code: "ES",
        language: "Spanish",
        languageCode: "es",
        currencyIsoCode: "EUR",
      },
      {
        name: "Sweden",
        code: "SE",
        language: "Swedish",
        languageCode: "sv",
        currencyIsoCode: "SEK",
      },
      {
        name: "United Kingdom",
        code: "GB",
        language: "English",
        languageCode: "en",
        currencyIsoCode: "GBR",
      },
      {
        name: "United States",
        code: "US",
        language: "English",
        languageCode: "en",
        currencyIsoCode: "USD",
      },
      {
        name: "Switzerland",
        code: "CH",
        language: "German",
        languageCode: "de",
        currencyIsoCode: "CHF",
      },
      {
        name: "Iceland",
        code: "IS",
        language: "Icelandik",
        languageCode: "is",
        currencyIsoCode: "ISK",
      },
      {
        name: "Norway",
        code: "NO",
        language: "Norwegian",
        languageCode: "no",
        currencyIsoCode: "NOK",
      },
    ];
  }

  onChangeLanguage(e: any) {
    this.languageService.changeLanguage(e.value);
  }

  logoutButton() {
    this.displayModal = true;
  }

  modalButtons(btn: boolean) {
    if (btn) {
      this.authService.onLogout();
    }
    this.displayModal = false;
  }

  ngOnDestroy(): void {
    this.customerDataSubscription?.unsubscribe();
  }
}
