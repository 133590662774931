import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { messages } from 'src/app/shared/defaults';
import { HelperService, WalletsService } from 'src/app/shared/services';

@Component({
  selector: 'app-add-operation-model',
  templateUrl: './add-operation-model.component.html'
})
export class AddOperationModelComponent implements OnInit {

  @Input() editingAsset: any;
  @Output() onSuccess = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();
  currencyInstances: any;
  private subscriptions = new Subscription();
  @Input() walletId: any;
  assets: any;

  formOptions = {
    assetType : [
      {name: 'FIAT Currency', code: 'fiat', type:'currency' },
      {name: 'Crypto Currency', code: 'crypto', type: 'crypto-currency'},
      // {name: 'Bank-Card', code: 'bank-card'},
      // {name: 'Transport-Card', code: 'transport-card'},
      // {name: 'ID-Card', code: 'id-card'},
      // {name: 'QR-Card', code: 'qr-code'},
      // {name: 'Linked-Account', code: 'linked-account'},
      // {name: 'Linked-External-Account', code: 'linked-external-account'},
    ],
    operationType : ["buy","sell","deposit","withdraw","transfer","reverse"],
    instance: [],
    //["EUR","USD","BTC","USDT"],
    sourceDestination:[
      {name: 'Asset wallet owner platform', code: 'buy'},
      {name: 'Asset wallet owner bank', code: 'sell'},
      {name: 'Asset wallet owner blockchain', code: 'deposit'},
      {name: 'Asset swift bank', code: 'deposit'},
      {name: 'Asset sepa bank', code: 'deposit'},
      {name: 'Asset exchange', code: 'deposit'},
      {name: 'Asset blockchain', code: 'withdraw'},
      {name: 'Exteranl source wallet owner platform', code: 'buy'},
      {name: 'Exteranl source wallet owner bank', code: 'sell'},
      {name: 'Exteranl source wallet owner blockchain', code: 'deposit'},
      {name: 'Exteranl source swift bank', code: 'deposit'},
      {name: 'Exteranl source sepa bank', code: 'deposit'},
      {name: 'Exteranl source exchange', code: 'deposit'},
      {name: 'Exteranl source blockchain', code: 'withdraw'},
      {name: 'Exteranl destination wallet owner platform', code: 'buy'},
      {name: 'Exteranl destination wallet owner bank', code: 'sell'},
      {name: 'Exteranl destination wallet owner blockchain', code: 'deposit'},
      {name: 'Exteranl destination swift bank', code: 'deposit'},
      {name: 'Exteranl destination sepa bank', code: 'deposit'},
      {name: 'Exteranl destination exchange', code: 'deposit'},
      {name: 'Exteranl destination blockchain', code: 'withdraw'}
    ]
  }

  isEditMode = false;
  selectedOperationType: any;

  formSubmited = false;
  formGroup = this.fb.group({
    operationType: ["", [Validators.required]],
    assetType: ["", [Validators.required]],
    //description: ["", [Validators.required]],
    instance: ["", [Validators.required]],
    //source: ["", [Validators.required]],
    //destination: ["", [Validators.required]]
  });
  constructor(
    private fb: FormBuilder,
    public walletsService: WalletsService,
    private helperService: HelperService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.walletsService.getCurrencies().subscribe(currencies => {
        this.currencyInstances = currencies;
        //this.mapCurrencies(this.currencyInstances);
        console.log('currency instances',this.currencyInstances)
      })
    );
    this.subscriptions.add(
      this.walletsService.getAssets(this.walletId)
      .subscribe((res: any) => {
        this.assets = res;
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isEditMode = false;
    let currentValue = changes['editingAsset'].currentValue;
    if (currentValue) {
      this.isEditMode = true;
      this.formGroup.patchValue(currentValue);
    }
  }

  filterByType(option: any) {
    console.log('selectedType',option)
    const filteredOptions = this.currencyInstances.filter(c => c.type == option.code)
    console.log('filteredOptions',filteredOptions)
    this.formOptions.instance = filteredOptions;
  }

  onSubmit() {
    this.formSubmited = true;
    let formData = this.formGroup.getRawValue();
    console.log('formgroup',formData)

    if (this.formGroup.status == 'VALID') {
      ;
      let httpService;
      let successMessage;

        //const currencyId = formData.instance.id
        // const aliaseId = getAliaseId(formData.instance);
        //const typeName = formData.instance.type
        const payload = {
          operationType: formData.operationType,
          assetType: formData.assetType.type,
          name: formData.instance.name,
          isSameWallet: true
        };
        console.log('add op payload', payload);
        const asset = this.assets.filter( a => a?.instance?.id == formData?.instance?.id);
        const assetId = asset[0].id;
        httpService = this.walletsService.createWalletAssetOperation(this.walletId,assetId,payload);
        successMessage = messages.success.createWalletOperation;

      httpService.subscribe((res: any) => {
        if(res.isOk) {
          this.helperService.notify('success', successMessage);
          this.onClose.emit('success');
        }
        else {
          this.helperService.notify('error', 'Unable to add asset');
        }
        //this.modalRef.hide();
      }, (error) => {
        this.helperService.notify('error', error);
      });
      this.onClose.emit('close');
      setTimeout(() => {
        this.formSubmited = false;
        this.formGroup.reset();
        this.formGroup.markAsPristine();
        //this.formGroup.controls['purpose'].setValue('spot-trading');
      }, 500);
    }
    console.log('submit', this.formGroup.value)
  }


}

