<p-table #table
    *ngIf="tableConfig?.rows"
    dataKey="id" 
    responsiveLayout="scroll"
    [lazy]="true" 
    [value]="tableData" 
    [totalRecords]="totalRecords"

    (onLazyLoad)="onLazyLoad" 
    (selectAllChange)="selectAllChange" 
    
    [paginator]="tableConfig.paginator===undefined ? false : tableConfig.paginator" 
    [rows]="tableConfig.rows || 10"
    [loading]="loading"
    [globalFilterFields]="pageConfig.globalFilterFields"
>
    <ng-template pTemplate="caption">
        <div class="flex justify-content-between align-items-center">
            <h3 *ngIf="tableConfig.title">{{ tableConfig.title }}</h3>
            <ng-template *ngIf="customCaptionComponent" [ngTemplateOutlet]="customCaptionComponent"></ng-template>
        </div>
        <div *ngIf="tableConfig.showSearch" class="flex">
            <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash"
                (click)="clear(table)"></button>
            <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="table.filterGlobal($event.target, 'contains')"
                    placeholder="Search keyword" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let column of columns;" [pSortableColumn]="column.field">
                {{ column.name }}
                <p-sortIcon *ngIf="column.sort" [field]="column.field"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th *ngFor="let column of columns;" [pSortableColumn]="column.field">
                <p-columnFilter *ngIf="filterList" type="text" field="{{ column.field }}"></p-columnFilter>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer>
        <tr>
            <td *ngIf="leadingTableIcons">
                <ng-container *ngTemplateOutlet="leadingTableIcons"></ng-container>
            </td>
            <td *ngFor="let column of columns;">
                <div *ngIf="column.field.includes('.');then nested_object_content else normal_content"></div>
                <ng-template #nested_object_content>
                    {{helperService.checkUndefined(customer[column.field.split('.')[0]][column.field.split('.')[1]], column.default)}}
                </ng-template>
                <ng-template #normal_content>
                    {{helperService.checkUndefined(customer[column.field], column.default)}}
                </ng-template>
                <p-button *ngIf="column.isButton" label="{{column.buttonName}}"
                    (click)="onActionClick(customer,column.buttonEvent)"></p-button>
            </td>
            <td *ngIf="trailingTableIcons">
                <ng-container *ngTemplateOutlet="trailingTableIcons"></ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>