import { VerificationService } from "./verification.service";
import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import {
  GroupAccess,
  ComponentRole,
  UserAccess,
} from "../models/access-group.model";
import { AccessRights } from "../models/app-enums.model";
import { HttpRequestData } from "../models/backend-api.model";
import { BackendApiService } from "./backend-api.service";
import { LoaderService } from "./loader.service";
import { Store } from "./store.service";
import { ClientType } from "../enums/client-type.enum";

@Injectable({ providedIn: "root" })
export class AccessGroupsService {
  allAccessGroups?: GroupAccess[];
  allGroupsChanged = new Subject<GroupAccess[]>();
  userAccessGroups?: UserAccess[];
  userAccessRoles = new BehaviorSubject<any>(null);

  application = "b2b_wallet";
  componentsToDisable: string[] = [];
  private _componentNames: string[] = [];

  constructor(
    private store: Store,
    private api: BackendApiService,
    private ls: LoaderService,
    private verificationService: VerificationService
  ) {
    this.application = this.ls.clientConfig.initProvider.application ?? "b2b_wallet";
    this.setComponentNames();
  }

  get componentNames() {
    return this._componentNames.slice();
  }

  setComponentNames() {
    let bankingType = this.ls.clientConfig.initProvider.clientType;

    const componentsForNeobanks = [
      "Account",
      "Payment",
    ];

    const componentsForCryptoExchanges = [
      "Dashboard",
      "Trading",
      "Smart Coin",
      "Deposit",
      "Withdraw",
      "Linked Bank Accounts",
      "Saved Cards",
    ];


    switch (bankingType) {
      case ClientType.neoBanking:
        this._componentNames = componentsForNeobanks;
        break;
      case ClientType.cryptoExchange:
        this._componentNames = componentsForCryptoExchanges;
        break;
      case ClientType.demo:
        this._componentNames = [...componentsForNeobanks, ...componentsForCryptoExchanges];
        break;
    }

    this._componentNames.push(...[
      this.application === 'b2b_wallet' ? "Business Details" : "Personal Details",
      "Settings",
      "Notifications",
    ]);
  }

  subscribeUserAccessRoles = (callback: (value: ComponentRole[]) => void) =>
    this.userAccessRoles.subscribe(callback);

  getAllAccessGroups() {

    let orgId = "";
    const customerData = this.store.customerData.value;
    if (customerData.id) {
      orgId = customerData.person?.organizationId;
    }

    const data = new HttpRequestData();
    data.bodyParams = {
      application: this.application,
      organizationId: orgId,
    };

    const apiRequest = this.api.request(
      this.api.apiEndpoints.accessGroups.getAllGroups(),
      data,
      "customerMicroService"
    );
    apiRequest.subscribe({
      next: (response) => {
        this.allAccessGroups = response;
        this.allGroupsChanged.next(this.allAccessGroups ?? []);
      },
    });

    return apiRequest;
  }

  addGroup(payload: any) {
    const data = new HttpRequestData();
    data.bodyParams = payload;
    return this.api.request(
      this.api.apiEndpoints.accessGroups.addGroup(),
      data,
      "customerMicroService"
    );
  }

  getUserAccessGroups(userId?: string) {
    const data = new HttpRequestData();
    data.bodyParams = {
      userId: userId ?? this.store.userId,
      application: this.application,
    };

    return this.api.request(
      this.api.apiEndpoints.accessGroups.getAccessControl(),
      data,
      "customerMicroService"
    );
  }

  getCustomerAccessGroups(customerId?: string) {
    const data = new HttpRequestData();
    data.bodyParams = {
      customerId: customerId ?? this.store.customerId,
      application: this.application,
    };

    return this.api.request(
      this.api.apiEndpoints.accessGroups.getAccessControl(),
      data,
      "customerMicroService"
    );
  }


  updateGroup(payload: any, groupId?: string) {
    const data = new HttpRequestData();
    data.bodyParams = payload;
    return this.api.request(
      this.api.apiEndpoints.accessGroups.updateGroup(groupId),
      data,
      "customerMicroService"
    );
  }

  revokeComponentAccessRight(customerId: string, groupId: string) {
    const data = new HttpRequestData();
    data.bodyParams = {
      "customerId": customerId,
      "groupId": groupId,
      "application": this.application
    }
    return this.api.request(this.api.apiEndpoints.adminPanel.user.revokeGroup(), data, "customerMicroService");
  }


  setUserAccessGroups(userAccess: UserAccess[]) {
    this.userAccessGroups = userAccess;
    const accessRoles: ComponentRole[] = [];
    for (const accessGroup of userAccess) {
      if (accessGroup.roles) {
        accessRoles.push(...accessGroup.roles);
      }
    }

    // if all profile childs are not_visible, then hide profile
    const profileChilds = ['Business Details', 'Linked Bank Accounts', 'Saved Cards'];
    let allchildrenNotVisible = true;
    for (const profileChild of profileChilds) {
      const childAccessRole = accessRoles.find(accessRole => accessRole.componentName === profileChild);
      if (!childAccessRole || childAccessRole.accessRight !== AccessRights.no_visibility) {
        allchildrenNotVisible = false;
        break;
      }
    }

    if (allchildrenNotVisible) accessRoles.push({ componentName: 'Profile', accessRight: AccessRights.no_visibility });
    this.userAccessRoles.next(accessRoles);
    this.UpdateRolesForAccountVerification();
  }

  setManualUserGroups() {
    const accessRoles: ComponentRole[] = [];
    this.userAccessRoles.next(accessRoles);
    this.UpdateRolesForAccountVerification();
  }

  getComponentRole = (componentName: string) =>
    this.userAccessRoles.value?.find(
      (role: ComponentRole) => role.componentName == componentName
    )?.accessRight ?? AccessRights.full_control;

  setDisabledComponents() {
    this.componentsToDisable = [
      "Dashboard",
      "Deposit",
      "Withdraw",
      "Trading",
      "Smart Coin",
      "Notifications",
      "Linked Bank Accounts",
      "Saved Cards",
    ];
  }

  UpdateRolesForAccountVerification() {
    // update Roles Based on account verification
    if (
      !this.verificationService.isAccountVerified &&
      this.userAccessRoles.value &&
      this.componentsToDisable.length
    ) {
      const accessRoles = this.userAccessRoles.value;
      for (const componentName of this.componentNames) {
        if (this.componentsToDisable.includes(componentName)) {
          // check if component is already present in userAccessRoles, if not add it with read access
          // if present and has full_control, update the access right to read
          const accessRole = accessRoles.find(
            (role: ComponentRole) => role.componentName === componentName
          );
          if (!accessRole) {
            accessRoles.push({
              componentName: componentName,
              accessRight: AccessRights.read,
              application: this.application,
            });
          } else if (accessRole.accessRight === AccessRights.full_control) {
            accessRole.accessRight = AccessRights.read;
          }
        }
      }
      this.userAccessRoles.next(accessRoles);
    }
  }
}
