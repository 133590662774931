import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ActiveSession } from "../interface/active-session.interface";

@Injectable({
  providedIn: "root",
})
export class Store {
  loggedIn: boolean;
  ipAddress = "";
  tenantId = localStorage.getItem("tenantId") || "";
  defaultOrgId = "";
  spotTradingWalletId = "";
  application = "b2c_wallet";

  customerId = localStorage.getItem("customerId") || "";
  userId = localStorage.getItem("userId") || "";
  orgId = localStorage.getItem("orgId") || "";
  personId = localStorage.getItem("personId") || "";
  credentials: any[] = JSON.parse(localStorage.getItem("credentials") || "[]");
  twoFaInfo: any[] = JSON.parse(localStorage.getItem("twoFaInfo") || "[]");

  countryList: any[] = [];
  activeSessions: ActiveSession[] = [];
  customerData = new BehaviorSubject<any>({});

  private _apimToken = new BehaviorSubject<string>(
    localStorage.getItem("apimToken") || ""
  );
  private _userToken = new BehaviorSubject<string>(
    localStorage.getItem("userToken") || ""
  );

  apimToken$ = this._apimToken.asObservable();
  userToken$ = this._userToken.asObservable();

  constructor() {
    this.loggedIn = localStorage.getItem("isUserLoggedIn") === "true";
  }

  get apimToken(): string {
    return this._apimToken.value;
  }

  set apimToken(token: string) {
    this._apimToken.next(token);
    localStorage.setItem("apimToken", token);
  }

  get userToken(): string {
    return this._userToken.value;
  }

  set userToken(token: string) {
    this._userToken.next(token);
    localStorage.setItem("userToken", token);
  }

  updateLocalStorage(
    key:
      | "apimToken"
      | "userToken"
      | "tenantId"
      | "customerId"
      | "userId"
      | "orgId"
      | "personId",
    value: any
  ): void {
    localStorage.setItem(key, value);
    if (key === "apimToken") {
      this.apimToken = value;
    } else if (key === "userToken") {
      this.userToken = value;
    } else {
      (this as any)[key] = value;
    }
  }
}
