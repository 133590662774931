<div class="wrapper">
    <div *ngIf="loadingStatus == LoadingStatus.error" class="flex flex-column align-items-center">
        <span class="font-bold text-6xl">Oops!</span>
        <p-image src="/assets/images/something_went_wrong.png" alt="Something went wrong" width="200" height="150"
            class="my-4">
        </p-image>
        <span class="font-bold text-2xl">Something went wrong.</span>
        <span class="text-lg my-2">Please contact your admin.</span>
        <button *ngIf="!environment.production" pButton type="button" class="btn-primary p-button-text"
            label="Load Anyway (For Dev)" (click)="onLoadAnyWay.emit()"></button>
    </div>
    <div *ngIf="loadingStatus == LoadingStatus.loading" class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</div>