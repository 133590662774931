import { Component, NgModule, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { ToastNotificationService } from "../../../services/toast-notification.service";

@Component({
  selector: "lib-toast-notification",
  templateUrl: "./toast-notification.component.html",
  styleUrls: ["./toast-notification.component.css"],
  providers: [MessageService],
})
export class ToastNotificationComponent implements OnInit {
  notification: any;
  constructor(
    private messageService: MessageService,
    private toastNotificationService: ToastNotificationService
  ) {
    this.toastNotificationService.notification.subscribe((message) => {
      this.notification = message;
      if (message) {
        this.showNotification();
      }
    });
  }

  ngOnInit(): void {}
  showNotification() {
    this.messageService.add(this.notification);
  }
}

@NgModule({
  declarations: [ToastNotificationComponent],
  imports: [ToastModule],
  exports: [ToastNotificationComponent],
})
export class ToastNotificationModule {}
