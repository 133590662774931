import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from 'src/app/shared/models';
import { LocalstorageService } from './localstorage.service';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from './api-http.service';
import { AuthenticationService } from 'sepa/framework/src/lib/services/authentication.service';
import { STATIC_DI } from 'sepa/framework/src/lib/staticdi';
import { BackendApiService } from 'sepa/framework/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private userAccessSubject: BehaviorSubject<any>;
  public userAccess: Observable<any>;

  constructor(
    private http: ApiHttpService,
    public ls: LocalstorageService,
    private injector: Injector,
    private api: BackendApiService //public sepaAuth: AuthenticationService,
  ) {
    const userJson = this.ls.getItem('currentUser');
    var user = userJson !== null ? JSON.parse(userJson) : new User();
    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();

    const accessJson = this.ls.getItem('userAccess');
    var access = accessJson !== null ? JSON.parse(accessJson) : null;
    this.userAccessSubject = new BehaviorSubject<any>(access);
    this.userAccess = this.userAccessSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get userAccessValue(): User {
    return this.userAccessSubject.value;
  }

  login(user: User) {
    const sepaAuth = this.injector.get<AuthenticationService>(
      AuthenticationService
    );
    this.ls.setItem(STATIC_DI.ENVIRONMENT.tokenKey, `Bearer ${user.token}`);
    this.ls.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    sepaAuth.authState.next(true);
  }

  logout() {
    this.makeLocalStorageEmpty();
    location.reload();
  }

  makeLocalStorageEmpty() {
    this.ls.removeItem('currentUser');
    this.currentUserSubject.next(new User());

    this.ls.removeItem('userAccess');
    this.userAccessSubject.next({});
  }

  updateProfileDetails(form) {
    const url = apis.admin.updateProfileDetails;
    return this.http.put(url, form);
  }

  updateUserAccess(userAccess: any) {
    this.ls.setItem('userAccess', JSON.stringify(userAccess));
    this.userAccessSubject.next(userAccess);
  }

  verifyPassword(password) {
    const url = apis.admin.verifyPassword;
    return this.http.post(url, { password });
  }
  getAllUsers(tenantId: string, groupIds: any) {
    const data = {
      organizationId: tenantId,
      groupId: groupIds,
      meta: {
        skip: 0,
        limit: 0,
        page: 0,
      },
    };
    const payload = {
      bodyParams: { ...data },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.user.getAllUsers(),
      payload,
      'customerMicroService'
    );
  }
  createUser(body) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.user.createUser(),
      payload,
      'customerMicroService'
    );
  }
  updateUser(id, body) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.user.updateUser(id),
      payload,
      'customerMicroService'
    );
  }
  blockUser(id) {
    return this.api.request(
      this.api.apiEndpoints.adminPanel.user.bockUser(id, 'blacklisted'),
      null,
      'customerMicroService'
    );
  }
  revokeGroup(body) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.user.revokeGroup(),
      payload,
      'customerMicroService'
    );
  }

  assignGroup(body) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.user.assignGroup(),
      payload,
      'customerMicroService'
    );
  }
  recoverCredentials(body) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.createAccount.recoverCredentials,
      payload,
      'customerMicroService'
    );
  }

  resetCredentials(body) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.createAccount.resetCredential,
      payload,
      'customerMicroService'
    );
  }

  verifyCredentials(body) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.createAccount.verifyCredentials,
      payload,
      'customerMicroService'
    );
  }
}
