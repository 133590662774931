<app-title-card [title]="'Fees'">
</app-title-card>

<sepa-table 
    [loading]="loading"
    [pageConfig]="pageConfig"
    [tableConfig]="tableConfig"
    [tableData]="tableData"
    [totalRecords]="totalRecords"

    (onLazyLoad)="onLazyLoad" 
    (selectAllChange)="selectAllChange"
></sepa-table>