import { Component, OnInit, Input, TemplateRef } from "@angular/core";
import { Table } from "primeng/table";
import { BackendApiService } from "../../../services/backend-api.service";
import { ApiEndpoint, HttpMethod } from "../../../models/backend-api.model";
import { HttpHeaders } from "@angular/common/http";
import { EventsService } from "../../../services/event.service";
import { LazyLoadEvent } from "primeng/api";

@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.css"],
})
export class TableComponent implements OnInit {
  @Input() leadingTableIcons?: TemplateRef<any>;
  @Input() trailingTableIcons?: TemplateRef<any>;
  @Input() data!: any;
  @Input() showPagination = true;
  @Input() showSearch = true;
  @Input() apiEndPoint?: ApiEndpoint;
  @Input() customCaptionComponent?: TemplateRef<any>;

  public columns!: {
    name: string;
    field: string;
    subfielddata: string;
    sort: string;
    isButton: boolean;
    buttonName: string;
    buttonEvent: string;
  }[];

  customers!: any[];
  totalRecords!: number;
  loading: boolean = true;

  title?: string;
  filterList?: string[];
  rows: number = 10;
  captionCheckboxText?: string;

  constructor(
    private api: BackendApiService,
    private eventService: EventsService
  ) {}

  ngOnInit() {
    this.columns = JSON.parse(this.data.columns);
    if (this.data.filterList) {
      this.filterList = JSON.parse(this.data.filterList);
    }
    this.title = this.data.title;
    this.rows = +this.data.rows ?? 10;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true,
    };
    let api_url = this.data.data_source;
    if (this.data.check_url_params === true) {
      //todo
    }
    this.api.apiEndpoints.grid = {
      getGrid: new ApiEndpoint(api_url, HttpMethod.Get, httpOptions),
    };
  }

  loadCustomers(event: LazyLoadEvent) {
    this.loading = true;
    this.api
      .request(
        this.apiEndPoint ?? this.api.apiEndpoints.grid.getGrid,
        null,
        this.data.apiFor ?? "mockData"
      )
      .subscribe((res) => {
        if (res) {
          if (this.data.body_param) {
            this.customers = res[this.data.body_param].slice(
              event.first,
              event.first! + event.rows!
            );
            this.totalRecords = res[this.data.body_param].length;
          } else {
            this.customers = res.responseBody.slice(
              event.first,
              event.first! + event.rows!
            );
            this.totalRecords = res.responseBody.length;
          }
          this.loading = false;
        } else {
        }
      });
  }

  onSelectAllChange(event: any) {
    const checked = event.checked;
    if (checked) {
    } else {
    }
  }

  clear(table: Table) {
    table.clear();
  }

  onActionClick(row: any, eventName: string) {
    this.eventService.fireEvent(eventName, row);
  }
}
