<div class="grid">
  <div class="col-12 md:col-6 lg:col-1"></div>
  <div class="col-12 md:col-6 lg:col-10">
    <div
      class="col-12 text-center md:col-6 lg:col-12"
      style="color: greenyellow"
    >
      <i style="font-size: 60px" [ngClass]="data.icon"></i>
    </div>
    <div class="col-12 text-center md:col-6 lg:col-12">
      <h2 style="margin-bottom: 10">
        <p style="margin-bottom: 0">{{ data.headerText }}</p>
      </h2>
      <h3 style="margin-top: 0; font-weight: 100">{{ data.message }}</h3>
    </div>
    <div class="col-12 text-center md:col-6 lg:col-12">
      <button
        (click)="onCloseDialog()"
        pButton
        pRipple
        type="button"
        label="Confirm"
        class="p-button-raised btn-tab font-bold"
        [style.backgroundColor]="'red'"
        style="color: white; width: 50%"
      ></button>
    </div>
  </div>
</div>
