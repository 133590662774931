import { ApiRequest, HttpMethod } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';
export const wallets = environment.tenantWallets;

export const apiHost = environment.proxyUrl;
export const mockHost = environment.proxyUrl;
export const museHost = environment.proxyUrl + 'email/send';
export const museSms = environment.proxyUrl + 'sms/mobica/send';
export const upload = environment.proxyUrl + 'api/v1/aml/upload-document';
export const getdocs = environment.proxyUrl + 'aml/get-documents/';
export const downloaddoc =
  environment.proxyUrl + 'aml/1.0.0/document/';
export const apiBase = apiHost + 'api/v1/';
export const ipgApiBase = apiHost + 'api/ipg/';
export const mockbase = mockHost + 'api/v1/';
export const defaultWalletId = 'abd9381c-74c9-4384-88e5-f91a8e03012c';

export const apis = {
  cdnUrl: '',
  user: {
    login: apiHost + `oauth/${environment.apiVersion.customerMS}/auth/token`,
  },
  admin: {
    getApiManagerToken: apiHost + 'oauth2/token',
    getLoginToken:
      apiHost + 'customer/' + environment.apiVersion.museKrakenMS + '/login',
    loginnew: apiBase + 'Users/login',
    login: apiBase + 'admin/login',
    getUserInfo: apiBase + 'admin/getUserInfo',
    updateProfileDetails: apiBase + 'admin/updateProfileDetails',
    verifyPassword: apiBase + 'admin/verifyPassword',
  },
  customer: {
    // getAllCustomers: apiBase + 'persons/getAllCustomers',
    //getAllCustomers: apiBase + 'customer/getAllCustomers',
    getAllCustomers:
      apiHost +
      'customer/' + environment.apiVersion.customerMS +
      '/getAllCustomers',
    getCustomersByName: apiBase + 'admin/getCustomersByName',
    getCustomerById: (customerId: any) =>
      apiHost +
      'customer/' +
       environment.apiVersion.customerMS +
      `/organization/getById/${customerId}`,
    getIndividualCustomerById: (customerId: any) =>
      apiHost +
      'customer/' +
       environment.apiVersion.customerMS +
      `/getDetailsById/${customerId}`,
    getIndividualCustomerByOrgId: (orgId: any) =>
      apiHost +
      'customer/' +
       environment.apiVersion.customerMS +
      `/getByOrganizationId/${orgId}`,
    // bulkUpload: apiBase + 'admin/bulkUpload',
    // bulkUpload: apiBase + 'persons/registerAll',
    bulkUpload: apiBase + 'shareholder/create/',
    bulkUploadIndividual: apiHost + 'customer/3.0.12/createBulk',
    // bulkUploadBusiness: apiBase + 'organization/registerAll',
    bulkUploadBusiness:
      apiHost +
      'customer/' +
       environment.apiVersion.museKrakenMS +
      '/organization/create',

    // getAllBusinessCustomers: apiBase + 'admin/getAllBusinessCustomers',
    getAllBusinessCustomers:
      apiHost +
      'customer/'
       +
       environment.apiVersion.customerMS +
      '/organization/getAll',
    getBusinessCustomersByName: apiHost + 'admin/getBusinessCustomersByName',
    getBusinessById: apiHost + 'organization/getById/',
    getAllViewers: apiHost + 'admin/getAllViewers',
    updateViewerById: apiHost + 'admin/updateViewerById',
  },
  muse: {
    email: museHost,
    sms: museSms,
    upload: upload,
    getdocs: getdocs,
    downloaddoc: downloaddoc,
  },
  fee: {
    getAllFees: apiBase + 'fee/getAllFees',
    getFeeById: apiBase + 'fee/getFeeById',
    create: apiBase + 'fee/create',
    updateFeeById: apiBase + 'fee/updateFeeById',
    deleteFeeById: apiBase + 'fee/deleteFeeById',
  },
  group: {
    availableGroups: apiBase + 'customer/getAllGroup',
    create: apiBase + 'customer/addGroup',
    updateGroupById: apiBase + 'group/updateGroupById',
    allUsersInGroupById: apiBase + 'group/allUsersInGroupById',
  },
  people: {
    allAdminUsers: apiBase + 'customer/getAllUsersByGroup',
    create: apiBase + 'customer/create',
    updateUserDetails: apiBase + 'admin/updateUserDetails',
    addComponentAccessRight: apiBase + 'customer/addComponentAccessRight',
    revokeComponentAccessRight: apiBase + 'customer/revokeComponentAccessRight',
    deleteByEmail: apiBase + 'admin/deleteByEmail',
  },
  chart: {
    pie: {
      customersByKycStatus: apiBase + 'admin/getAllKysStatuses',
      customersByBusinesss: apiBase + 'admin/getAllViewersDividedByBusiness',
      customersByCountry: apiBase + 'admin/getAllCustomersDevidedByCountry',
    },
    bar: {
      customersByMonth: apiBase + 'admin/getAllCustomersDevidedByMonth',
    },
  },
  kraken: {
    ledgers: apiBase + 'kraken/ledgers',
    orders: apiBase + 'kraken/orders',
    trades: apiBase + 'kraken/trades/history',
  },
  others: {
    getCountries: apiBase + 'countries/getCountries',
  },
  faqs: {
    getAllFaqs: apiBase + 'faqs/getAllFaqs',
    getFaqById: apiBase + 'faqs/getFaqById',
    create: apiBase + 'faqs/add',
    updateFaqsById: apiBase + 'faqs/updateById',
    deleteFeeById: apiBase + 'faqs/delete',
  },
  ticket: {
    getAllTickets: apiBase + 'ticket/getAllTickets',
    getTicketById: apiBase + 'ticket/getTicketById',
    create: apiBase + 'ticket/create',
    updateTicketById: apiBase + 'ticket/updateById',
    deleteTicketById: apiBase + 'ticket/delete',
  },
  email: {
    updateEmailTemplateById: apiBase + 'email/updateById',
    getAllEmailTemplates: apiBase + 'email/getAllEmailTemplates',
    getEmailTemplateById: apiBase + 'email/getEmailTemplateById',
    create: apiBase + 'email/create',
    deleteEmailTemplateById: apiBase + 'email/delete',
  },
  currency: {
    getAllCurrency: apiBase + 'currency/getAllCurrency',
    getCurrencyById: apiBase + 'currency/getCurrencyById',
    create: apiBase + 'currency/create',
    updateCurrencyById: apiBase + 'currency/updateById',
    deleteCurrencyById: apiBase + 'currency/delete',
  },
  transactions: {
    getTradesHistory: apiHost + `sct/kraken/1.0.0/getTradesHistory`,
    krakenClosedOrders: (walletId = 1) =>
      apiBase + `wallets/${walletId}/krakenClosedOrders`,
    assignTransaction: apiBase + `wallets/transactionHistory`,
    getTradingHistory: (id, type) => apiBase + `${id}/trading-order-history?businessType=${type}`
  },
  wallets: {
    getWalletById: (walletId) => apiHost + 'wallet/' +environment.apiVersion.customerMS + `/${walletId}`,
    getWalletsByCustomerId: (customerId) =>
    apiHost + 'wallet/customer/' + customerId,
    getWalletsByOrgId: (orgId) =>
    apiHost + 'wallet/b2b-customers/' + orgId,
    asset: {
      createAsset: (walletId) => apiHost + `wallets/${walletId}/asset`,
      createAssetOperation: (walletId, assetId) =>
      apiHost + `wallets/${walletId}/asset/${assetId}/operation`,
      create: apiHost + 'wallets/asset/create',
      updateWalletAssetById: (walletId, assetId) =>
      apiHost + `wallet/${walletId}/asset/${assetId}`,
      updateAssetStatusById: (walletId, assetId) =>
      apiHost + `wallet/${walletId}/asset/${assetId}/status`,
      updateAssetById:
      apiHost +
        'wallets/abd9381c-74c9-4384-88e5-f91a8e03012c/asset/{assetId}/opeation/{operationId}/limit/{limitId}',
      deleteAssetById: apiHost + 'wallet/asset/delete',
      updateFundById: apiHost + 'wallet/asset/updateFundById',
    },
    //getCurrencies: () => apiBase + 'currencies'
    getCurrencies: () => apiHost + 'wallet/'+environment.apiVersion.customerMS+'/getAll/currencies?type=all', //https://muse-mi-stg.local.sepa-cyber.com/wallet/getAll/currencies?type=all
    getSmartCoinWalletAvailableBalance: apiHost + `wallet/1.0.0/smart-coin/wallet-available-balance`,
    getOrders: () => apiBase + 'orders',
    approve: () => apiBase + 'status',
    getAvailableBalace: () => apiBase + 'spot-trading-available-balance',
    getBulkAmounts: () =>
      apiBase + 'withdraw-bulk-ready-aggregate-orders-amount',
    confirmBulkWithdraw: () => apiBase + 'bulk-withdraw-confirm',
    completeWithdraw: () => apiBase + 'complete-withdraw',

  },
  rule: {
    getAllRules: () => apiHost + 'rules/get-all',
    updateRule: (id) => apiHost + `rules/${id}`,
  },
  ipg: {
    addMerchantDataInput: apiHost + 'api/ipg/backoffice/configure-organization',
    getOrganizationData: (organization_id) =>
      apiHost + `api/ipg/backoffice/organizations/${organization_id}/configurations`,
  },
  smartCoin: {
    getTransactionHistoryByTenantAndStatus: (status) =>
    apiHost +
      `sc/${environment.apiVersion.customerMS}/transactionHistoryByTenantAndStatus?page=0&limits=0&orderStatus=${status}`,
    getTransactionHistoryByTenant:
    apiHost + `sc/${environment.apiVersion.customerMS}/transactionHistoryByTenant?page=0&limits=0`,
    getTransactionHistoryByCustomerId: (customerId) =>
    apiHost +
      `sc/${environment.apiVersion.customerMS}/transactionHistoryByCustomerId?page=0&limits=0&orderStatus=${customerId}`,
    getTransactionHistorySumByTenantAndStatus: (status) =>
    apiHost +
      `sc/${environment.apiVersion.customerMS}/getTransactionHistorySumByTenantAndStatus?page=0&limits=0&orderStatus=${status}`,
    reserveToken: apiHost + `sc/${environment.apiVersion.customerMS}/getDataForTokenReserve`,
    transferToken: apiHost + `sc/${environment.apiVersion.customerMS}/getDataForTokenTransfer`,
    swapToken: apiHost + `sc/${environment.apiVersion.customerMS}/getDataForSwap`,
    transferUSDT: apiHost + `sc/${environment.apiVersion.customerMS}/transferUSDTtoESCROW`,
  },
  aml: {
    setDocumentstatus: environment.proxyUrl + 'api/v1/aml/documents/',
  },
  oauth: {
    login: () => apiHost + `oauth/${environment.apiVersion.customerMS}/auth/token`,
    session: (token: string) => apiHost + `oauth/${environment.apiVersion.customerMS}/auth/session?token=${token}`,
    logout: () => apiHost + `oauth/${environment.apiVersion.customerMS}/auth/logout`,
  },  
};
