import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetsComponent } from './assets/assets.component';
import { OperationsComponent } from './operations/operations.component';
import { EditAssetModelComponent } from './models/edit-asset-model/edit-asset-model.component';
import { AddAssetModelComponent } from './models/edit-asset-model/add-asset-model.component';
import { EditOperationModelComponent } from './models/edit-operation-model/edit-operation-model.component';
import { AddOperationModelComponent } from './models/edit-operation-model/add-operation-model.component';
import { SepaTableComponent } from './sepa-table/sepa-table.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ErrortostringPipe } from 'src/app/shared/pipes/errortostring.pipe';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ComponentsModule } from 'src/app/shared/module/shared.module';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { CalendarModule } from 'primeng/calendar';
import { PasswordModule } from 'primeng/password';
import { MenuModule } from 'primeng/menu';
import { FieldsetModule } from 'primeng/fieldset';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TabMenuModule } from 'primeng/tabmenu';
import { LimitComponent } from './limit/limit.component';
import { FeeComponent } from './fee/fee.component';
import { WalletContainerComponent } from './wallet-container/wallet-container.component';
import { PrimeIcons } from 'primeng/api';
import { ChipsModule } from 'primeng/chips';
import { ModalComponent } from './modal/modal.component';

@NgModule({
  declarations: [
    AssetsComponent,
    ErrortostringPipe,
    OperationsComponent,
    EditAssetModelComponent,
    AddAssetModelComponent,
    EditOperationModelComponent,
    AddOperationModelComponent,
    SepaTableComponent,
    ConfirmationDialogComponent,
    LimitComponent,
    FeeComponent,
    WalletContainerComponent,
    ModalComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    CardModule,
    ButtonModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CheckboxModule,
    TooltipModule,
    DividerModule,
    CalendarModule,
    PasswordModule,
    MenuModule,
    FieldsetModule,
    TabViewModule,
    ComponentsModule,
    ToastModule,
    TabMenuModule,
    PanelMenuModule,
    ChartModule,
    ConfirmDialogModule,
    InputSwitchModule,
    RadioButtonModule,
    OverlayPanelModule,
    PaginatorModule,
    InputTextareaModule,
    ChipsModule,
  ],
  exports: [
    AssetsComponent,
    OperationsComponent,
    EditAssetModelComponent,
    AddAssetModelComponent,
    EditOperationModelComponent,
    AddOperationModelComponent,
    SepaTableComponent,
    ConfirmationDialogComponent,
    ChipsModule,
    ModalComponent
  ],
})
export class SharedModule {}
