import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SepaFrameworkComponent } from "./sepaframework.component";

import { CardModule } from "primeng/card";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { PanelModule } from "primeng/panel";
import { ButtonModule } from "primeng/button";
import { ImageModule } from "primeng/image";
import { FormsModule } from "@angular/forms";
import { AppRoutingModule } from "./sepaframework-routing.module";
import { httpInterceptorProviders } from "./interceptors";
import { STATIC_DI } from "./staticdi";
import { ToastNotificationModule } from "./components/shared/toast-notification/toast-notification.component";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { InstrumentationService } from "./services/instrumentation.service";
import { LoaderService } from "./services/loader.service";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";
import { LoadingComponent } from "./components/shared/loading/loading.component";
import { Observable, tap } from "rxjs";
import { AuthInterceptor } from "./interceptors/auth.interceptor";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export function loadClientConfig(
  loaderService: LoaderService
): () => Observable<any> {
  return () =>
    loaderService
      .loadConfig()
      .pipe(tap((clientConfig) => {
        loaderService.setClientConfigs(clientConfig);
      }));
}

@NgModule({
  declarations: [SepaFrameworkComponent, LoadingComponent],
  imports: [
    BrowserModule,
    CardModule,
    PasswordModule,
    InputTextModule,
    PanelModule,
    ButtonModule,
    ImageModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastNotificationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    httpInterceptorProviders,
    InstrumentationService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (inistService: InstrumentationService) => () =>
        inistService.initSentry(),
      deps: [InstrumentationService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadClientConfig,
      deps: [LoaderService],
      multi: true,
    },
  ],
  exports: [SepaFrameworkComponent],
})
export class SepaFrameworkModule {
  static forRoot(config: any): ModuleWithProviders<SepaFrameworkModule> {
    STATIC_DI.ROUTES = config.routes;
    STATIC_DI.ENVIRONMENT = config.environment;
    STATIC_DI.AUTH_ROUTES = config.authChildRoutes;
    return {
      ngModule: SepaFrameworkModule,
      // providers: [{ provide: 'environments', useValue: config.environment }],
    };
  }
}
