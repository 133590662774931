import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DashboardAdminComponent implements OnInit {
  basicOptions: any;

  pieCustomerData: any;
  barCountryData: any;
  barMonthData: any;
  barBusinessData: any;

  constructor() {}

  ngOnInit() {
    this.pieCustomerData = {
      labels: ['Pending', 'Verified', 'Decline'],
      datasets: [
        {
          data: [300, 50, 100],
          backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
          hoverBackgroundColor: ['#64B5F6v', '#81C784', '#FFB74D'],
        },
      ],
    };
    this.barCountryData = {
      labels: [
        'Bulgaria',
        'Afganistan',
        'Turkey',
        'New Zealand',
        'United Arab Emirates',
        'Cypress',
        'Namibia',
        'India',
        'Barbados',
      ],
      datasets: [
        {
          label: 'Customer countries',
          backgroundColor: '#42A5F5',
          data: [65, 59, 80, 81, 56, 55, 40, 10, 21],
        },
      ],
    };
    this.barMonthData = {
      labels: ['Olympic', 'Sofia Mall'],
      datasets: [
        {
          label: 'Customer business',
          backgroundColor: '#42A5F5',
          data: [45, 5],
        },
      ],
    };
    this.barBusinessData = {
      labels: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      datasets: [
        {
          label: 'Customer counts',
          backgroundColor: '#42A5F5',
          data: [65, 59, 80, 81, 56, 55, 40, 10, 21, 21, 21, 21],
        },
      ],
    };

    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: '#ebedef',
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: '#ebedef',
          },
          grid: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
        y: {
          ticks: {
            color: '#ebedef',
          },
          grid: {
            color: 'rgba(255,255,255,0.2)',
          },
        },
      },
    };
  }
}
