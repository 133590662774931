import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from "@angular/core";
import { VerificationStatus } from '../models/verification-status.model';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  sideMenuOptionSelected = new Subject<any>();
  kybVerificationStatusChanged = new BehaviorSubject<VerificationStatus[]>([]);
  isAccountVerified = false;

  constructor() { }

}