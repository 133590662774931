import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { map, tap } from 'rxjs/operators';
import { BackendApiService, LoaderService } from 'sepa/framework/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  tenantId: any;

  constructor(
    public http : ApiHttpService,
    public api: BackendApiService,
    private loaderService: LoaderService
  ) {
    const initData = this.loaderService.clientConfig.initProvider;
    this.tenantId = initData.tenantId;
  }

  pagesList = [
    {title : "Dashboard",       slug:"dashboard" },
    {title : "Customers",       slug:"customers" },
    {title : "Currency Management",    slug:"currencyManagement" },
    {title : "Fee Management",  slug:"feeManagement" },
    {title : "Groups",          slug:"groups" },
    {title : "People",          slug:"people" },
    {title : "Transactions",    slug:"transactions" },
  ]

  availableGroups = [];
  getAvailableGroups(){
    const url = apis.group.availableGroups;
    return this.http.post(url,{application: "admin_wallet", "organizationId": "string"}).pipe(
      tap((res:any)=>this.availableGroups = res.data)
    );
  }

  getAllGroups() {
    const tenantId = this.tenantId;
    const data = {
      bodyParams: {
        application: 'admin_wallet',
        organizationId: tenantId?.toString(),
      }
    };
    return this.api.request(this.api.apiEndpoints.accessGroups.getAllGroups(), data, 'customerMicroService')
    .pipe(map((res: any) => res));
  }
  // getAllUsersByGroup(groupId: any) {
  //   let tenantId = localStorage.getItem('tenantId');
  //   const data = {
  //     bodyParams: {
  //       application: 'admin_wallet',
  //       organizationId: tenantId?.toString(),
  //     }
  //   };
  //   return this.api.request(apiEndpoints.adminPanel.accessGroups.getAllUsersByGroup(), data, 'customerMicroService')
  //   .pipe(map((res: any) => res));
  // }

  addGroup(body) {
    const tenantId = this.tenantId;
    body.application = "admin_wallet";
    body.organizationId= tenantId?.toString(),
    console.log('create group body', body)
    const data = {
      bodyParams: body
    };
    return this.api.request(this.api.apiEndpoints.accessGroups.addGroup(), data, 'customerMicroService')
    .pipe(map((res: any) => res));
  }

  getAllUsersInGroupById(groupId){
    const body = {groupId};
    const url = apis.group.allUsersInGroupById;
    return this.http.post(url, body);
  }

  create(body){
    body.application = "admin_wallet";
    body.organizationId= "string";
    console.log('create group body', body)
    const url = apis.group.create;
    return this.http.post(url, body);
  }

  updateGroupById(body){
    const url = apis.group.updateGroupById;
    return this.http.put(url, body);
  }

  deleteByEmail(email){
    const url = apis.people.deleteByEmail;
    return this.http.delete(url, {email : email});
  }



}
