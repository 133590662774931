import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  constructor(
    public http : ApiHttpService,
  ) { }

  allCustomers = [];
  getAllCustomers(pageObject: any){
    const url = apis.customer.getAllCustomers;
    return this.http.post(url, pageObject).pipe(
      tap((res:any)=>this.allCustomers = res.data)
    );
  }

  getCustomerById(userId: any){
    const body = {userId};
    const url = apis.customer.getCustomerById(userId);
    return this.http.post(url, body);
  }

  bulkUpload(businessName: any, array: any){
    const body = {businessName, array};
    const url = apis.customer.bulkUpload;
    return this.http.post(url, body);
  }

}
