import { Subject } from "rxjs";
import { BehaviorSubject, map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpRequestData } from "../models/backend-api.model";
import { BackendApiService } from "./backend-api.service";
import { CurrencyPair } from "../models/wallet.model";
import { Store } from "./store.service";

@Injectable({ providedIn: "root" })
export class WalletService {
  isLive = true;
  purpose = "spot_trading";
  web3purpose = "web3";
  walletType = "customer_account";
  walletChanged = new BehaviorSubject<any>([]);
  web3WalletChanged = new BehaviorSubject<any>([]);

  assetAdded = new BehaviorSubject<any>([]);
  depositCardData: any = {};
  withdrawCardData: any = {};
  bankTransferData: any;
  walletId!: string;
  web3WalletId!: string;

  allCurrencies: any[] = [];
  currenciesChanged = new BehaviorSubject<any>([]);
  allowedTerminalCurrencies: any[] = [];
  savedCardsChanged = new BehaviorSubject<any>([]);

  currencyPairChanged = new Subject<CurrencyPair>();
  intermsOfChanged = new BehaviorSubject<string>("EUR");
  application = "b2c_wallet";

  orderPriceChange = new Subject<string>();
  tradingTableLoading = new BehaviorSubject(false);

  constructor(
    private store: Store,
    private api: BackendApiService,
  ) {
    this.application = this.store.application;
    this.getCurrencies("all").subscribe({
      next: (response) => {
        this.allCurrencies = response.value;
        this.currenciesChanged.next(response.value);
      },
    });
    this.getWallet();
    this.getSavedCards();
    this.bankTransferData = {};
  }

  getWallet() {
    const orgId = this.store.orgId ?? localStorage.getItem("orgId");
    const walletRequest =
      this.application === "b2b_wallet"
        ? this.api.request(
          this.api.apiEndpoints.wallet.getWalletByOrgId(orgId),
          null,
          "walletMicroService"
        )
        : this.api.request(
          this.api.apiEndpoints.wallet.getWalletByCustomerId(this.store.customerId),
          null,
          "walletMicroService"
        );

    walletRequest.subscribe({
      next: (response) => {
        // Filter customer wallet
        const customerWallet = response.value?.find(
          (wallet: any) => wallet.purpose == this.purpose
        );
        if (customerWallet) {
          this.walletId = customerWallet.id;
          this.walletChanged.next(customerWallet);
          this.store.spotTradingWalletId = customerWallet.id;
        } else {
          if (this.allCurrencies.length) this.createWallet();
          else {
            this.getCurrencies("all").subscribe({
              next: (response) => {
                this.allCurrencies = response.value;
                this.createWallet();
              },
            });
          }
        }

        //Filter web3 wallet
        const web3Wallet = response.value?.find(
          (wallet: any) => wallet.purpose == this.web3purpose
        );
        if (web3Wallet) {
          this.web3WalletId = web3Wallet.id;
          this.web3WalletChanged.next(web3Wallet);
        }
      },
    });
  }

  createWallet() {
    // Get euro currency to add as a defualt asset
    const euroCurrency = this.allCurrencies.find(
      (currency) =>
        currency.aliases?.filter(
          (alias: any) => alias.shortname == "EUR" && alias.default
        ).length
    );

    let defaultAssets: any[] = [];

    if (euroCurrency) {
      const defaultAlias = euroCurrency.aliases?.find(
        (alias: any) => alias.default
      );
      defaultAssets.push({
        purpose: "spot-trading",
        type: "currency",
        status: "new",
        instanceBase: defaultAlias?.id,
        availableBalance: {
          balanceType: "available",
          name: defaultAlias?.shortname,
          value: "0",
          instanceRef: euroCurrency.id,
          instanceBaseRef: defaultAlias?.id,
        },
      });
    }
    const data = new HttpRequestData();
    data.bodyParams = {
      purpose: this.purpose,
      isLive: this.isLive,
      type: this.walletType,
      customerId: this.store.customerId,
      assets: defaultAssets,
    };

    this.api
      .request(
        this.api.apiEndpoints.wallet.createWallet(),
        data,
        "walletMicroService"
      )
      .subscribe({
        next: (response) => {
          this.getWallet();
          //this.toastNotificationService.setNotification({ severity: "success", detail: "New access group added" });
        },
      });
  }

  addAsset(data: any): Observable<any> {
    const payload = new HttpRequestData();
    payload.bodyParams = data;
    return this.api.request(
      this.api.apiEndpoints.wallet.createAsset(this.walletId),
      payload,
      "walletMicroService"
    );
  }

  getCurrencies(type: string): Observable<any> {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    data.queryParams = {
      type: type,
    };
    return this.api.request(
      this.api.apiEndpoints.wallet.getCurrency(),
      data,
      "walletMicroService"
    );
  }

  createOrder(data: any) {
    const payload = new HttpRequestData();
    payload.bodyParams = data;
    return this.api.request(
      this.api.apiEndpoints.wallet.createOrder(this.walletId),
      payload,
      "walletMicroService"
    );
  }

  getAssetByCustomer(): Observable<any> {
    return this.api
      .request(
        this.api.apiEndpoints.wallet.getWalletByCustomerId(this.store.customerId),
        null,
        "walletMicroService"
      )
      .pipe(
        map((response) => {
          const customerWallet = response.value?.find(
            (wallet: any) => wallet.purpose == this.purpose
          );
          return customerWallet.assets;
        })
      );
  }

  getAssetAvailableBalance(assetId: string, percentage: string) {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    return this.api.request(
      this.api.apiEndpoints.wallet.getAssetAvailableBalance(
        this.walletId,
        assetId,
        percentage
      ),
      data,
      "walletMicroService"
    );
  }

  getCurrencyPairs() {
    return this.api.request(
      this.api.apiEndpoints.wallet.getCurrencyPairs(this.walletId),
      null,
      "walletMicroService"
    );
  }

  getOrders(type: string, time?: string) {
    const data = new HttpRequestData();
    data.urlAppend = undefined;
    switch (type) {
      case "openOrders":
        return this.api.request(
          this.api.apiEndpoints.wallet.getOpenOrders(this.walletId),
          null,
          "walletMicroService"
        );
      case "orderHistory":
        return this.api.request(
          this.api.apiEndpoints.wallet.getOrderHistory(
            this.walletId,
            time ?? "2D"
          ),
          data,
          "walletMicroService"
        );
      case "tradeHistory":
        return this.api.request(
          this.api.apiEndpoints.wallet.getTradeHistory(
            this.walletId,
            time ?? "2D"
          ),
          data,
          "walletMicroService"
        );
      default:
        return this.api.request(
          this.api.apiEndpoints.wallet.getOpenOrders(this.walletId),
          null,
          "walletMicroService"
        );
    }
  }

  getFunds() {
    return this.api.request(
      this.api.apiEndpoints.wallet.getFunds(this.walletId),
      null,
      "walletMicroService"
    );
  }

  getTransactions() {
    return this.api.request(
      this.api.apiEndpoints.wallet.getTransactions(this.walletId),
      null,
      "walletMicroService"
    );
  }

  getAllOrders() {
    return this.api.request(
      this.api.apiEndpoints.wallet.getOrders(this.walletId),
      null,
      "walletMicroService"
    );
  }

  getSavedCard() {
    return this.api.request(
      this.api.apiEndpoints.ipg.getSavedCard(this.store.customerId, this.store.application === 'b2b_wallet' ? this.store.orgId : this.store.defaultOrgId),
      null,
      "ipgMs"
    );
  }

  getSavedCards() {
    const customerId =
      this.store.customerId || localStorage.getItem("customerId");
    
    let organizationId;
    
    // Check if the application exists and is either 'b2b_wallet' or 'b2c_wallet'
    if (this.store.application === "b2b_wallet" || this.store.application === "b2c_wallet") {
      organizationId = this.store.orgId || localStorage.getItem("orgId");
    } else {
      organizationId = this.store.defaultOrgId || localStorage.getItem("defaultOrgId");
    }
  
    // If orgId is still undefined, fallback to localStorage's orgId regardless of the application
    organizationId = organizationId || localStorage.getItem("orgId");
  
    if (!customerId || !organizationId) {
      console.error("Missing IDs - Customer ID or Organization ID is missing.");
      throw new Error("Customer ID or Organization ID is missing.");
    }
  
    return this.api.request(
      this.api.apiEndpoints.ipg.getSavedCard(customerId, organizationId),
      null,
      "ipgMs"
    );
  }
  

  cancelOrder(orderId: string) {
    return this.api.request(
      this.api.apiEndpoints.wallet.cancelOrder(this.walletId, orderId),
      null,
      "walletMicroService"
    );
  }

  getFee(feePayload: any) {
    const payload = new HttpRequestData();
    payload.bodyParams = feePayload;
    return this.api.request(
      this.api.apiEndpoints.ruleManagement.getFee(),
      payload,
      "ruleManagementMs"
    );
  }

  createBankDetail(data: any) {
    const payload = new HttpRequestData();
    payload.bodyParams = data;
    return this.api.request(
      this.api.apiEndpoints.wallet.createBankDetail(this.walletId),
      payload,
      "walletMicroService"
    );
  }

  getBankDetailByCustomer(customerId: string) {
    return this.api.request(
      this.api.apiEndpoints.wallet.getBankDetailByCustomer(customerId),
      null,
      "walletMicroService"
    );
  }

  getBankDetailByTenant() {
    return this.api.request(
      this.api.apiEndpoints.wallet.getBankDetailByTenant(),
      null,
      "walletMicroService"
    );
  }

  updateBankDetail(data: any, id: string) {
    const payload = new HttpRequestData();
    payload.bodyParams = data;
    return this.api.request(
      this.api.apiEndpoints.wallet.updateBankDetail(id),
      payload,
      "walletMicroService"
    );
  }

  deleteBankCard(organizationId: string, registrationId: string) {
    const payload = new HttpRequestData();
    payload.bodyParams = {
      organizationId: organizationId,
      registrationId: registrationId,
    };
    return this.api.request(
      this.api.apiEndpoints.ipg.deleteByRegistrationId(),
      payload,
      "ipgMs"
    );
  }

  deleteBankAccount(bannkAccountId: string) {
    return this.api.request(
      this.api.apiEndpoints.wallet.deleteBankDetail(bannkAccountId),
      null,
      "walletMicroService"
    );
  }

  registerToken(data: any) {
    const payload = new HttpRequestData();
    payload.bodyParams = data;
    return this.api.request(
      this.api.apiEndpoints.ipg.registerToken(),
      payload,
      "ipgMs"
    );
  }

  public getCardtypes(cardType: string) {
    if (cardType == 'visa') {
      return 'VISA';
    } else if (cardType == 'mastercard') {
      return 'MC';
    } else if (cardType == 'amex') {
      return 'AMEX';
    } else if (cardType == 'jcb') {
      return 'JCB ';
    } else {
      return '';
    }
  }
}
