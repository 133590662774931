import { filter, take } from "rxjs/operators";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { AccessGroupsService } from "../services/access-groups.service";
import { AccessComponent } from "../models/access-group.model";
import { AccessRights } from "../models/app-enums.model";
import { DepositWithdrawService } from "../services/deposit-withdraw.service";
import { DepositWithdrawTypes } from "../enums/deposit-withdraw/deposit-withraw-types.enum";
import { LoaderService } from "../services/loader.service";
import { ClientType } from "../enums/client-type.enum";

// used to check if account data is there
@Injectable({
  providedIn: "root",
})
export class AccessControlGuard implements CanActivateChild {
  accessComponents: AccessComponent[] = [];
  application = 'b2b_wallet'
  constructor(
    private router: Router,
    private accessGroupsService: AccessGroupsService,
    private depositWithdrawService: DepositWithdrawService,
    private ls: LoaderService
  ) {
    this.application = this.ls.clientConfig.initProvider.application;
    this.setAccessComponents();
  }

  setAccessComponents() {
    let bankingType = this.ls.clientConfig.initProvider.clientType;
    const groupsForNeobanks = [
      {
        groupName: "Account",
        startWithUrls: ["account"],
      },
      {
        groupName: "Payment",
        startWithUrls: ["payment"],
      },
    ];

    const groupsForCryptoBanks = [
      {
        groupName: "Dashboard",
        startWithUrls: ["dashboard"],
      },
      {
        groupName: "Trading",
        startWithUrls: ["trading"],
      },
      {
        groupName: "Smart Coin",
        startWithUrls: ["smart-coin"],
      },
      {
        groupName: "Deposit",
        startWithUrls: ["deposit-withdraw"],
      },
      {
        groupName: "Withdraw",
        startWithUrls: ["deposit-withdraw"],
      },
      {
        groupName: "Linked Bank Accounts",
        startWithUrls: ["linked-bank-accounts"],
      },
      {
        groupName: "Saved Cards",
        startWithUrls: ["saved-cards"],
      },
    ]

    switch (bankingType) {
      case ClientType.neoBanking:
        this.accessComponents = groupsForNeobanks;
        break;
      case ClientType.cryptoExchange:
        this.accessComponents = groupsForCryptoBanks;
        break;
      case ClientType.demo:
        this.accessComponents = [...groupsForNeobanks, ...groupsForCryptoBanks];
        break;
    }

    this.accessComponents.push(
      ...[
        {
          groupName: "Profile",
          startWithUrls: ["profile"],
        },
        {
          groupName: "Settings",
          startWithUrls: ["settings"],
        },
        {
          groupName: "Notifications",
          startWithUrls: ["notifications"],
        },
        this.application === 'b2b_wallet' ? {
          groupName: "Business Details",
          startWithUrls: ["business-details"],
        } : {
          groupName: "Personal Details",
          startWithUrls: ["personal-detail"],
        },
        {
          groupName: "Support Ticket",
          startWithUrls: ["support"],
        },
      ]);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    // apply this guard only for personal and business projects or possibly for a specific client
    if (
      this.ls.clientConfig.initProvider?.application !== "b2b_wallet" &&
      this.ls.clientConfig.initProvider?.application !== "b2c_wallet"
    )
      return true;

    return this.accessGroupsService.userAccessRoles.pipe(
      filter((accessRoles) => accessRoles != null),
      take(1),
      map((): boolean | UrlTree => {
        return this.checkRouting(state.url)
      })
    );
  }

  checkRouting(url: string): boolean | UrlTree {
    // get the starting url after shared
    const routeComponentGroupRole = this.getRouteComponentRole(url);
    if (routeComponentGroupRole === AccessRights.no_visibility) {
      this.router.navigate(["/shared/" + this.getNextAllowedRoute()]);
      return false;
    } else {
      return true;
    }
  }

  getRouteComponentRole(url: string): AccessRights {

    const splitedUrls = url.split("/shared/");
    const filterdUrl1 = splitedUrls.length > 1 ? splitedUrls[1] : "";

    // handle for profile sub routes
    if (filterdUrl1.split("/").includes("profile")) {
      // check for profile parent access
      const profileAccess =
        this.accessGroupsService.getComponentRole("Profile");
      if (profileAccess === AccessRights.no_visibility)
        return AccessRights.no_visibility;
      //else check for profile sub routes
      const profileSubRoutes = filterdUrl1.split("/");
      const subRouteGroupName = this.accessComponents.find((x) =>
        x.startWithUrls.includes(
          profileSubRoutes.length > 1 ? profileSubRoutes[1] : ""
        )
      )?.groupName;
      return subRouteGroupName
        ? this.accessGroupsService.getComponentRole(subRouteGroupName)
        : AccessRights.no_visibility;
    }
    if (filterdUrl1.split("/").includes("deposit-withdraw")) {
      // check if either deposit or withdraw
      if (
        this.depositWithdrawService.activeType.value ===
        DepositWithdrawTypes.DEPOSIT_FIAT ||
        this.depositWithdrawService.activeType.value ===
        DepositWithdrawTypes.DEPOSIT_CRYPTO
      ) {
        return this.accessGroupsService.getComponentRole("Deposit") ===
          AccessRights.full_control
          ? AccessRights.full_control
          : AccessRights.no_visibility;
      }
      if (
        this.depositWithdrawService.activeType.value ===
        DepositWithdrawTypes.WITHDRAW_FIAT ||
        this.depositWithdrawService.activeType.value ===
        DepositWithdrawTypes.WITHDRAW_CRYPTO
      ) {
        return this.accessGroupsService.getComponentRole("Withdraw") ===
          AccessRights.full_control
          ? AccessRights.full_control
          : AccessRights.no_visibility;
      }

      return AccessRights.no_visibility;
    }

    const filterdUrl2 = filterdUrl1.split('/')[0];
    if (filterdUrl2 === 'aboutus') return AccessRights.full_control;
    const routeComponentGroup = this.accessComponents.find(x => x.startWithUrls.includes(filterdUrl2))?.groupName;

    return routeComponentGroup ? this.accessGroupsService.getComponentRole(routeComponentGroup) : AccessRights.no_visibility;
  }


  getNextAllowedRoute(): string {
    const notAllowedRoutes = ['Deposit', 'Withdraw', this.application === 'b2b_wallet' ? 'Business Details' : 'Personal Details', 'Linked Bank Accounts', 'Saved Cards', 'Support Ticket'];
    for (const accessComponent of this.accessComponents) {
      if (!notAllowedRoutes.includes(accessComponent.groupName) && this.accessGroupsService.getComponentRole(accessComponent.groupName) !== AccessRights.no_visibility) {
        return accessComponent.startWithUrls[0];
      }
    }
    return 'aboutus';
  }

}
