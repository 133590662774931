import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";
    const token = localStorage.getItem("userToken");
    const tokenExpiry = localStorage.getItem("tokenExpiry");
    const now = Math.floor(Date.now() / 1000);

    if (isLoggedIn && token && tokenExpiry) {
      if (now >= +tokenExpiry) {
        console.log("Token is expired. Logging out...");
        this.authService.logout();
        return throwError("Token is expired");
      }

      // Clone the request to add the token header
      request = request.clone({
        setHeaders: {
          token: token,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.authService.handleUnauthorizedResponse(error.status);
        return throwError(error);
      })
    );
  }
}
