export class User {
    id          ?: number;
    email       ?: string;
    mobile      ?: string;
    password    ?: string;
    firstName   ?: string;
    lastName    ?: string;
    status      ?: string;
    groupId     ?: number;
    token       ?: string;
    role        ?: string = "admin";
}