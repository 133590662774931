import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders, HttpEvent, HttpEventType } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {

  constructor(private http: HttpClient) { }

  public getString (object: any) {
    let paramsAndValues:any[] = [];
    for(let key in object) {
      let value = encodeURIComponent(object[key].toString());
      paramsAndValues.push([key, value].join('='));
    }
    return paramsAndValues.join('&');
  }

  public postStringHold (object: any) {
    var body = new HttpParams();
    for(let key in object) {
      let value = encodeURIComponent(object[key].toString());
      body = body.append(key,value);
    }
    return body;
  }

  public postString (object: any) {
    var body = new FormData();
    for(let key in object) {
      let value = encodeURIComponent(object[key].toString());
      body.append(key,value);
    }
    return body;
  }

  public toFormData( formValue: any ) {
    const formData = new FormData();

    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      formData.append(key, value);
    }

    return formData;
  }

  public get(url: string, data?: any, options?: any) {
    return this.http.get(url +"?"+ this.getString(data), options);
  }

  public getStraight(url: string, data?: any, options?: any) {
    return this.http.get(url, options);
  }

  public post(url: string, data?: any, options?: any) {
    // data = this.postString(data);
    return this.http.post(url, data, options);
  }

  public put(url: string, data?: any, options?: any) {
    return this.http.put(url, data, options);
  }

  public patch(url: string, data?: any, options?: any) {
    return this.http.patch(url, data, options);
  }

  public delete(url: string, body?: any) {
    console.log(url,body);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: body,
    }
    return this.http.delete(url, options);
  }

  public request(req:ApiRequest) {
    let res;
    if(req.method == HttpMethod.Post){
      res = this.post(req.url, req.data, req.options)
    }else if(req.method == HttpMethod.Put){
      res = this.put(req.url, req.data, req.options)
    }else if(req.method == HttpMethod.Delete){
      res = this.delete(req.url, req.data)
    }else{
      res = this.get(req.url, req.data, req.options)
    }
    if(req.mapFn){
      return res.pipe(map(req.mapFn))
    }
    return res
  }
}

export enum HttpMethod {
  Get,
  Post,
  Delete,
  Put,
  Head
}


export class ApiRequest {
  public method: HttpMethod = HttpMethod.Get;
  public url: string = "";
  public data?: any;
  public options?: any;
  public mapFn?: any;

  constructor(obj?: Partial<ApiRequest>) {
    Object.assign(this, obj);
  }
}
