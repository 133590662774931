<form [formGroup]="editFormGroup" (ngSubmit)="onSubmit()">
  <div class="ui-fluid ui-corner-all modal-body m-1 m-lg-2 pt-0">
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="operationType" class="block">Operation Type</label>
      </div>
      <div class="ui-g-12">
        <p-dropdown autoWidth="false" [options]="formOptions.operationType"
        [style]="{'width':'100%'}" [(ngModel)]="selectedOperationType" formControlName="operationType"
        formControlName="operationType" placeholder="Select Operation type"></p-dropdown>
        <ng-container *ngIf="editFormGroup.get('operationType')?.touched || formSubmited">
          <small class="p-error block"
              *ngFor="let errorMsg of editFormGroup.controls['operationType'].errors | keyvalue">
              {{"operationType" | errortostring : errorMsg}}
          </small>
      </ng-container>
      </div>
    </div>
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="operationType" class="block">Asset Type</label>
      </div>
      <div class="ui-g-12">
        <p-dropdown #assetType autoWidth="false" [options]="formOptions.assetType" optionLabel="name"
        [style]="{'width':'100%'}" (onClick)="filterByType(assetType.selectedOption)"
        formControlName="assetType" placeholder="Select Asset type"></p-dropdown>
        <ng-container *ngIf="editFormGroup.get('assetType')?.touched || formSubmited">
          <small class="p-error block"
              *ngFor="let errorMsg of editFormGroup.controls['assetType'].errors | keyvalue">
              {{"assetType" | errortostring : errorMsg}}
          </small>
      </ng-container>
      </div>
    </div>
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="operationType" class="block">Instance</label>
      </div>
      <div class="ui-g-12">
        <p-dropdown #assetType autoWidth="false" [options]="formOptions.instance" optionLabel="name"
        [style]="{'width':'100%'}" (onClick)="filterByType(assetType.selectedOption)"
        formControlName="instance" placeholder="Select Instance"></p-dropdown>
        <ng-container *ngIf="editFormGroup.get('instance')?.touched || formSubmited">
          <small class="p-error block"
              *ngFor="let errorMsg of editFormGroup.controls['instance'].errors | keyvalue">
              {{"instance" | errortostring : errorMsg}}
          </small>
      </ng-container>
      </div>
    </div>
    <div style="display:flex; justify-content: center; padding-top: 2rem;">
      <button pButton type="submit" label="Update"></button>
    </div>

  </div>
</form>
