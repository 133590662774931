export const messages = {
    success : {
        updateProfileDetails: 'Profile details have been saved.',
        updateGroupById: 'Group details have been saved.',
        createGroup: 'Group have been created.',
        groupMemberDelete: 'Group member have been deleted.',
        updateUserDetails: 'User details have been saved.',
        createUser: 'User have been created.',
        currencyDelete: 'Currency have been deleted.',
        updateFeeById: 'Fee details have been saved.',
        createFee: 'Fee have been created.',
        deleteFeeById: 'Fee have been deleted.',
        updateCustomerDetails: 'Customer details have been saved.',
        deleteTradePairById: 'Trade pair have been deleted.',
        updateTicket : 'Ticket have been updated.',
        updateFaqById :'Faq have been updated.',
        createFaq : 'Faq have been updated.',
        deleteTicketById : 'Ticket have been deleted',
        updateEmailTemplateById : 'Email template have been saved.',
        createCurrency : 'Currency Have been created',
        updateCurrencyById: 'Currency have been updated.',
        updateAssetById: 'Asset details have been updated.',
        createAsset: 'Asset have been created.',
        createFund : 'Fund have been created.',
        updateFundById : 'Fund details have been updated.',
        deleteLimitById: 'Limit have been deleted.',
        createLimit : 'Limit have been created.',
        updateLimitById : 'Limit have been updated.',
        updateOperationById : 'Operation have been updated',
        createWalletOperation : 'Operation has been created'
    },
    error : {
        updateProfileDetails: 'Some thing is not correct.',
    },
    confirm : {
        groupMemberDelete: "Delete member",
        currencyDelete: "Delete currency",
        feeDelete: "Delete fee",
        tradePairDelete: "Delete trade pair",
        ticketDelete: "Delete ticket",
        limitDelete:"Delete limit"
    },
    texts : {
        noDataToDisplay : "There is no match in the list",
        dataFatching : "Please wait... data is fatching from server."
    },
    sms:{
    verificationmessage: ""
    },




}
