import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AuthService, HelperService } from 'src/app/shared/services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  error = '';
  showPassword = false;
  myForm: FormGroup;
  formSubmitted: boolean = false;
  queryParams: any;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public help: HelperService
  ) {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => (this.queryParams = params));
  }

  login() {
    this.error = '';
    this.formSubmitted = true;

    if (this.myForm.valid) {
      const { email, password } = this.myForm.value;
      this.authService.onLogin(email, password).subscribe({
        next: () => {
          // Navigate to the return URL or default to the dashboard
          const returnUrl = this.queryParams['returnUrl'] || '/dashboard';
          this.router.navigateByUrl(returnUrl);
        },
        error: (error: any) => {
          console.error(error);
          this.error =
            error.error?.description || 'Login failed. Please try again.';
          this.help.notify('error', this.error);
        },
      });
    }
  }
}
