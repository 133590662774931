import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(public http:ApiHttpService) 
  { }
  allFaqs = [];
  getAllFaqs(){
      const url = apis.faqs.getAllFaqs;
      return this.http.post(url).pipe(
          tap((res:any)=>this.allFaqs = res.data)
      );
  }
  getFaqById(faqId){
    const body = {faqId};
    const url = apis.faqs.getFaqById;
    return this.http.post(url, body);
}

create(faqObj){
    const body = faqObj;
    console.warn(faqObj);
    
    const url = apis.faqs.create;
    return this.http.post(url, body);
}

updateFaqById(faqObj){
    const body = faqObj;
    const url = apis.faqs.updateFaqsById;
    return this.http.put(url, body);
}

deleteFaqById(faqId){
    const url = apis.faqs.deleteFeeById;
    return this.http.delete(url, {faqId : faqId});
}
}
