import { Component, OnInit } from "@angular/core";
import { UtilsService } from "../../services/utils.service";

@Component({
  selector: "lib-auth",
  templateUrl: "./auth.component.html",
  styleUrls: ["./auth.component.css"],
})
export class AuthComponent implements OnInit {
  constructor(
    public utils: UtilsService
  ) {}

  ngOnInit(): void {}
}
