import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalstorageService } from '../services';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        public ls: LocalstorageService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userToken = localStorage.getItem('userToken');
        const isLoggedIn = localStorage.getItem('isUserLoggedIn') === 'true';
        const apiManagerToken = localStorage.getItem('apimToken');

        // Skip adding Authorization and token headers if the request is for oauth2/token
        if (request.url.includes('/oauth2/token')) {
            return next.handle(request);
        }

        // Add tenant ID
        request = this.addTenantId(request);
        
        // Add API Manager token as Authorization header
        request = this.addApiManagerToken(request, apiManagerToken);

        // If the user is logged add the userToken and keep Authorization unchanged
        if (isLoggedIn && userToken && apiManagerToken) {
            request = this.addUserToken(request, userToken, apiManagerToken);
        }

        return next.handle(request);
    }

    // Method to add tenant headers
    addTenantId(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                "x-tenant-id": `${environment.tenantId}`,
                "tenantId": `${environment.tenantId}`
            }
        });
    }

    // Method to add API Manager token as Authorization header if available
    addApiManagerToken(request: HttpRequest<any>, apiManagerToken: string | null): HttpRequest<any> {
        if (apiManagerToken && !request.headers.has('Authorization')) {
            return request.clone({
                setHeaders: {
                    Authorization: apiManagerToken
                }
            });
        }
        return request;
    }

    // Method to add userToken to `token` header while keeping Authorization unchanged
    addUserToken(request: HttpRequest<any>, userToken: string, apiManagerToken: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: apiManagerToken,
                token: userToken
            }
        });
    }
}
