import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { map, tap } from 'rxjs/operators';
import { BackendApiService } from 'sepa/framework/src/public-api';
import { HttpRequestData } from 'sepa/framework/src/lib/models/backend-api.model';

@Injectable({
    providedIn: 'root'
})
export class TransactionHistoryService {

    constructor(public http: ApiHttpService, private api: BackendApiService) { }

    allTransactionsHistory = [];
    getAllTransactionHistory(pageObject: any) {
        // const url = apis.transactions.getAllTransactionsHistory;
        const url = "apis.transactions.getAllTransactionsHistory";
        return this.http.get(url, pageObject).pipe(
            tap((res: any) => this.allTransactionsHistory = res.data)
        );
    }

    idWiseTransactionsHistory = [];
    getTransactionById(pageObject: any) {
        const url = "apis.transactions.getAllTransactionsHistory";
        return this.http.get(url, pageObject).pipe(
            map((res: any) => {
                res.forEach((element: { status: string | any[]; }) => {
                    console.log(element);
                    if (element.status && element.status.length > 0) {
                        element.status = element.status[element.status.length - 1].status
                    } else {
                        element.status = 'rejected'
                    }
                });
                return res
            })
        );
    }

    changeOrderStatus(order: { id: any; }, status: any) {
        let orderId = order.id
        const url = "apis.transactions.changeOrderStatus(orderId)";
        return this.http.post(url, { orderId, status, jsondata: JSON.stringify(order) })
    }

    getTradesHistory(){
        let url = apis.transactions.getTradesHistory;
        return this.http.post(url);
    }

    getTradingOrderHistory(id,type){
        const data = new HttpRequestData();
        data.urlAppend = undefined;
        return this.api.request(this.api.apiEndpoints.adminPanel.wallet.getTradingHistory(id, type), data, 'walletMicroService')
    }

    getDepositWithdrawHistory(id,type){
      const data = new HttpRequestData();
      data.urlAppend = undefined;
      return this.api.request(this.api.apiEndpoints.adminPanel.wallet.getDepositWithdrawHistory(id, type), data, 'walletMicroService')
  }

    krakenClosedOrders(){
        let url = apis.transactions.krakenClosedOrders();
        return this.http.get(url);
    }

    assignTransaction(body){
        let url = apis.transactions.assignTransaction;
        return this.http.post(url,body);
    }

}
