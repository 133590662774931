<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <div class="ui-fluid ui-corner-all modal-body m-1 m-lg-2 pt-0">
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="operationType" class="block">Operation Type</label>
      </div>
      <div class="ui-g-12">
        <p-dropdown autoWidth="false" [options]="formOptions.operationType"
        [style]="{'width':'100%'}" [(ngModel)]="selectedOperationType" formControlName="operationType"
        formControlName="operationType" placeholder="Select Operation type"></p-dropdown>
        <ng-container *ngIf="formGroup.get('operationType')?.touched || formSubmited">
          <small class="p-error block"
              *ngFor="let errorMsg of formGroup.controls['operationType'].errors | keyvalue">
              {{"operationType" | errortostring : errorMsg}}
          </small>
      </ng-container>
      </div>
    </div>
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="operationType" class="block">Asset Type</label>
      </div>
      <div class="ui-g-12">
        <p-dropdown #assetType autoWidth="false" [options]="formOptions.assetType" optionLabel="name"
        [style]="{'width':'100%'}" (onClick)="filterByType(assetType.selectedOption)"
        formControlName="assetType" placeholder="Select Asset type"></p-dropdown>
        <ng-container *ngIf="formGroup.get('assetType')?.touched || formSubmited">
          <small class="p-error block"
              *ngFor="let errorMsg of formGroup.controls['assetType'].errors | keyvalue">
              {{"assetType" | errortostring : errorMsg}}
          </small>
      </ng-container>
      </div>
    </div>
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="operationType" class="block">Instance</label>
      </div>
      <div class="ui-g-12">
        <p-dropdown #assetType autoWidth="false" [options]="formOptions.instance" optionLabel="name"
        [style]="{'width':'100%'}" (onClick)="filterByType(assetType.selectedOption)"
        formControlName="instance" placeholder="Select Instance"></p-dropdown>
        <ng-container *ngIf="formGroup.get('instance')?.touched || formSubmited">
          <small class="p-error block"
              *ngFor="let errorMsg of formGroup.controls['instance'].errors | keyvalue">
              {{"instance" | errortostring : errorMsg}}
          </small>
      </ng-container>
      </div>
    </div>
    <div style="display:flex; justify-content: center; padding-top: 2rem;">
      <button pButton type="submit" label="Add"></button>
    </div>

  </div>
    <!-- <div class="modal-body m-1 m-lg-2 pt-1">
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="username2" class="block">Operation type</label>
                    <p-dropdown [options]="formOptions.operationType" formControlName="operationType" placeholder="Select Operation type"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('operationType')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['operationType'].errors | keyvalue">
                            {{"Operation type" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="username2" class="block">Asset Type</label>
                    <p-dropdown [options]="formOptions.assetType" formControlName="assetType" placeholder="Select Asset Type"
                        optionLabel="name"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('assetType')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['assetType'].errors | keyvalue">
                            {{"Asset Type" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="username2" class="block">Description</label>
                    <input id="username2" placeholder="Enter Description" formControlName="description"
                        aria-describedby="username2-help" pInputText />
                    <ng-container *ngIf="formGroup.get('description')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['description'].errors | keyvalue">
                            {{"Description" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="username2" class="block">Instance</label>
                    <p-dropdown [options]="formOptions.instance" formControlName="name" placeholder="Select Instance"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('name')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['name'].errors | keyvalue">
                            {{"Instance" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="username2" class="block">Source</label>
                    <p-dropdown [options]="formOptions.sourceDestination" formControlName="source" placeholder="Select Source"
                        optionLabel="name"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('source')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['source'].errors | keyvalue">
                            {{"Source" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="username2" class="block">Destination</label>
                    <p-dropdown [options]="formOptions.sourceDestination" formControlName="destination" placeholder="Select Destination"
                        optionLabel="name"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('destination')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['destination'].errors | keyvalue">
                            {{"Destination" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div style="display:flex; justify-content: center; margin-right: 2rem">
          <button pButton type="submit" label="Create"></button>
        </div>
    </div> -->
    <!-- <div style="display:flex; justify-content: center; margin-right: 2rem">
      <button pButton type="submit" label="Create"></button>
    </div> -->
</form>
