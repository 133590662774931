import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { GroupService, HelperService } from 'src/app/shared/services';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css'],
})
export class GroupsComponent implements OnInit, OnDestroy {
  title: any;
  isGroupDisplayed = false;
  isDeleteDisplayed = false;

  currentGroup;
  isViewGroupMode = false;

  permissions = [
    { title: 'Full Control', key: 'full_control' },
    { title: 'Read', key: 'read' },
    { title: 'Reporting', key: 'Reporting' },
    { title: 'Not Visible', key: 'no_visibility' },
  ];

  pages = [
    { name: 'Dashboard', permission: 'Full Control' },
    { name: 'Currency Management', permission: 'Full Control' },
    { name: 'Fee Management', permission: 'Full Control' },
    { name: 'Groups', permission: 'Full Control' },
    { name: 'People', permission: 'Full Control' },
    { name: 'Transactions', permission: 'Full Control' },
  ];

  emptyGroup = { name: '', pages: this.pages, users: [] };

  componentsList = [
    {
      title: 'Dashboard',
      slug: 'dashboard',
    },
    {
      title: 'Information Management',
      slug: 'information_management',
    },
    {
      title: 'Active Customer Requests',
      slug: 'active_customer_requests',
    },
    {
      title: 'Business Customers',
      slug: 'business_customers',
    },
    {
      title: 'Individual Customers',
      slug: 'individual_customers',
    },
    // {
    //    title:"Business Customers Detail",
    //    slug:"parent"
    // },
    {
      title: 'Business details and verification',
      slug: 'business_details_and_verification',
    },
    {
      title: ' Authorization Management',
      slug: 'authorization_management',
    },
    // {
    //   title:"Individual Customers Detail",
    //   slug:"parent"
    // },
    {
      title: 'Individual details and verification',
      slug: 'individual_details_and_verification',
    },
    {
      title: 'Fee Management',
      slug: 'fee_management',
    },
    {
      title: 'Access Groups',
      slug: 'groups',
    },
    {
      title: 'Users',
      slug: 'users',
    },
    {
      title: 'Transaction History',
      slug: 'transaction_history',
    },
  ];

  components: any[] = [];
  groups: any[] = [];
  groupForm: FormGroup = this.fb.group({
    groupId: [''],
    organizationId: ['string'],
    tenantId: [''],
    groupName: ['fwf'],
    roles: this.fb.array([]),
  });
  getAvailableGroupsSubscribtion: any;
  getAllGroupsSubscribtion: any;
  addGroupSubscribtion: any;

  constructor(
    private ref: ChangeDetectorRef,
    public help: HelperService,
    private fb: FormBuilder,
    public groupService: GroupService
  ) {}

  ngOnInit(): void {
    this.currentGroup = this.emptyGroup;
    this.components = this.componentsList;
    this.getAllGroups();
    this.addRoles();
  }

  getAvailableGroups() {
    this.getAvailableGroupsSubscribtion = this.groupService
      .getAvailableGroups()
      .subscribe({
        next: (res) => {
          console.log(res);
          this.groups = res;
        },
      });
  }

  getAllGroups() {
    this.getAllGroupsSubscribtion = this.groupService.getAllGroups().subscribe({
      next: (res) => {
        console.log(res);
        this.groups = res;
      },
    });
  }

  addRoles() {
    this.groupForm.setControl('roles', this.fb.array([]));
    let roles = this.groupForm.get('roles') as FormArray;
    this.components.forEach((comp) => {
      roles.push(
        this.fb.group({
          componentTitle: [comp.title],
          componentName: [comp.slug],
          accessRight: ['full_control'],
        })
      );
    });
    setTimeout(() => this.ref.detectChanges(), 1000);
  }

  get refRolesArray() {
    return this.groupForm.get('roles') as FormArray;
  }

  createUpdateGroup() {
    if (this.groupForm.status == 'VALID') {
      let body = this.groupForm.value;
      body.roles = body.roles.map((i) => {
        delete i.componentTitle;
        return i;
      });
      delete body.groupId;
      delete body.tenantId;
      this.addGroupSubscribtion = this.groupService
        .addGroup(this.groupForm.value)
        .subscribe({
          next: (res) => {
            console.log(res);
            let msg = this.isViewGroupMode
              ? 'Group updated successfully'
              : 'Group created successfully';
            this.help.notify('success', msg);
            this.getAllGroups();
            this.closeGroupModal();
          },
        });
    }
  }
  //Group Modal
  openGroupModal(group?) {
    console.log('view group modal data', group);
    this.isGroupDisplayed = true;
    this.isViewGroupMode = false;
    this.groupForm.reset();
    this.addRoles();
    if (group) {
      this.isViewGroupMode = true;
      group.roles = this.components.map((comp) => {
        let role = group.roles.find((i) => i.componentName == comp.slug) || {
          accessRight: 'full_control',
        };
        return {
          componentTitle: comp.title,
          componentName: comp.slug,
          accessRight: role.accessRight,
        };
      });
      this.groupForm.setValue(group);
    }
  }

  closeGroupModal() {
    this.isGroupDisplayed = false;
    this.currentGroup = this.emptyGroup;
  }

  //Delete Modal

  openDeleteModal(selectedUser) {
    console.log(selectedUser);
    this.isDeleteDisplayed = true;
  }

  closeDeleteModal(isUserToBeDeleted: boolean) {
    this.isDeleteDisplayed = false;
  }
  ngOnDestroy(): void {
    this.getAvailableGroupsSubscribtion?.unsubscribe();
    this.getAllGroupsSubscribtion?.unsubscribe();
    this.addGroupSubscribtion?.unsubscribe();
  }
}
