import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { messages } from 'src/app/shared/defaults';
import { HelperService, WalletsService } from 'src/app/shared/services';

export interface MappedCurrency {
    Purpose: any,
    Type: any,
    Status: any,
    AliaseId: any,
    CurrencyId: any
  //   instanceBase: aliase.id,
  //   availableBalance: {
  //     balanceType: 'available',
  //     name: 'currency',
  //     value: '0',
  //     instanceRef: currency.id,
  //     instanceBaseRef: aliase.id,
}

@Component({
  selector: 'app-add-asset-model',
  templateUrl: './add-asset-model.component.html'
})
export class AddAssetModelComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  @Input() editingAsset: any;
  @Input() walletId: any;
  @Input() cancelClicked: any;
  @Output() onClose = new EventEmitter<any>();
  currencyInstances: any;
  mappedCurrencies: MappedCurrency[] = [];

  formOptions = {
    assetType : [
      {name: 'FIAT Currency', code: 'fiat', type:'currency' },
      {name: 'Crypto Currency', code: 'crypto', type: 'crypto-currency'},
      // {name: 'Bank-Card', code: 'bank-card'},
      // {name: 'Transport-Card', code: 'transport-card'},
      // {name: 'ID-Card', code: 'id-card'},
      // {name: 'QR-Card', code: 'qr-code'},
      // {name: 'Linked-Account', code: 'linked-account'},
      // {name: 'Linked-External-Account', code: 'linked-external-account'},
    ],
    instance: [],
    assetStatus:[
      {name: 'New', code: 'new'},
      {name: 'Active', code: 'available'},
      {name: 'InActive', code: 'pending'},

    ]
  }

  isEditMode = false;

  formSubmited = false;
  formGroup = this.fb.group({
    purpose: [{value:'spot-trading', disabled: true}, [Validators.required]],
    type: ["", [Validators.required]],
    instance: ["", [Validators.required]],
    status: ["", [Validators.required]]
  });
  constructor(
    private fb: FormBuilder,
    public walletsService: WalletsService,
    private helperService: HelperService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.walletsService.getCurrencies().subscribe(currencies => {
        this.currencyInstances = currencies;
        //this.mapCurrencies(this.currencyInstances);
        console.log('currency instances',this.currencyInstances)
      })
    );
  }

  filterByType(option: any) {
    console.log('selectedType',option)
    const filteredOptions = this.currencyInstances.filter(c => c.type == option.code)
    console.log('filteredOptions',filteredOptions)
    this.formOptions.instance = filteredOptions;
  }

  // mapCurrencies(currencies: any) {
  //   for(const currency of currencies) {
  //     const mappedCurrency: MappedCurrency = {
  //       Purpose: 'spot-trading',
  //       Type: currency.type,
  //       Status:
  //     };
  //   }
  // }

  // payload.bodyParams = {
  //   purpose: 'spot-trading',
  //   type: 'currency',
  //   status: 'new',
  //   instanceBase: aliase.id,
  //   availableBalance: {
  //     balanceType: 'available',
  //     name: 'currency',
  //     value: '0',
  //     instanceRef: currency.id,
  //     instanceBaseRef: aliase.id,
  //   },
  // };

  ngOnChanges(changes: SimpleChanges): void {
    this.isEditMode = false;
    //let currentValue = changes['editingAsset'].currentValue;
    let currentCancelStatus = changes['cancelClicked'].currentValue;
    // if (currentValue) {
    //   this.isEditMode = true;
    //   this.formGroup.patchValue(currentValue);
    // }
    if(currentCancelStatus) {
        this.formSubmited = false;
        this.formGroup.reset();
        this.formGroup.markAsPristine();
        this.formGroup.controls['purpose'].setValue('spot-trading');
    }
  }

  onSubmit() {
    this.formSubmited = true;
    let formData = this.formGroup.getRawValue();
    //console.log('formgroup',this.formGroup)

    if (this.formGroup.status == 'VALID') {
      ;
      let httpService;
      let successMessage;

        const currencyId = formData.instance.id
        const aliaseId = getAliaseId(formData.instance);
        //const typeName = formData.instance.type
        const payload = {
          purpose: formData.purpose,
          type: formData.type.type,
          status: formData.status.code,
          instanceBase: aliaseId,
          availableBalance: {
            balanceType: 'available',
            name: formData.type.type,
            value: '0',
            instanceRef: currencyId,
            instanceBaseRef: aliaseId,
          },
        };
        console.log('payload', payload);
        httpService = this.walletsService.createAsset(this.walletId,payload);
        successMessage = messages.success.createAsset;

      httpService.subscribe((res: any) => {
        if(res.isOk) {
          this.helperService.notify('success', successMessage);
          this.onClose.emit('success');
        }
        else {
          this.helperService.notify('error', 'Unable to add asset');
        }
        //this.modalRef.hide();
      }, (error) => {
        this.helperService.notify('error', error);
      });
      this.onClose.emit('close');
      setTimeout(() => {
        this.formSubmited = false;
        this.formGroup.reset();
        this.formGroup.markAsPristine();
        this.formGroup.controls['purpose'].setValue('spot-trading');
      }, 500);
    }
    //console.log('submit', this.formGroup.value)
  }


}

function getAliaseId(instance: any) {
  console.log('get aliase called', instance)
  const curAliaseId = instance.aliases.find((currencyAlias: any) => currencyAlias.default === true)?.id ?? '';
  console.log('cur aliase id', curAliaseId)
  // let currencyAliasId = '';
  //   switch (instance.name) {
  //     case 'Ether':
  //       currencyAliasId = instance.aliases.find((currencyAlias: any) => currencyAlias.name == 'Ether')?.id ?? '';
  //       break;
  //     case 'Bitcoin':
  //       currencyAliasId = instance.aliases.find((currencyAlias: any) => currencyAlias.name == 'BTC')?.id ?? '';
  //       break;
  //     case 'EURO':
  //       currencyAliasId = instance.aliases.find((currencyAlias: any) => currencyAlias.name == 'EUR')?.id ?? '';
  //       break;
  //   }
    return curAliaseId;
}

