import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  public logo!:string;
  public isThemed:boolean = false;
  public static routes: Routes;
  constructor() { }
  public pathToComponent(url:string, routeNestingPosition?:number):string {
    let path = url.substring(url.lastIndexOf("/") + 1, url.length);
    if (routeNestingPosition) {
      if (url.charAt(0) == '/') {
        url = url.substring(1);
      }
      path = url.split('/')[routeNestingPosition];
    }
    return path;
  }

  public extractFunction(items:any, scope:any) {
    items.forEach((e:any) => {
      if (e.hasOwnProperty('functioncall')) {
        const funcStr = e.functioncall;
        let func = scope[funcStr as keyof typeof scope] as any;
        e.command = func.bind(scope);
      }
    });
    return items;
  }

}
