import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';

interface ButtonOptions {
  label: string,
  icon?: string
}

@Component({
  selector: 'app-title-card',
  templateUrl: './title-card.component.html',
  styleUrls: ['./title-card.component.css']
})
export class TitleCardComponent implements OnInit {

  @Input() title='Default Title'
  @Input() hasButton = false;
  @Input() buttonOptions: ButtonOptions = {
    label: 'Button',
    icon: ''
  };

  @Output() openModal = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  open() {
    this.openModal.emit()
  }

}
