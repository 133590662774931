export enum LoadingStatus {
    loading,
    success,
    error
}

export enum Applications {
    b2b_wallets = 'b2b_wallets',
    b2c_wallet = 'b2c_wallet',
    admin_wallet = 'admin_wallet'
}

export enum AccessRights {
    read = 'read',
    full_control = 'full_control',
    no_visibility = 'no_visibility'
}