import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { LoaderService } from 'sepa/framework/src/lib/services/loader.service';
import { wallets } from 'src/app/shared/defaults';
import { HelperService, WalletsService } from 'src/app/shared/services';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
})
export class FeeComponent implements OnInit {
  @Input() walletId: string = '';
  loading: boolean = false;
  pageConfig: any = {};
  tableConfig: any = {};
  tableData: any[] = [];
  totalRecords: number = 1;

  constructor(
    private loaderService: LoaderService,
    private helperService: HelperService,
    private walletsService: WalletsService
  ) {}

  ngOnInit(): void {
    let conf = this.loaderService.clientConfig.fees_list;
    this.pageConfig = conf;
    this.tableConfig = conf.tableConfig;

  }

  getWalletsByCustomerId(customerId, walletType) {
    this.loading = true;
    this.walletsService
      .getWalletsByCustomerId(customerId)
      .subscribe((res: any) => {
        console.log(res);
        let wallet = res.find((i) => i.purpose == walletType);
        if (wallet) {
          this.walletId = wallet.id;
        } else {
          this.helperService.notify('error', 'wallet not found');
        }
        this.getFees();
      });
  }
  fakeFeeData = [
    {
      type: 'fixed',
      feeValue: '0',
      paidBy: 'source',
      feeSharing: '',
      isDynamic: true,
    },
  ];

  getFees() {
    this.loading = true;
    this.walletsService.getFees(this.walletId).subscribe((res: any) => {
      console.log(res);
      this.loading = false;
      this.tableData = res;
    });
  }
  onLazyLoad(e) {
    console.log(e);
  }
  selectAllChange(e) {
    console.log(e);
  }
}
