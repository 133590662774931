import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { apis } from '../defaults';
import { ApiHttpService } from './api-http.service';
import { HelperService } from './helper.service';
import { BackendApiService } from 'sepa/framework/src/public-api';
import { HttpRequestData } from 'sepa/framework/src/lib/models/backend-api.model';
import { MappedAsset } from 'src/app/components/shared/assets/assets.component';

@Injectable({
  providedIn: 'root',
})
export class WalletsService {
  private walletSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  web3EthAmountChanged: BehaviorSubject<any> = new BehaviorSubject<any>(0);

  constructor(
    public http: ApiHttpService,
    private helperService: HelperService,
    private api: BackendApiService
  ) {}

  _assetToEdit: BehaviorSubject<MappedAsset> = new BehaviorSubject(
    {} as MappedAsset
  );
  currentAsset = this._assetToEdit.asObservable();
  setAsset(asset: MappedAsset) {
    this._assetToEdit.next(asset);
  }

  getWalletsByCustomerId(customerId: string) {
    // customerId = "1";
    // const url = apis.wallets.getWalletsByCustomerId(customerId);
    // return this.http.get(url).pipe(map((res: any) => res.value));
    return this.api
      .request(
        this.api.apiEndpoints.wallet.getWalletByCustomerId(customerId),
        null,
        'walletMicroService'
      )
      .pipe(map((res: any) => res.value));
  }

  getWalletsByOrgId(orgId: string) {
    //const url = apis.wallets.getWalletsByOrgId(orgId);
    return this.api
      .request(
        this.api.apiEndpoints.wallet.getWalletByOrgId(orgId),
        null,
        'walletMicroService'
      )
      .pipe(map((res: any) => res.value));
    //return this.http.get(url).pipe(map((res: any) => res.value));
  }

  getWalletById(walletId: any) {
    // walletId = "06d8ec8f-4bd9-41eb-82de-7dc3bf2d374b";
    // const url = apis.wallets.getWalletById(walletId);
    // return this.http.get(url).pipe(map((res: any) => res.value));
    return this.api
      .request(
        this.api.apiEndpoints.adminPanel.wallet.getWalletById(walletId),
        null,
        'walletMicroService'
      )
      .pipe(map((res: any) => res.value));
  }

  getActiveCustomerRequests(data: any = {}) {
    const payload = {
      bodyParams: { ...data },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.getOrders(),
      payload,
      'walletMicroService'
    );
  }

  getAvailableBalances() {
    // const url = apis.wallets.getAvailableBalace();
    // return this.http.get(url);
    const data = new HttpRequestData();
    data.urlAppend = undefined;

    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.getAvailableBalace('crypto-fiat'),
      data,
      'walletMicroService'
    );
  }

  getBulkAmounts() {
    // const url = apis.wallets.getBulkAmounts();
    // return this.http.get(url);
    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.getBulkAmounts(),
      null,
      'walletMicroService'
    );
  }

  confirmBulkWithdraw() {
    const url = apis.wallets.confirmBulkWithdraw();
    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.confirmBulkWithdraw(),
      null,
      'walletMicroService'
    );
  }

  completeWithdraw(data) {
    const url = apis.wallets.completeWithdraw();
    const payload = {
      bodyParams: { ...data },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.completeWithdraw(),
      payload,
      'walletMicroService'
    );
  }

  approveRequest(body: any) {
    // const url = apis.wallets.approve();
    // return this.http.post(url, data);
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.approve(),
      payload,
      'walletMicroService'
    );
  }

  getCurrencies() {
    // const url = apis.wallets.getCurrencies();
    // return this.http.get(url).pipe(map((res: any) => res.value));
    return this.api
      .request(
        this.api.apiEndpoints.wallet.getCurrency(),
        null,
        'walletMicroService'
      )
      .pipe(map((res: any) => res.value));
  }

  getKrakenTxnData() {
    const data = {
      bodyParams: {
        page: 1,
        take: 50,
        skip: 50,
      },
    };
    return this.api
      .request(
        this.api.apiEndpoints.adminPanel.wallet.getKrakenTxnHistory(),
        data,
        'walletMicroService'
      )
      .pipe(map((res: any) => res.value.data));
  }

  getInformations() {
    return this.api
      .request(
        this.api.apiEndpoints.adminPanel.wallet.getInformations(),
        null,
        'walletMicroService'
      )
      .pipe(map((res: any) => res.value));
  }

  updateInfo(body: any) {
    const payload = {
      bodyParams: { ...body },
    };
    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.updateInfo(body.id),
      payload,
      'walletMicroService'
    );
  }

  getSmartCoinWalletAvailableBalance(config?: any, body?: any) {
    const payload = {
      bodyParams: { ...body }
    }
    let tenantShortName = config.tenantFullName;
    const ba = this.api
      .request(
        this.api.apiEndpoints.adminPanel.wallet.getSmartCoinWalletAvailableBalance(),
        payload,
        'walletMicroService'
      )
      .pipe(map((res: any) => res));
    ba.subscribe((res: any) => {
      const web3EthBalance = res.value.data.find(
        (c) => c.wallet_owner == tenantShortName
      )?.matic_amount;
      if (web3EthBalance != null) {
        this.web3EthAmountChanged.next(web3EthBalance.toFixed(4));
      }
    });
    return ba;
  }

  getAssets(walletId: any) {
    return this.getWalletById(walletId).pipe(
      map((wallet: any) => {
        return wallet.assets;
      })
    );
  }
  getOperations(walletId: any) {
    return this.getAssets(walletId).pipe(
      map((assets: any) => {
        return assets.reduce((result, asset: any) => {
          return result.concat(asset.allowedOperations);
        }, []);
      })
    );
  }
  getLimits(walletId: any) {
    return this.getOperations(walletId).pipe(
      map((operations: any) => {
        return operations.reduce((result, operation: any) => {
          return result.concat(operation.limits);
        }, []);
      })
    );
  }
  getFees(walletId: any) {
    return this.getOperations(walletId).pipe(
      map((operations: any) => {
        return operations.reduce((result, operation: any) => {
          return result.concat(operation.fees);
        }, []);
      })
    );
  }

  getAssetById(walletId: any, id: any) {
    return this.getAssets(walletId).pipe(
      map((assets: any) => {
        return assets.find((asset: any) => {
          return (asset.id = id);
        });
      })
    );
  }
  getOperationById(walletId: any, id: any) {
    return this.getOperations(walletId).pipe(
      map((operations: any) => {
        return operations.find((operation: any) => {
          return (operation.id = id);
        });
      })
    );
  }
  getFeeById(walletId: any, id: any) {
    return this.getFees(walletId).pipe(
      map((fees: any) => {
        return fees.find((fee: any) => {
          return (fee.id = id);
        });
      })
    );
  }
  getLimitById(walletId: any, id: any) {
    return this.getOperations(walletId).pipe(
      map((limits: any) => {
        return limits.find((limit: any) => {
          return (limit.id = id);
        });
      })
    );
  }

  createWalletAsset(assetObj) {
    const body = assetObj;
    const url = apis.wallets.asset.create;
    return this.http.post(url, body);
  }

  createWalletAssetOperation(walletId, assetId, operationObj) {
    const body = operationObj;
    const url = apis.wallets.asset.createAssetOperation(walletId, assetId);
    return this.http.post(url, body);
  }

  createAsset(walletId, assetObj) {
    const body = assetObj;
    const url = apis.wallets.asset.createAsset(walletId);
    return this.http.post(url, body);
  }

  updateAssetById(assetObj) {
    const body = assetObj;
    const url = apis.wallets.asset.updateAssetById;
    return this.http.put(url, body);
  }
  updateWalletAssetById(walletId, assetId, assetObj) {
    //const body = assetObj;
    const data = {
      bodyParams: assetObj,
    };
    // const url = apis.wallets.asset.updateWalletAssetById(walletId, assetId);
    //return this.http.put(url, body);
    return this.api.request(
      this.api.apiEndpoints.adminPanel.wallet.updateAssetById(
        walletId,
        assetId
      ),
      data,
      'walletMicroService'
    );
    //.pipe(map((res: any) => res.value));
  }
}
