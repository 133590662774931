
export const theme1  = {
  background:'',
  logo:'assets/images/main-logo1.png'
}

export const theme2  = {
  background:'assets/images/bg-login.png',
  logo:'assets/images/main-logo.png'
}

export const chartColors1 : any = {
  blue : {
    hash: '#1171B8',
    rgb: '17, 113, 184',
    backgroundColor: 'rgba(17, 113, 184,0.8)',
    borderColor: 'rgba(17, 113, 184,1)'
  },
  voilet : {
    hash: '#5C66BB',
    rgb: '92, 102, 187',
    backgroundColor: 'rgba(92, 102, 187,0.8)',
    borderColor: 'rgba(92, 102, 187,1)'
  },
  green : {
    hash: '#88C057',
    rgb: '15, 90, 22',
    backgroundColor: 'rgba(15, 90, 22,0.8)',
    borderColor: 'rgba(15, 90, 22,1)'
  },
  denim : {
    hash: '#1171B8',
    rgb: '17, 113, 184',
    backgroundColor: 'rgba(17, 113, 184,0.8)',
    borderColor: 'rgba(17, 113, 184,1)'
  },
  blueviolet : {
    hash: '#5C66BB',
    rgb: '92, 102, 187',
    backgroundColor: 'rgba(92, 102, 187,0.8)',
    borderColor: 'rgba(92, 102, 187,1)'
  },
  darkfern : {
    hash: '#0F5A16',
    rgb: '15, 90, 22',
    backgroundColor: 'rgba(15, 90, 22,0.8)',
    borderColor: 'rgba(15, 90, 22,1)'
  },
  madison : {
    hash: '#0D2664',
    rgb: '13, 38, 100',
    backgroundColor: 'rgba(13, 38, 100,0.8)',
    borderColor: 'rgba(13, 38, 100,1)'
  },
  buttercup : {
    hash: '#F5A316',
    rgb: '245, 163, 22',
    backgroundColor: 'rgba(245, 163, 22,0.8)',
    borderColor: 'rgba(245, 163, 22,1)'
  },
  silversand : {
    hash: '#C6C8CB',
    rgb: '245, 163, 22',
    backgroundColor: 'rgba(245, 163, 22,0.8)',
    borderColor: 'rgba(245, 163, 22,1)'
  },
  tropicalblue : {
    hash: '#BDDFF8',
    rgb: '189, 223, 248',
    backgroundColor: 'rgba(189, 223, 248,0.8)',
    borderColor: 'rgba(189, 223, 248,1)'
  },
  mantis : {
    hash: '#88C057',
    rgb: '136, 192, 87',
    backgroundColor: 'rgba(136, 192, 87,0.8)',
    borderColor: 'rgba(136, 192, 87,1)'
  },
  color1 : {
      hash: '#C3922E',
      rgb: '0, 102, 204',
      backgroundColor: 'rgba(195, 146, 46,0.8)',
      borderColor: 'rgba(195, 146, 46,1)'
  },
  color2 : {
      hash: '#19D792',
      rgb: '51, 102, 204',
      backgroundColor: 'rgba(51, 102, 204)',
      borderColor: 'rgba(15, 90, 22,1)'
  },
  color3 : {
      hash: '#C3922E',
      rgb: '195, 146, 46',
      backgroundColor: 'rgba(195, 146, 46,0.8)',
      borderColor: 'rgba(195, 146, 46,1)'
  },
  color4 : {
      hash: '#19D792',
      rgb: '25, 215, 146',
      backgroundColor: 'rgba(25, 215, 146,0.8)',
      borderColor: 'rgba(25, 215, 146,1)'
  },
}

export const chartColors2 : any = {
  blue : {
    hash: '#1171B8',
    rgb: '17, 113, 184',
    backgroundColor: 'rgba(17, 113, 184,0.8)',
    borderColor: 'rgba(17, 113, 184,1)'
  },
  voilet : {
    hash: '#5C66BB',
    rgb: '92, 102, 187',
    backgroundColor: 'rgba(92, 102, 187,0.8)',
    borderColor: 'rgba(92, 102, 187,1)'
  },
  green : {
    hash: '#88C057',
    rgb: '15, 90, 22',
    backgroundColor: 'rgba(15, 90, 22,0.8)',
    borderColor: 'rgba(15, 90, 22,1)'
  },
  denim : {
    hash: '#1171B8',
    rgb: '17, 113, 184',
    backgroundColor: 'rgba(17, 113, 184,0.8)',
    borderColor: 'rgba(17, 113, 184,1)'
  },
  blueviolet : {
    hash: '#5C66BB',
    rgb: '92, 102, 187',
    backgroundColor: 'rgba(92, 102, 187,0.8)',
    borderColor: 'rgba(92, 102, 187,1)'
  },
  darkfern : {
    hash: '#0F5A16',
    rgb: '15, 90, 22',
    backgroundColor: 'rgba(15, 90, 22,0.8)',
    borderColor: 'rgba(15, 90, 22,1)'
  },
  madison : {
    hash: '#0D2664',
    rgb: '13, 38, 100',
    backgroundColor: 'rgba(13, 38, 100,0.8)',
    borderColor: 'rgba(13, 38, 100,1)'
  },
  buttercup : {
    hash: '#F5A316',
    rgb: '245, 163, 22',
    backgroundColor: 'rgba(245, 163, 22,0.8)',
    borderColor: 'rgba(245, 163, 22,1)'
  },
  silversand : {
    hash: '#C6C8CB',
    rgb: '245, 163, 22',
    backgroundColor: 'rgba(245, 163, 22,0.8)',
    borderColor: 'rgba(245, 163, 22,1)'
  },
  tropicalblue : {
    hash: '#BDDFF8',
    rgb: '189, 223, 248',
    backgroundColor: 'rgba(189, 223, 248,0.8)',
    borderColor: 'rgba(189, 223, 248,1)'
  },
  mantis : {
    hash: '#88C057',
    rgb: '136, 192, 87',
    backgroundColor: 'rgba(136, 192, 87,0.8)',
    borderColor: 'rgba(136, 192, 87,1)'
  },
  color1 : {
      hash: '#C3922E',
      rgb: '195, 146, 46',
      backgroundColor: 'rgba(195, 146, 46,0.8)',
      borderColor: 'rgba(195, 146, 46,1)'
  },
  color2 : {
      hash: '#19D792',
      rgb: '25, 215, 146',
      backgroundColor: 'rgba(25, 215, 146,0.8)',
      borderColor: 'rgba(25, 215, 146,1)'
  },
  color3 : {
      hash: '#C3922E',
      rgb: '195, 146, 46',
      backgroundColor: 'rgba(195, 146, 46,0.8)',
      borderColor: 'rgba(195, 146, 46,1)'
  },
  color4 : {
      hash: '#19D792',
      rgb: '25, 215, 146',
      backgroundColor: 'rgba(25, 215, 146,0.8)',
      borderColor: 'rgba(25, 215, 146,1)'
  },
}

