import { Injectable } from "@angular/core";
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { STATIC_DI } from "../staticdi";

@Injectable()
export class InstrumentationService {
  constructor() {}

  initSentry() {
    const environment = STATIC_DI.ENVIRONMENT;
    Sentry.init({
      environment: environment.production ? "Production" : "Development",
      release: `${environment.appName}@${environment.appVersion}`,
      dsn: environment.sentryDsn,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
          tracingOrigins: ["localhost", environment.appUrl, /^\//],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: environment.production ? 0.2 : 1,
    });
  }

  configureSentry(
    customerData?: any,
    tags?: { key: string; value: string }[],
    extras?: { key: string; value: string }[]
  ) {
    Sentry.configureScope((scope) => {
      // set user info
      if (customerData) {
        scope.setUser({
          customerId: customerData.id,
          email: customerData.person?.email,
        });
      }

      // set tags
      if (tags?.length) {
        for (const tag of tags) {
          scope.setTag(tag.key, tag.value);
        }
      }

      // set extra info
      if (extras?.length) {
        for (const extra of extras) {
          scope.setExtra(extra.key, extra.value);
        }
      }
    });
  }
}
