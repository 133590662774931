import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { BackendApiService } from "../services/backend-api.service";

@Injectable()
export class RetryHttpInterceptor implements HttpInterceptor {
  constructor(private api: BackendApiService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if ((error.status === 401 || error.status === 400) && !this.isLoginRequest(request)) {
          
          const userToken = localStorage.getItem('userToken') ?? '';
          const apiManagerToken = localStorage.getItem('apimToken') ?? '';

          request = this.addMissingHeaders(request, userToken, apiManagerToken);

          // Retry the request with the updated headers
          return next.handle(request);
        }
        return throwError(error);
      })
    );
  }

  // Helper function to check if the request is a login request
  private isLoginRequest(request: HttpRequest<any>): boolean {
    return request.url.includes('/oauth2/token');
  }

  private addMissingHeaders(request: HttpRequest<any>, userToken: string, apiManagerToken: string): HttpRequest<any> {
    const headers: { [key: string]: string } = {};

    if (!request.headers.has('Authorization') && apiManagerToken) {
      headers['Authorization'] = `${apiManagerToken}`;
    }

    if (!request.headers.has('token') && userToken) {
      headers['token'] = userToken;
    }

    return request.clone({
      setHeaders: headers
    });
  }
}
