import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class KrakenService {

  constructor(
    public http : ApiHttpService,
  ) { }

  pagesList = [
    {title : "Ledger",       slug:"ledgers" },
    {title : "Orders-Open",       slug:"openOrders" },
    {title : "Orders-Closed",        slug:"closedOrders" },
    {title : "Trades",        slug:"trades" },

  ]

  ledgers = [];
  getLedgers(ofs , start, end, type='all'){
    const url = apis.kraken.ledgers;
    return this.http.post(url, {"ofs":ofs, "start":start, "end":end ,"type" : type }).pipe(
      tap((res:any)=>this.ledgers = res.data)
    );
  }

  trades = [];
  getTrades(ofs , start, end, type='all'){
    const url = apis.kraken.trades;
    return this.http.post(url, {"ofs":ofs, "start":start, "end":end }).pipe(
      tap((res:any)=>this.trades = res.data)
    );
  }

}
