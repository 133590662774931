import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { BackendApiService } from "./backend-api.service";
import { HttpRequestData } from "../models/backend-api.model";
import { finalize, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { ThemingService } from "./theming.service";
import { UtilsService } from "./utils.service";
import { STATIC_DI } from "../staticdi";
import { LoaderService } from "./loader.service";
import { ToastNotificationService } from "./toast-notification.service";
import { TwoFaData } from "../models/auth.model";
import { Country } from "../models/country.model";
import { Store } from "./store.service";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  authState = new BehaviorSubject(false);
  userId!: string;
  initialLoadAuthCheck = true;
  apimToken = "";
  private userLoggedIn = new BehaviorSubject<boolean>(this.isLoggedIn());
  userLoggedIn$ = this.userLoggedIn.asObservable();
  createAccountData: any = {
    customerType: "Person",
    customerName: "",
    customerStatus: "new",
    categorization: "category1",
    person: {
      firstName: {
        name: "",
        order: 1,
        abbreviation: "",
        language: "",
        native: "",
        suffix: "",
        prefix: "",
      },
      lastName: {
        name: "",
        order: 2,
        abbreviation: "",
        language: "",
        native: "",
        suffix: "",
        prefix: "",
      },
      fullName: "",
      gender: "Male",
      dateOfBirth: new Date("01 January 1900 00:00 UTC").toISOString(),
      placeOfBirth: "",
      nationality: "",
      pincode: "",
      password: "",
      email: "",
      telephoneNumber: [
        {
          phoneType: "mobile",
          operator: "",
          encoding: "numbers",
          country: "",
          number: "",
          purpose: "",
        },
      ],
    },
  };

  createBusinessAccount: any = {
    isTradingNameExist: true,
    website: "",
    businessSector: "",
    rangeOfServicesOrGoods: "",
    taxRegistrationNumber: 0,
  };

  countryListChanged = new BehaviorSubject<Country[]>([]);
  private countryList: Country[] = [];

  user?: any;
  customer?: any;
  loginSuccess = new Subject<boolean>();

  isUsernameVerified: boolean = false;
  isSmsVerified: boolean = false;
  isSmsAdded: boolean = false;
  number: string = "";
  funding2faVerified = false;
  twoFaData?: TwoFaData;

  constructor(
    private store: Store,
    private api: BackendApiService,
    public themeService: ThemingService,
    private router: Router,
    private loaderService: LoaderService,
    public utils: UtilsService,
    private http: HttpClient,
    private toastNotificationService: ToastNotificationService
  ) {}

  initValues() {
    // Customer type for B2B will be 'Employee' or 'Person'
    this.setTheme();
    this.createAccountData.customerType =
      this.store.application === "b2b_wallet" ? "Employee" : "Person";
    this.getCountries();
    this.getActiveSessions();
    if (!this.store.userId) this.setPublicIpAddress();
    if (this.store.customerId) {
      this.get2faData().subscribe({
        next: (response) => (this.twoFaData = response),
      });
    }
  }

  getPublicIpAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  setPublicIpAddress() {
    this.getPublicIpAddress().subscribe((res: any) => {
      this.store.ipAddress = res.ip;
      this.api.setOptions();
      this.api.setApiEndpoints();
    });
  }

  getActiveSessions() {
    if (this.store.customerId) {
      this.api
        .request(
          this.api.apiEndpoints.activeSessions.getActiveSessions(
            this.store.customerId
          ),
          null,
          "customerMicroService"
        )
        .subscribe({
          next: (response) => {
            this.store.activeSessions = response ?? [];
          },
        });
    }
  }

  private setTheme() {
    if (!this.utils.isThemed) {
      const clientTheme = this.loaderService.clientConfig.theme;
      this.utils.logo = clientTheme.logo;
      this.themeService.switchTheme(clientTheme.theme);
      this.themeService.changeFevicon(clientTheme.favicon);
    }
    this.utils.isThemed = true;
  }

  onLogin(email: string, password: string) {
    if (!this.store.ipAddress) {
      this.getPublicIpAddress().subscribe({
        next: (response: any) => {
          this.store.ipAddress = response?.ip;
          this.api.setOptions();
          this.api.setApiEndpoints();
          this.login(email, password);
        },
        error: (error) =>
          this.toastNotificationService.setNotification({
            severity: "error",
            detail: "Something went wrong. Please try again later.",
          }),
      });
    } else {
      this.login(email, password);
    }
  }

  login(email: string, password: string) {
    const data = {
      bodyParams: {
        credentials: [
          {
            credentialType: "username",
            value: email.trim(),
            code: password.trim(),
            communicationChannel: {
              name: "string",
              type:
                this.store.application === "b2b_wallet"
                  ? "web_business"
                  : "web_personal",
              addressUrl: "",
            },
          },
        ],
      },
    };

    this.api
      .request(this.api.apiEndpoints.oauth.login(), data, "oauth")
      .subscribe({
        next: (response) => {
          const token = response.token;
          this.store.userToken = token;
          if (token) {
            localStorage.setItem("userToken", token);
            localStorage.setItem("isUserLoggedIn", JSON.stringify(true));
            this.userLoggedIn.next(true);
            this.api.setOptions();
            this.api
              .request(this.api.apiEndpoints.oauth.session(token), {}, "oauth")
              .subscribe({
                next: (sessionResponse) => {
                  const userInfo = sessionResponse.userInfo;
                  this.user = userInfo;
                  this.store.personId = userInfo.personId;
                  this.store.userId = userInfo.userId;
                  this.store.tenantId = userInfo.tenantId;
                  this.store.credentials = userInfo.credentials;
                  this.store.orgId = userInfo.organizationId;
                  this.store.customerData.next(userInfo);
                  localStorage.setItem(
                    "customerData",
                    JSON.stringify(userInfo)
                  );
                  localStorage.setItem("customerId", userInfo.customerId);
                  localStorage.setItem("userId", userInfo.userId);
                  localStorage.setItem("personId", userInfo.personId);
                  localStorage.setItem("tenantId", userInfo.tenantId);
                  localStorage.setItem("orgId", userInfo.organizationId);
                  localStorage.setItem("tokenExpiry", userInfo.exp);
                  this.userLoggedIn.next(true);
                  this.api
                    .request(
                      this.api.apiEndpoints.createAccount.getCustomerById(
                        userInfo.customerId
                      ),
                      null,
                      "customerMicroService"
                    )
                    .subscribe((customer) => {
                      this.customer = customer;
                      this.store.customerData.next(customer);
                      localStorage.setItem(
                        "orgId",
                        customer.person?.organizationId
                      );
                      this.store.orgId = customer.person?.organizationId;
                      // Check for account verification
                      this.checkAccountVerification();
                    });

                  this.checkAccountVerification();
                },
                error: (err) => {
                  if (err.error?.description) {
                    this.toastNotificationService.setNotification({
                      severity: "error",
                      detail: err.error?.description,
                    });
                  }
                },
              });
          } else {
            console.error("Failed to retrieve token from login response");
            this.toastNotificationService.setNotification({
              severity: "error",
              detail: "Failed to retrieve token from login response",
            });
          }
        },
        error: (err) => {
          console.error("Error from login:", err);
          if (err.error?.description) {
            this.toastNotificationService.setNotification({
              severity: "error",
              detail: err.error?.description,
            });
          }
        },
      });
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem("userToken");
  }

  persistUser() {
    localStorage.setItem("user", JSON.stringify(this.user));
  }

  checkAccountVerification() {
    if (this.user && this.customer) {
      const email = this.customer.person?.email;
      const data = new HttpRequestData();
      data.bodyParams = {
        credential: {
          credentialType: "username",
          value: email,
          code: "",
          communicationChannel: {
            name: "string",
            type: "email",
            addressUrl: "string",
          },
        },
      };
      this.api
        .request(
          this.api.apiEndpoints.createAccount.getCredentials,
          data,
          "customerMicroService"
        )
        .subscribe(
          (response: any) => {
            if (response.code == "404") {
              this.toastNotificationService.setNotification({
                severity: "error",
                detail: response.message,
              });
            } else {
              this.createAccountData.person.email = email;
              let phoneAdded = response.credentials.find(
                (ele: any) => ele.type == "sms"
              );
              let verificationStep = "emailCode";

              if (
                response.credentials.find(
                  (ele: any) =>
                    ele.type == "username" && ele.status == "verified"
                ) &&
                response.credentials.find(
                  (ele: any) => ele.type == "sms" && ele.status == "verified"
                )
              ) {
                verificationStep = "allVerified";
              }
              if (
                response.credentials.find(
                  (ele: any) =>
                    ele.type == "one_time_password" &&
                    ele.status == "verified" &&
                    !phoneAdded
                )
              ) {
                verificationStep = "oneTimePassword";
              } else if (
                response.credentials.find(
                  (ele: any) =>
                    ele.type == "username" && ele.status != "verified"
                )
              ) {
                verificationStep = "emailCode";
              } else if (
                response.credentials.find(
                  (ele: any) =>
                    ele.type == "username" && ele.status == "verified"
                ) &&
                !phoneAdded
              ) {
                verificationStep = "phoneInput";
              } else if (
                response.credentials.find(
                  (ele: any) => ele.type == "sms" && ele.status != "verified"
                )
              ) {
                verificationStep = "phoneCode";
              }

              switch (verificationStep) {
                case "allVerified":
                  this.isSmsVerified = true;
                  this.isSmsAdded = true;

                  if (this.user.isOneTimePasswordLogin) {
                    this.router.navigate(["auth/reset-password"]);
                  } else {
                    this.loginSuccess.next(true);
                  }
                  break;
                case "oneTimePassword":
                  // Get Phone number from customer data
                  if (this.customer?.person?.telephoneNumbers?.length) {
                    const countryCode =
                      this.customer?.person?.telephoneNumbers[0].country;
                    const phoneNumber =
                      this.customer?.person?.telephoneNumbers[0].number;
                    this.sendPhoneVerificationCode(
                      countryCode,
                      phoneNumber
                    ).subscribe({
                      next: (response) => {
                        this.isUsernameVerified = true;
                        this.isSmsAdded = true;
                        this.number = countryCode + phoneNumber;
                        this.isSmsVerified = false;
                        this.router.navigate(["auth/activate-account"]);
                        this.toastNotificationService.setNotification({
                          severity: "info",
                          detail:
                            "Please verify your phone number and change your one time password.",
                        });
                      },
                      error: (err) => {
                        this.toastNotificationService.setNotification({
                          severity: "error",
                          detail:
                            "Your phone number is already linked With diffrent account. Please contact your admin.",
                        });
                      },
                    });
                  } else
                    this.toastNotificationService.setNotification({
                      severity: "error",
                      detail:
                        "Your phone number is not registerd. Please contact your admin.",
                    });
                  break;
                case "phoneCode":
                  const ele = response.credentials.find(
                    (ele: any) => ele.type == "sms" && ele.status != "verified"
                  );
                  this.reSendCode("sms", ele.value).subscribe({
                    next: (response) => {
                      this.isUsernameVerified = true;
                      this.isSmsAdded = true;
                      this.number = ele.value;
                      this.isSmsVerified = false;
                      this.router.navigate(["auth/activate-account"]);
                      this.toastNotificationService.setNotification({
                        severity: "info",
                        detail:
                          "Please Verify Your Contact Number And Complete the Registration flow.",
                      });
                    },
                    error: (err) => {
                      if (err.error.description) {
                        this.toastNotificationService.setNotification({
                          severity: "error",
                          detail: err.error.description,
                        });
                      }
                    },
                  });
                  break;
                case "phoneInput":
                  this.isUsernameVerified = true;
                  this.isSmsAdded = false;
                  this.isSmsVerified = false;
                  this.router.navigate(["auth/activate-account"]);
                  this.toastNotificationService.setNotification({
                    severity: "info",
                    detail:
                      "Please Provide Your Contact Number And Complete the Registration flow.",
                  });
                  break;
                default:
                  this.reSendCode("username", "").subscribe({
                    next: (response) => {
                      this.isUsernameVerified = false;
                      this.router.navigate(["auth/activate-account"]);
                      this.toastNotificationService.setNotification({
                        severity: "info",
                        detail:
                          "Please Verify Your Account And Complete the Registration flow.",
                      });
                    },
                    error: (err) => {
                      if (err.error.description) {
                        this.toastNotificationService.setNotification({
                          severity: "error",
                          detail: err.error.description,
                        });
                      }
                    },
                  });
                  break;
              }
            }
          },
          (err: any) => {
            if (err.error.description) {
              this.toastNotificationService.setNotification({
                severity: "error",
                detail: err.error.description,
              });
            }
          }
        );
    }
  }

  compareDate(currentDate: Date, defaultDate: Date) {
    if (
      currentDate.getDate() == defaultDate.getDate() &&
      currentDate.getMonth() == defaultDate.getMonth() &&
      currentDate.getFullYear() == defaultDate.getFullYear()
    ) {
      return true;
    } else {
      return false;
    }
  }

  reSendCode(type: string, number: any) {
    const email = this.customer?.person?.email;

    let obj = {
      credential: {
        credentialType: type,
        value: type == "username" ? email : number,
        code: "",
        communicationChannel: {
          name: "string",
          type: "email",
          addressUrl: "string",
        },
      },
    };
    const data = new HttpRequestData();
    data.bodyParams = obj;

    return this.api.request(
      this.api.apiEndpoints.createAccount.reSendCode,
      data,
      "customerMicroService"
    );
  }

  sendPhoneVerificationCode(countryCode: string, phoneNumber: string) {
    let obj = {
      customerId: this.store.customerId,
      credentials: [
        {
          credentialType: "sms",
          value: JSON.stringify({
            code: countryCode,
            number: phoneNumber,
          }),
          code: "",
          communicationChannel: {
            name: "string",
            type: "phone",
            addressUrl: "string",
          },
        },
      ],
    };
    const data = new HttpRequestData();
    data.bodyParams = obj;

    return this.api.request(
      this.api.apiEndpoints.createAccount.sendCode,
      data,
      "customerMicroService"
    );
  }

  isAuthenticated(): Observable<any> {
    return new Observable((observer) => {
      if (this.authState.value) {
        observer.next(true);
      } else {
        observer.next(false);
      }
    });
  }

  isAuthenticatedOnServer(): Promise<boolean> {
    // Todo check if user is logged in on server
    if (this.store.userId) {
      if (this.initialLoadAuthCheck) {
        this.authState.next(true);
        this.initialLoadAuthCheck = false;
        return Promise.resolve(true);
      } else {
        return Promise.resolve(true);
      }
    } else {
      return Promise.resolve(false);
    }

    // return this.checkIfToken().then((hasToken) => {
    //   if (hasToken && this.initialLoadAuthCheck) {
    //     return new Promise((resolve) => {
    //       this.api.request(this.api.apiEndpoints.accounts.getAccount).subscribe(
    //         (response) => {
    //           if (!response.success) {
    //             resolve(false);
    //           } else {
    //             const account = response.body.data.account;
    //             this.store.userId
    //             resolve(true);
    //           }
    //         },
    //         (error) => {
    //           this.authState.next(false);
    //           resolve(false);
    //         }
    //       );
    //     });
    //   } else if (hasToken && !this.initialLoadAuthCheck) {
    //     return Promise.resolve(true);
    //   } else if (!hasToken) {
    //     return Promise.resolve(false);
    //   } else {
    //     return false;
    //   }
    // });
  }

  onLogout(clearSession = true) {
    if (clearSession) {
      // Find the current session based on IP address or another unique identifier
      const currentSession = this.store.activeSessions.find(
        (session) => session.sessionLog?.ipAddress === this.store.ipAddress
      );

      if (currentSession) {
        this.api
          .request(
            this.api.apiEndpoints.activeSessions.logoutActiveSession(
              this.store.customerId,
              currentSession.id
            ),
            null,
            "customerMicroService"
          )
          .subscribe({
            next: (response) => this.logout(),
            error: (err) =>
              this.toastNotificationService.setNotification({
                severity: "error",
                detail: "Something went wrong. Please try again later.",
              }),
          });
        return;
      }
    }
    this.logout();
  }

  logout(): void {
    const isLoggedIn = localStorage.getItem("isUserLoggedIn") === "true";

    if (!isLoggedIn) {
      this.clearLocalStorage();
      return;
    }

    this.api
      .request(this.api.apiEndpoints.oauth.logout(), null, "oauth")
      .pipe(finalize(() => this.clearLocalStorage()))
      .subscribe({
        next: () => {
          // Handle successful logout if needed
        },
        error: (err) => {
          this.toastNotificationService.setNotification({
            severity: "error",
            detail:
              err.error?.description ||
              "Something went wrong. Please try again later.",
          });
        },
      });
  }

  clearLocalStorage(): void {
    const apimToken = localStorage.getItem('apimToken') ?? '';
    localStorage.clear();
    localStorage.setItem('apimToken', apimToken);
    localStorage.setItem('isUserLoggedIn', JSON.stringify(false));
    this.userLoggedIn.next(false);
  
    // Check if the current route is already '/auth/login' to avoid looping
    if (this.router.url !== '/auth/login') {
      this.router.navigate(['/auth/login']).then(() => {
        window.location.reload();
      });
    }
  }
  

  handleUnauthorizedResponse(statusCode: number): void {
    if (statusCode === 400 || statusCode === 401 || statusCode === 403) {
      console.log(
        "Unauthorized or forbidden response received. Clearing local storage and redirecting..."
      );
      this.clearLocalStorage();
    }
  }

  getCountries() {
    const data = new HttpRequestData();
    data.bodyParams = {
      tenantId: "7",
    };
    this.api
      .request(
        this.api.apiEndpoints.createAccount.getCountryList,
        data,
        "countryList"
      )
      .subscribe({
        next: (response) => {
          this.countryList = response.map((country: any) => {
            return {
              name: country.countryName,
              code: country.three_digit_iso_code,
              nationality: country.nationality,
              phoneCode: country.phone_code,
              phoneCodeStr:
                "+" + country.phone_code + " " + country.three_digit_iso_code,
            };
          });

          this.store.countryList = this.countryList;
          this.countryListChanged.next(this.countryList.slice());
        },
      });
  }

  getCountryList() {
    return this.countryList.slice();
  }

  get2faData() {
    // since there is no get 2fa ep, we use the update
    const data = new HttpRequestData();
    data.bodyParams = {
      customerId: this.store.customerId,
    };
    return this.api.request(
      this.api.apiEndpoints.settings.update2FA(this.store.customerId),
      data,
      "customerMicroService"
    );
  }

  getCustomerData() {
    if (this.store.customerId) {
      this.api
        .request(
          this.api.apiEndpoints.createAccount.getCustomerById(
            this.store.customerId
          ),
          null,
          "customerMicroService"
        )
        .subscribe({
          next: (customer) => {
            this.customer = customer;
            this.store.customerData.next(customer);
          },
        });
    }
  }

  createApimUser(payload: any) {
    const data = new HttpRequestData();
    data.bodyParams = payload;
    return this.api.request(
      this.api.apiEndpoints.identity.createApimUser(),
      data,
      "customerMicroService"
    );
  }
}
