import { Component, OnInit } from '@angular/core';
import { CommonService } from './services/common.service';
import { IdleTimeoutService } from './services/idle-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'wallet-admin';
  constructor(
    private commonService: CommonService,
    private idleTimeoutService: IdleTimeoutService
  ) {}

  ngOnInit(): void {
    this.idleTimeoutService.initializeIdleTimeout();
  }
}
