import { AuthComponent } from "./components/auth/auth.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthCheckGuard, AuthGuard } from "./guards/auth.guard";
import { AccessControlGuard } from "./guards/access-control.guard";

const routes: Routes = [
  {
    path: "auth",
    component: AuthComponent,
    loadChildren: () =>
      import("./components/auth/auth.module").then((m) => m.AuthModule),
    canActivate: [AuthCheckGuard],
    data: {
      successRedirect: "shared",
    },
  },
  {
    path: "shared",
    loadChildren: () =>
      import("./components/shared/shared.module").then((m) => m.SharedModule),
    canActivate: [AuthGuard],
    canActivateChild: [AccessControlGuard],
    data: {
      failRedirect: "auth",
    },
  },
  {
    path: "**",
    redirectTo: "shared",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
