<app-title-card [title]="'Operations'">
</app-title-card>

<div class="">
  <p-table
  #operations1
  [value]="mappedOperations"
  [globalFilterFields]="['asset', 'operationType']"
  [resizableColumns]="false"
  [autoLayout]="true"
  [loading]="loading"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          #textInput
          type="text"
          (input)="operations1.filterGlobal(textInput.value, 'contains')"
          placeholder="Search Operations"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>Asset</th>
      <th>Operation</th>
      <th>Destination</th>
      <!-- <th>Created At</th> -->
      <th>Last Updated</th>
      <!-- <th>Edit</th> -->
      <!-- <th>Fees</th>
      <th>Limits</th> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowOperation>
    <tr>
      <td>{{rowOperation.asset}}</td>
      <td>{{rowOperation.operationType}}</td>
      <td>{{rowOperation.operationDestination}}</td>
      <!-- <td>{{rowOperation.createdAt | date: 'MM/dd/yyyy'}}</td> -->
      <td>{{rowOperation.updatedAt | date: 'MM/dd/yyyy'}}</td>
      <!-- <td><p-button
        label="Edit"
        (click)="onEditClick(rowOperation)"
      ></p-button>
      </td> -->
      <!-- <td><p-button
        label="Fees"
        (click)="onFeesClick(rowOperation)"
      ></p-button>
      </td>
      <td><p-button
        label="Limits"
        (click)="onLimitsClick(rowOperation)"
      ></p-button>
      </td> -->
    </tr>
  </ng-template>
</p-table>
</div>

<!-- <sepa-table
    [loading]="loading"
    [pageConfig]="pageConfig"
    [tableConfig]="tableConfig"
    [tableData]="tableData"
    [totalRecords]="totalRecords"

    (onLazyLoad)="onLazyLoad"
    (selectAllChange)="selectAllChange"
    (onAction)="onActionClick($event)"
></sepa-table> -->

<!-- <p-dialog [(visible)]="isAddOperationModelOpen"
  [breakpoints]="{ '640px': '60vw' }"
  [style]="{ width: '25vw', height: '65vh' }"
  [blockScroll]="true">
  <ng-template pTemplate="header">
      <h2>Add new operation</h2>
  </ng-template>
  <app-add-operation-model [walletId]="walletId" (onClose)="onAddOperationFinish($event)"></app-add-operation-model>
  <!-- <ng-template pTemplate="footer">
      <div class="flex justify-content-end flex-wrap">
          <button pButton type="button" label="Create"></button>
      </div>
  </ng-template> -->
<!-- </p-dialog>  -->
<!--
<p-dialog [(visible)]="isEditOperationModelOpen"
  [breakpoints]="{ '640px': '60vw' }"
  [style]="{ width: '25vw', height: '65vh' }"
  [blockScroll]="true">
  <ng-template pTemplate="header">
      <h2>Edit operation</h2>
  </ng-template>
  <app-edit-operation-model [walletId]="walletId" (onClose)="onEditOperationFinish($event)"></app-edit-operation-model>
  <!-- <ng-template pTemplate="footer">
      <div class="flex justify-content-end flex-wrap">
          <button pButton type="button" label="Create"></button>
      </div>
  </ng-template> -->
<!-- </p-dialog> -->
