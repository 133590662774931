<div>
  <h2 class="m-0">Log in</h2>
  <form [formGroup]="myForm" (submit)="login()" class="flex flex-column">
    <input
      pInputText
      type="text"
      placeholder="User Name"
      formControlName="email"
      class="my-4"
    />
    <span class="p-input-icon-right w-12">
      <i
        [class]="showPassword ? 'pi pi-eye' : 'pi pi-eye-slash'"
        (click)="showPassword = !showPassword"
      ></i>
      <input
        pPassword
        [type]="showPassword ? 'text' : 'password'"
        placeholder="Password"
        formControlName="password"
        class="w-12"
      />
    </span>
    <button
      pButton
      type="submit"
      class="btn-secondary mt-4 mb-2 w-12"
      label="Login"
    ></button>
  </form>
</div>
