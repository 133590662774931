import { Injectable } from '@angular/core';
import { apis, appGlobals } from 'src/app/shared/defaults';
import { ApiHttpService } from './api-http.service';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(
    private http : ApiHttpService,
    private helperService : HelperService
  ) {
    this.setGrobalCountries();
  }

  setGrobalCountries(){
    const url = apis.others.getCountries;
    const body = {
      "countryData":  ["nationality", "country_name","phone_code"]
    };
    return this.http.post(url, body).subscribe((res:any)=>{
      appGlobals.countries = res.data;
    },(error)=>{
        this.helperService.notify('error', error);
    });
  }
}
