import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(public http : ApiHttpService,) { }

  allEmailTemplates = [];
    getAllEmailTemplates(pageObject){
        const url = apis.email.getAllEmailTemplates;
        return this.http.post(url,pageObject).pipe(
            tap((res:any)=>this.allEmailTemplates = res.data)
        );
    }

    getEmailTemplateById(templateId){
        const body = {templateId};
        const url = apis.email.getEmailTemplateById;
        return this.http.post(url, body);
    }

    create(templateObj){
        const body = templateObj;
        const url = apis.email.create;
        return this.http.post(url, body);
    }

    updateEmailTemplateById(emailObj){
        const body = emailObj;
        const url = apis.email.updateEmailTemplateById;
        return this.http.put(url, body);
    }

    deleteEmailTemplateById(templateId){
        const url = apis.email.deleteEmailTemplateById;
        return this.http.delete(url, {templateId : templateId});
    }
}
