import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Routes } from '@angular/router';
import { SepaFrameworkModule } from 'sepa/framework/src/lib/sepaframework.module';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import { ComponentsModule } from './shared/module/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmationService } from 'primeng/api';
import { DividerModule } from 'primeng/divider';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ActiveCustomerRequestComponent } from './components/active-customer-requests/active-customer-request.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GroupsComponent } from './components/groups/groups.component';
import { DashboardAdminComponent } from './components/dashboard-admin/dashboard-admin.component';
import { LoginComponent } from './components/auth/login/login.component';
import { LayoutComponent } from 'sepa/framework/src/lib/components/shared/layout/layout.component';
import { ModalService } from './services/modal.service';
import { SharedModule } from './components/shared/shared.module';

const routesChildren: Routes = [
  {
    path: '',
    redirectTo: 'dashboard-admin',
  },
  {
    path: 'dashboard-admin',
    component: DashboardAdminComponent,
  },
  {
    path: 'groups',
    component: GroupsComponent,
  },
  {
    path: 'token-management',
    loadChildren: () =>
      import('./components/token-management/token-management.module').then(
        (m) => m.TokenManagementModule
      ),
  },
  {
    path: 'web3-wallet-management',
    loadChildren: () =>
      import(
        './components/web3-wallet-management/web3-wallet-management.module'
      ).then((m) => m.Web3WalletManagementModule),
  },
  {
    path: 'business_customers',
    loadChildren: () =>
      import('./components/business/business.module').then(
        (m) => m.BusinessModule
      ),
  },
  {
    path: 'individual_customers',
    loadChildren: () =>
      import('./components/individual/individual.module').then(
        (m) => m.IndividualModule
      ),
  },
  {
    path: 'active_customer_request',
    component: ActiveCustomerRequestComponent,
    loadChildren: () =>
      import(
        './components/active-customer-requests/active-customer-requests.module'
      ).then((m) => m.ActiveCustomerRequestsModule),
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./components/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'information-management',
    loadChildren: () =>
      import(
        './components/information-management/information-management.module'
      ).then((m) => m.InformationManagementModule),
  },

  {
    path: 'transaction-history',
    loadChildren: () =>
      import(
        './components/transaction-history/transaction-history.module'
      ).then((m) => m.TransactionHistoryModule),
  },
  {
    path: 'fee-management',
    loadChildren: () =>
      import('./components/fee/fee.module').then((m) => m.FeeModule),
  },
];

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [...routesChildren],
  },
];

const authChildRoutes: Routes = [
  {
    path: '',
    redirectTo: 'login',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
];

@NgModule({
  declarations: [
    GroupsComponent,
    AppComponent,
    DashboardAdminComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SepaFrameworkModule.forRoot({ routes, environment, authChildRoutes }),
    ReactiveFormsModule,
    ComponentsModule,
    ToastModule,
    ButtonModule,
    CardModule,
    DialogModule,
    DropdownModule,
    DividerModule,
    SharedModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    MessageService,
    ConfirmationService,
    ModalService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
