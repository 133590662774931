import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class LoadingService {
  inProgressUrls: BehaviorSubject<string[]> = new BehaviorSubject(
    [] as string[]
  );

  constructor() {}

  addInProgressUrl(url: string) {
    //Todo: add only if url doesn't exist in inprogress urls
    const urls = this.inProgressUrls.value;
    urls.push(url);
    //
    //
    this.inProgressUrls.next(urls);
  }

  removeInProgressUrl(url: string) {
    const urls = this.inProgressUrls.value.filter((u) => u !== url);
    //
    //
    this.inProgressUrls.next(urls);
  }
}
