<div class="grid mt-2">
    <div class="col-12 md:col-4 lg:col-3 flex align-items-center justify-content-start">
        <p-image [src]="utils.logo" alt="Image" [width]="logoWidth" class="ml-4"></p-image>
    </div>
    <div class="col-12 md:col-8 lg:col-9 flex align-items-center justify-content-end">
        <div *ngIf="showFormatSetting" class="flex align-items-center mr-3">
            <label for="languages" class="mr-2">Format Setting</label>
            <p-dropdown id="languages" class="dropdown-sm" [options]="languages" (onChange)="onChangeLanguage($event)"
                optionLabel="name">
            </p-dropdown>
        </div>
        <div style="padding-inline-end:2rem" class="flex">
            <div class="current-user" style="display: flex; align-items: center; justify-content: center;">
                <!-- <i class="pi pi-bell pr-3" style="font-size: 1rem"></i> -->
                <div class="pr-6">{{userName}}</div>
            </div>
            <button pButton pRipple type="button" label="Log Out" class="logout-btn p-button-outlined p-button-sm"
                [style]="{padding: '8px'}" (click)="logoutButton()"></button>
        </div>
    </div>
</div>
<p-dialog [(visible)]="displayModal" [modal]="true" [style]="{width: '25vw'}" [baseZIndex]="10000" [draggable]="false"
    [resizable]="false" [closable]="false">
    <div class="text-center">
        <h3>Logout</h3>
        <p class="p-m-0">Are you sure you want to logout?</p>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" class="p-button-text" label="Yes" (click)="modalButtons(true)"></button>
        <button pButton type="button" class="p-button-text" label="No" (click)="modalButtons(false)"></button>
    </ng-template>

</p-dialog>