import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private evtext = new Subject();
  constructor() { }

  public fireEvent(evname:any,payload:any) {
    this.evtext.next({evname,payload});
  }
  public getEventObservable():Observable<any> {
    return this.evtext.asObservable();
  }
}
