import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, fromEvent, merge, Observable } from 'rxjs';
import {
  debounceTime,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ModalService } from './modal.service';
import { AuthenticationService } from 'sepa/framework/src/lib/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IdleTimeoutService {
  private readonly idleTimeoutMs = 30 * 60 * 1000;
  private readonly warningTimeoutMs = 5 * 60 * 1000;
  private idleEvents$!: Observable<any>;
  private userActivity$ = new Subject<boolean>();
  private stopTimer$ = new Subject<void>();
  private isWarningShown = false;
  private destroy$ = new Subject<void>();
  private warningTimeoutId: any = null;
  private idleTimeoutId: any = null;
  // private countdownIntervalId: any = null;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private ngZone: NgZone,
    private modalService: ModalService
  ) {
    // Subscribe to login status changes and initialize or stop the idle timeout accordingly
    this.authService.userLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoggedIn) => this.handleLoginStatusChange(isLoggedIn));

    // Initialize idle timeout if the user is already logged in (based on localStorage)
    if (this.isUserLoggedIn()) {
      this.initializeIdleTimeout();
    }
  }

  isUserLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem('isUserLoggedIn') || 'false');
  }

  handleLoginStatusChange(isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.initializeIdleTimeout();
    } else {
      this.stopIdleTimeout();
    }
  }

  // Initialize idle timeout events
  initializeIdleTimeout() {
    this.idleEvents$ = merge(
      fromEvent(window, 'mousemove'),
      fromEvent(window, 'keydown'),
      fromEvent(window, 'wheel'),
      fromEvent(window, 'touchmove')
    );

    this.idleEvents$
      .pipe(
        debounceTime(500),
        tap(() => {
          if (this.isUserLoggedIn() && !this.isWarningShown) {
            this.userActivity$.next(true);
          }
        })
      )
      .subscribe();

    this.userActivity$
      .pipe(
        startWith(true),
        switchMap(() => this.startIdleTimer())
      )
      .subscribe();
  }

  // Start idle timer
  private startIdleTimer(): Observable<any> {
    this.clearTimers();
    return new Observable((observer) => {
      this.ngZone.runOutsideAngular(() => {
        const timeLeftForWarning = this.idleTimeoutMs - this.warningTimeoutMs;

        this.warningTimeoutId = setTimeout(() => {
          this.showWarning();
          // this.startCountdown(this.warningTimeoutMs);
        }, timeLeftForWarning);

        this.idleTimeoutId = setTimeout(() => {
          observer.next();
          observer.complete();
        }, this.idleTimeoutMs);

        this.stopTimer$.pipe(takeUntil(this.userActivity$)).subscribe(() => {
          this.clearTimers();
          observer.complete();
        });
      });
    }).pipe(
      tap(() => {
        if (this.isUserLoggedIn()) {
          this.logout();
        }
      })
    );
  }

  // Commented out timer functionality
  // private startCountdown(duration: number) {
  //   const countdown$ = interval(1000).pipe(
  //     takeWhile(() => duration > 0),
  //     tap(() => {
  //       duration -= 1000;
  //       console.log(
  //         `Time left before session expires: ${Math.ceil(
  //           duration / 1000
  //         )} seconds`
  //       );
  //     })
  //   );

  //   this.countdownIntervalId = countdown$.subscribe({
  //     complete: () => this.clearCountdownInterval(),
  //   });
  // }

  // private clearCountdownInterval() {
  //   if (this.countdownIntervalId) {
  //     this.countdownIntervalId.unsubscribe();
  //     this.countdownIntervalId = null;
  //   }
  // }

  // Clear all timers
  clearTimers() {
    if (this.warningTimeoutId) {
      clearTimeout(this.warningTimeoutId);
      this.warningTimeoutId = null;
    }
    if (this.idleTimeoutId) {
      clearTimeout(this.idleTimeoutId);
      this.idleTimeoutId = null;
    }
    // this.clearCountdownInterval();
  }

  // Show warning modal
  showWarning() {
    if (!this.isWarningShown && this.isUserLoggedIn()) {
      this.ngZone.run(() => {
        this.modalService.showModal(
          'Your session is about to expire in < 2 minutes > for security reasons.'
        );
        this.isWarningShown = true;
      });
    }
  }

  // Log out user
  logout() {
    this.ngZone.run(() => {
      this.authService.logout();
      this.router.navigate(['/auth/login']);
      this.isWarningShown = false;
      localStorage.setItem('isUserLoggedIn', JSON.stringify(false));
    });
  }

  // Reset idle timer
  resetTimer() {
    this.clearTimers();
    if (this.isUserLoggedIn()) {
      this.userActivity$.next(true);
      this.isWarningShown = false;
      this.startIdleTimer().subscribe();
    }
  }

  // Stop idle timeout
  public stopIdleTimeout() {
    this.stopTimer$.next();
    this.isWarningShown = false;
    this.modalService.hideModal();
    this.clearTimers();
  }

  // Extend session by resetting the timer
  public extendSession() {
    this.resetTimer();
  }
}
