import { RetryHttpInterceptor } from './retry.interceptor';
/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { BackendHttpInterceptor } from './backend-http.interceptor';
import { LoadingHttpInterceptor } from './loading.interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BackendHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryHttpInterceptor, multi: true }
];
