import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { LoaderService } from 'sepa/framework/src/lib/services/loader.service';
import { wallets } from 'src/app/shared/defaults';
import { HelperService, WalletsService } from 'src/app/shared/services';

@Component({
  selector: 'app-limit',
  templateUrl: './limit.component.html',
})
export class LimitComponent implements OnInit {
  @Input() walletId: string = '';
  loading: boolean = false;
  pageConfig: any = {};
  tableConfig: any = {};
  tableData: any[] = [];
  totalRecords: number = 1;

  constructor(
    private loaderService: LoaderService,
    private helperService: HelperService,
    private walletsService: WalletsService
  ) {}

  ngOnInit(): void {
    let conf = this.loaderService.clientConfig.limits_list;
    this.pageConfig = conf;
    this.tableConfig = conf.tableConfig;

  }

  getWalletsByCustomerId(customerId, walletType) {
    this.loading = true;
    this.walletsService
      .getWalletsByCustomerId(customerId)
      .subscribe((res: any) => {
        console.log(res);
        let wallet = res.find((i) => i.purpose == walletType);
        if (wallet) {
          this.walletId = wallet.id;
        } else {
          this.helperService.notify('error', 'wallet not found');
        }
        this.getLimits();
      });
  }

  getLimits() {
    this.loading = true;
    this.walletsService.getLimits(this.walletId).subscribe((res: any) => {
      console.log(res);
      this.loading = false;
      this.tableData = res;
    });
  }
  onLazyLoad(e) {
    console.log(e);
  }
  selectAllChange(e) {
    console.log(e);
  }
}
