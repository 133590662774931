export * from './PageParams';
export * from './PageParamsServer';
export * from './apis';
export * from './messages';
import  {chartColors1, chartColors2, theme1, theme2 }  from './themes';





export const appGlobals  : any = {
  topTab : "consumer/", //user to set top header tab for "consumer | business"
  countries : []
}

export const theme = theme1; 
export const chartColors = chartColors1;
