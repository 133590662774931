<app-title-card
  [title]="'Groups'"
  [hasButton]="true"
  [buttonOptions]="{ label: 'Add Group', icon: 'pi pi-plus' }"
  (openModal)="openGroupModal()"
>
</app-title-card>
<div class="grid">
  <div class="col-4">
    <p-card>
      <ng-template pTemplate="header">
        <div class="text-xl ml-2 my-2">All groups</div>
      </ng-template>
      <div style="margin-top: -20px">
        <div *ngFor="let group of groups">
          <div class="flex align-items-center justify-content-between">
            <div class="text-base">{{ group.groupName }}</div>
            <div><p-button (click)="openGroupModal(group)">View</p-button></div>
          </div>
          <div><p-divider></p-divider></div>
        </div>
      </div>
    </p-card>
  </div>
</div>

<!-- Display group modal -->
<p-dialog
  [(visible)]="isGroupDisplayed"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '52vw' }"
  [draggable]="false"
  [resizable]="false"
  [modal]="true"
>
  <ng-template pTemplate="header">
    <h2>{{ isViewGroupMode ? "Group Information" : "Add Group" }}</h2>
  </ng-template>

  <div>
    <form [formGroup]="groupForm" (submit)="createUpdateGroup()">
      <div [class]="isViewGroupMode ? 'grid' : 'grid'">
        <div id="name" class="col-3 pt-2 px-5">Group Name</div>
        <div class="col-9 pl-5">
          <input
            type="text"
            class="formInput"
            pInputText
            formControlName="groupName"
            [attr.disabled]="isViewGroupMode ? true : null"
          />
        </div>
        <div class="col-3 pl-5 label">Pages</div>
        <div class="col-9 pl-5 label">Permission</div>
        <ng-container formArrayName="roles">
          <div
            *ngFor="let role of refRolesArray.controls; let i = index"
            [formGroupName]="i"
            class="col-12"
          >
            <div class="grid">
              <div class="col-3 pl-5 item">{{ role.value.componentTitle }}</div>
              <div class="col-9 pl-5">
                <p-dropdown
                  [options]="permissions"
                  optionLabel="title"
                  optionValue="key"
                  formControlName="accessRight"
                >
                </p-dropdown>
              </div>
            </div>
          </div>
          <div class="col-12 mt-3">
            <div class="grid">
              <div class="col-4 item"></div>
              <div class="col-4" style="display: flex; justify-content: center">
                <button
                  (click)="createUpdateGroup()"
                  pButton
                  type="button"
                  [label]="isViewGroupMode ? 'Save' : 'Submit'"
                ></button>
              </div>
              <div class="col-4 item"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </form>

    <div *ngIf="false">
      <div class="col-3 pl-5 label">Username</div>
      <div class="col-9 pl-5 label">Email</div>

      <div *ngFor="let user of currentGroup.users" class="col-12 ml-4">
        <div class="grid">
          <div class="col-3 item">{{ user.name }}</div>
          <div class="col-5 item">{{ user.email }}</div>
          <div class="col-4">
            <button
              pButton
              pRipple
              type="button"
              label="Delete Member"
              class="p-button-danger p-button-text"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<!-- Delete Modal -->

<p-dialog
  [(visible)]="isDeleteDisplayed"
  [breakpoints]="{ '960px': '75vw' }"
  [style]="{ width: '34vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <div class="mr-3">
        <i
          class="pi pi-exclamation-triangle p-error pt-2"
          style="font-size: 5rem"
        ></i>
      </div>
      <div class="ml-3">
        <h2 class="mb-0">Delete member</h2>
        <h4 class="mt-2 p-text-secondary">This action cannot be undone.</h4>
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-end flex-wrap">
      <button
        pButton
        pRipple
        type="button"
        label="Cancel"
        class="p-button-text p-button-plain"
        (click)="closeDeleteModal(false)"
      ></button>
      <button
        pButton
        type="button"
        label="Delete"
        class="p-button-danger"
        (click)="closeDeleteModal(true)"
      ></button>
    </div>
  </ng-template>
</p-dialog>
