import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'active-customer-request',
  templateUrl: './active-customer-request.component.html',
  styleUrls: [],
})
export class ActiveCustomerRequestComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
