import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { MessageService, PrimeNGConfig } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class HelperService {
  public constructor(
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    public datepipe: DatePipe
  ) {
    this.date = new Date();
    this.latest_date = this.datepipe.transform(this.date, 'yyyy-MM-dd h:mm:ss');
  }
  store: any = {};
  date: any;
  latest_date: any;
  notify(type: string, message: string) {
    if (type == 'success') {
      this.messageService.add({severity:'success', summary: 'Success', detail: message});
    }
    if (type == 'error') {
      this.messageService.add({severity:'error', summary: 'Error', detail: message});
    }
  }

  responseProcessor(response: any) {
    if (typeof (response.message) === 'string') {
      this.notify(response.status, response.message);
    } else {
      for (var key in response.message) {
        if (response.message[key])
          this.notify(response.status, response.message[key]);
      }
    }
  }

  randomString(length: number = 10, chars: String = "abcdefghijklmnopqrstuvwxyz") {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  arrayColumn(list: any[], column: string) {
    return list.map(function (item: any) {
      return item[column]
    });
  }
  addProperty(list: any[], obj: any) {
    return list.map(function (el) {
      var o = Object.assign(el, obj);
      return o;
    })
  }

  checkboxToggleAll(list: any[], allChecked: boolean) {
    list.map((item) => { item.checked = allChecked; return item; })
  };
  getChecked(list: any[]) {
    return list.filter((item) => { return item.checked });
  };
  isAllChecked(list: any[]) {
    return list.every((item) => { return item.checked });
  };
  export(onPageArray: any[], filteredArray: any[], fileName = 'export') {
    const onPageCheckedArray = this.getChecked(onPageArray);
    let result: any[] = [];
    if (onPageCheckedArray.length) {
      result = onPageCheckedArray;
    } else {
      result = filteredArray;
    }
    this.downloadFile(result, fileName);
  }
  downloadFile(data: any[], fileName: string) {
    const replacer = (key: any, value: null) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, fileName + '_' + (this.latest_date) + '.csv');
  }

  csvToJSON(csv: string) {
    let allTextLines = csv.split(/\r|\n|\r/);
    let headers = allTextLines[0].split(',');
    let lines: any[] = [];
    for (let i = 1; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(',');
      if (data.length === headers.length) {
        let tarr: any = {};
        for (let j = 0; j < headers.length; j++) {
          tarr[headers[j]] = data[j].split("\"").length === 1 ? data[j] : data[j].split("\"")[1];
        }
        // console.log(tarr);
        // console.log(this.deepen(tarr));
        // lines.push(tarr);
        lines.push(this.deepen(tarr));
      }
    }
    return lines;
  }

  deepen(obj: any) {
    const result = {};

    // For each object path (property key) in the object
    for (const objectPath in obj) {
      // Split path into component parts
      const parts = objectPath.split('.');

      // Create sub-objects along path as needed
      let target = result;
      while (parts.length > 1) {
        const part = parts.shift() || "";
        target = target[part] = target[part] || {};
      }

      // Set value at end of path
      target[parts[0]] = isNaN(obj[objectPath]) ? obj[objectPath] : parseInt(obj[objectPath])
    }

    return this.objectToArray(result);
  }
  objectToArray(obj: any) {
    if (typeof obj !== 'object') {
      return obj
    }
    for (let key in obj) {
      if (typeof obj[key] === 'object') {
        let childKeys = Object.keys(obj[key])
        if (!isNaN(+childKeys[0])) {
          let array = childKeys.map((k) => { return this.objectToArray(obj[key][k]) });
          obj[key] = array
        } else {
          obj[key] = this.objectToArray(obj[key])
        }
      }
    }
    return obj
  }

  togglebyId(elemId: string) {
    var element = document.getElementById(elemId);
    element?.classList.toggle("d-none")
  }

  dateWithoutTime(dateTime: { getTime: () => string | number | Date; }) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
  }

  measureStrength(pass: string) {
    let score = 0;
    // award every unique letter until 5 repetitions
    let letters = {};
    for (let i = 0; i < pass.length; i++) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }
    // bonus points for mixing it up
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;
    for (let check in variations) {
      variationCount += (variations[check]) ? 1 : 0;
    }
    score += (variationCount - 1) * 10;
    return Math.trunc(score);
  }

  stringTest(pass: string, key: string | number) {
    let variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    return variations[key];
  }

  objectToCsv(obj) {
    var json = [obj]
    var fields = Object.keys(json[0])
    var replacer = function (key, value) { return value === null ? '' : value }
    var csv = json.map(function (row) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column
    let result = csv.join('\r\n');
    console.log(csv)
    return result;
  }
  jsonToKeyValue(jsonobj, prefix?) {
    var newobj = {};
    function recurse(o, p) {
      for (var f in o) {
        var pre = (p === undefined ? '' : p + ".");
        if (o[f] && typeof o[f] === "object") {
          newobj = recurse(o[f], pre + f);
        } else {
          newobj[pre + f] = o[f];
        }
      }
      return newobj;
    }
    return recurse(jsonobj, prefix);
  }
  jsonTOCsv(jsonobj, prefix?){
    return this.objectToCsv(this.jsonToKeyValue(jsonobj, prefix))
  }

  checkUndefined(value, defaultValue){
    if(value===undefined){
      return defaultValue===undefined ? "" : defaultValue
    }
    return value;
  }

  convertDataFormat(data){
    let d = new Date(data);
    return [
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2),
      d.getFullYear(),
    ].join('/');
  }

  keyValueToArray(obj){
    let res: any[] = [];
    for(let key in obj){
      res.push({key,...obj[key]})
    }
    return res
  }
}
