<app-title-card [title]="'General Overview'"></app-title-card>
<div class="grid">
    <div class="col-6">
        <app-card [titleCard]="'Customers by month'" [basicData]="barMonthData"></app-card>
    </div>
    <div class="col-6">
        <app-card [titleCard]="'Customers by country'" [basicData]="barCountryData"></app-card>
    </div>
    <div class="col-6">
        <app-card [titleCard]="'Customers by business'" [basicData]="barBusinessData"></app-card>
    </div>
    <div class="col-6">
        <app-card [titleCard]="'Customers by KYC status'" [basicData]="pieCustomerData" [isPieChart]="true"></app-card>
    </div>
</div>