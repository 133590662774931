export class PageParams {
    inputArray		    = [];
    filteredArray : any[]   = [];
    onPageArray : any[]     = [];
    searchKeys		    = [];
    searchValue		    = '';
    pageSize	        = 10;
    pageIndex           = 0;
    orderKey		    = 'popularity';
    orderType		    = 'desc';
    filters : any       = {};

    hasPreviousPage() {
        return this.pageIndex >= 1 && this.pageSize != 0;
    };
    hasNextPage() {
        var maxPageIndex = this.getNumberOfPages() - 1;
        return this.pageIndex < maxPageIndex && this.pageSize != 0;
    };
    getNumberOfPages() {
        if (!this.pageSize) {
            return 0;
        }
        return Math.ceil(this.resultAfterFilter().length / this.pageSize);
    };

    changePageSize(pageSize) {
        this.pageIndex = 0;
        this.pageSize = pageSize;
    }
    nextPage () {
        if (!this.hasNextPage()) {
            return;
        }
        this.pageIndex++;
    };
    previousPage() {
        if (!this.hasPreviousPage()) {
            return;
        }
        this.pageIndex--;
    };

    resultAfterFilter(){
        var res:any[] = this.inputArray;
        if(this.searchKeys.length && this.searchValue){
            res = res.filter((item)=>{
                return this.searchKeys.some(key=>{
                    return item[key].toString().toLowerCase().includes(this.searchValue.toString().toLowerCase())
                });
            });
        }
        res = this.customeFilter(res);
        this.filteredArray = this.checkPageIndexIsInRange(res);
        return this.filteredArray

    }
    customeFilter(res){
        return res;
    }
    checkPageIndexIsInRange(res : any[]){
        // console.log(this.pageIndex*this.pageSize > res.length/this.pageSize);
        if(this.pageIndex*this.pageSize > res.length){
            this.pageIndex = 0;
            return this.resultAfterFilter();
        }
        return res;
    }
    resultAfterPagination(){
        this.onPageArray = this.resultAfterFilter().slice((this.pageIndex)*this.pageSize, (this.pageIndex+1)*this.pageSize);
        return this.onPageArray
    }


}
