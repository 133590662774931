<app-title-card
  [title]="'Assets'"
>
</app-title-card>
<!-- [columns]="tableConfig?.columns" -->
<div class="">
  <p-table
  #assets1
  [value]="mappedAssets"
  [globalFilterFields]="['purpose', 'type', 'instanceName']"
  [resizableColumns]="false"
  [autoLayout]="true"
  [loading] = "loading"
>
  <ng-template pTemplate="caption">
    <div class="p-d-flex">
      <span class="p-input-icon-left p-ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          #textInput
          type="text"
          (input)="assets1.filterGlobal(textInput.value, 'contains')"
          placeholder="Search Assets"
        />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th>Type</th>
      <th>Currency Unit</th>
      <th>Status</th>
      <th>Current Balance</th>
      <th>Available Balance</th>
      <th>Last Updated</th>
      <th>Edit</th>
      <!-- <th>Operations</th> -->
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowAsset>
    <tr>
      <td>{{rowAsset.type}}</td>
      <td>{{rowAsset.instanceName}}</td>
      <td>{{rowAsset.status}}</td>
      <td>{{rowAsset.currentBalance}}</td>
      <td>{{rowAsset.availableBalance}}</td>
      <td>{{rowAsset.updatedAt | date: 'MM/dd/yyyy'}}</td>
      <td><p-button
        label="Edit"
        (click)="onEditClick(rowAsset)"
      ></p-button>
      </td>
      <!-- <td><p-button
        label="Details"
        (click)="onOperationsClick(rowAsset)"
      ></p-button>
      </td> -->
    </tr>
    <!-- <tr>
      <td *ngFor="let col of columns">
        <div
          *ngIf="
            col.field.includes('.');
            then nested_object_content;
            else normal_content
          "
        ></div>
        <ng-template #nested_object_content>
          {{
            helperService.checkUndefined(
              rowData[col.field.split(".")[0]][col.field.split(".")[1]],
              col.default
            )
          }}
        </ng-template>
        <ng-template #normal_content>
          {{ helperService.checkUndefined(rowData[col.field], col.default) }}
        </ng-template>
        <p-button
          *ngIf="col.isButton"
          label="{{ col.buttonName }}"
          (click)="onActionClick(rowData)"
        ></p-button>
      </td>
    </tr> -->
  </ng-template>
</p-table>
</div>

<p-dialog
  [(visible)]="isEditAssetModelOpen"
  [breakpoints]="{ '640px': '60vw' }"
  [style]="{ width: '25vw', height: '65vh' }"
  [blockScroll]="true"
  (onHide)="onEditCancel($event)"
>
  <ng-template pTemplate="header">
    <h2>Edit asset</h2>
  </ng-template>
  <app-edit-asset-model [editingAsset]="editingAsset" [cancelEditClicked]="closeEditClicked"
  [walletId]="walletId" (onEditClose)="onEditAssetFinish($event)"></app-edit-asset-model>
  <!-- <ng-template pTemplate="footer">
    <div class="flex justify-content-end flex-wrap">
      <button pButton type="button" label="Create"></button>
    </div>
  </ng-template> -->
</p-dialog>
