import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class MainCardComponent implements OnInit {


  @Input() isPieChart = false;
  @Input() isProgressBar = false;
  @Input() titleCard = '';
  @Input() basicData: any;
  @Input() supportCounter: any;
  @Input() email: any;
  @Output() seletedEmail = new EventEmitter<string>();

  basicOptions: any;

  data: any;

  chartOptions: any;

  constructor() { }

  ngOnInit(): void {

    this.basicOptions = {
      plugins: {
        legend: {
          labels: {
            color: 'var(--text-color)'
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: 'var(--text-color)'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        },
        y: {
          ticks: {
            color: 'var(--text-color)'
          },
          grid: {
            color: 'rgba(255,255,255,0.2)'
          }
        }
      }
    }
  }

  emitData() {
    this.seletedEmail.emit(this.email);
  }

}
@NgModule({
  imports: [
    CardModule,
    BrowserModule,
    BrowserAnimationsModule,
    ProgressBarModule,
    ChartModule,
    CommonModule,
    BrowserModule
  ],
})
export class MainCardModule {
}

