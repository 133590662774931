import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ApiHttpService } from './api-http.service';
import { ToastNotificationService } from 'sepa/framework/src/lib/services/toast-notification.service';
import { Store } from 'sepa/framework/src/lib/services/store.service';
import { environment } from 'src/environments/environment'; // Import environment
import { apis } from '../defaults/apis';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<any | null>(null);
  public currentUser$ = this.currentUserSubject.asObservable();
  private userLoggedIn = new BehaviorSubject<boolean>(this.isLoggedIn());

  constructor(
    private http: ApiHttpService,
    private toastNotificationService: ToastNotificationService,
    private router: Router,
    private store: Store
  ) {}

  getApiManagerToken(): Observable<any> {
    const url = apis.admin.getApiManagerToken;
    const auth = environment.apiManager.auth;
    const headers = {
      Authorization: `Basic ${btoa(auth.username + ':' + auth.password)}`,
    };
    const body = { grant_type: 'client_credentials' };

    return this.http.post(url, body, { headers }).pipe(
      tap((res: any) => {
        const apiManagerToken = res.access_token;
        this.store.apimToken = `Bearer ${apiManagerToken}`;
        localStorage.setItem('apiManagerToken', apiManagerToken);
      }),
      catchError((err) => {
        this.toastNotificationService.setNotification({
          severity: 'error',
          detail: 'Failed to get API manager token. Please try again.',
        });
        return throwError(err);
      })
    );
  }

  onLogin(email: string, password: string): Observable<any> {
    return this.getApiManagerToken().pipe(
      switchMap(() => this.login(email, password))
    );
  }

  private login(email: string, password: string): Observable<any> {
    const data = {
      credentials: [
        {
          credentialType: 'username',
          value: email.trim(),
          code: password.trim(),
          communicationChannel: {
            name: 'string',
            type: 'web_admin',
            addressUrl: '',
          },
        },
      ],
    };

    return this.http.post(apis.oauth.login(), data).pipe(
      switchMap((response: any) => {
        const token = response.token;
        if (token) {
          this.store.userToken = token;
          this.store.updateLocalStorage('userToken', token);
          localStorage.setItem('isUserLoggedIn', 'true');
          this.userLoggedIn.next(true);
          return this.getSessionData(token);
        } else {
          this.toastNotificationService.setNotification({
            severity: 'error',
            detail: 'Failed to retrieve token from login response',
          });
          return throwError('Failed to retrieve token');
        }
      }),
      catchError((err) => {
        this.handleLoginError(err);
        return throwError(err);
      })
    );
  }

  private getSessionData(token: string): Observable<any> {
    return this.http.get(apis.oauth.session(token)).pipe(
      tap((sessionResponse: any) => {
        const userInfo = sessionResponse.userInfo;
        this.storeUserData(userInfo, token);
      }),
      catchError((err) => {
        this.handleSessionError(err);
        return throwError(err);
      })
    );
  }

  private storeUserData(userInfo: any, token: string) {
    this.currentUserSubject.next(userInfo);

    this.store.personId = userInfo.personId;
    this.store.userId = userInfo.userId;
    this.store.tenantId = userInfo.tenantId;
    this.store.credentials = userInfo.credentials;
    this.store.orgId = userInfo.organizationId;
    this.store.customerData.next(userInfo);

    localStorage.setItem('customerData', userInfo);
    this.store.updateLocalStorage('customerId', userInfo.customerId);
    this.store.updateLocalStorage('userId', userInfo.userId);
    this.store.updateLocalStorage('personId', userInfo.personId);
    this.store.updateLocalStorage('tenantId', userInfo.tenantId);
    this.store.updateLocalStorage('orgId', userInfo.organizationId);
    this.userLoggedIn.next(true);
  }

  logout() {
    this.http
      .post(apis.oauth.logout(), {})
      .pipe(
        catchError((err) => {
          this.toastNotificationService.setNotification({
            severity: 'error',
            detail:
              err.error?.description ||
              'Something went wrong. Please try again later.',
          });
          return throwError(err);
        }),
        finalize(() => {
          const apimToken = this.store.apimToken || '';
          this.clearUserData();
          this.store.updateLocalStorage('apimToken', apimToken);
          this.router.navigate(['/auth/login']); // Redirect to login page
        })
      )
      .subscribe();
  }

  private clearUserData() {
    localStorage.removeItem('currentUser');
    this.store.updateLocalStorage('userToken', '');
    this.currentUserSubject.next(null);
    this.userLoggedIn.next(false);
    localStorage.setItem('isUserLoggedIn', 'false');
  }

  isLoggedIn(): boolean {
    return this.store.loggedIn;
  }

  private handleLoginError(err: any) {
    console.error('Error from login:', err);
    this.toastNotificationService.setNotification({
      severity: 'error',
      detail: err.error?.description || 'Login failed. Please try again.',
    });
  }

  private handleSessionError(err: any) {
    console.error('Error fetching session data:', err);
    this.toastNotificationService.setNotification({
      severity: 'error',
      detail: err.error?.description || 'Failed to retrieve session data.',
    });
  }
}
