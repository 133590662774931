import { filter } from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { LoaderService } from 'sepa/framework/src/lib/services/loader.service';
import { CustomerService, HelperService, WalletsService } from 'src/app/shared/services';

export interface MappedAsset {
  purpose: any,
  type?: any,
  instanceName?: any,
  status?: any,
  currentBalance?: any,
  availableBalance?: any,
  createdAt?: any,
  updatedAt?: any,
  id?: any,
}

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
})
export class AssetsComponent implements OnInit {

  @Input() walletId: string = ""
  loading: boolean = false;
  pageConfig: any = {};
  tableConfig: any = {};
  tableData: any[] = [];
  totalRecords: number = 1;
  walletType:string = ""
  routeType:string = ""
  customerId:string = ""

  isAssetModelOpen = false
  isEditAssetModelOpen = false
  closeClicked = false;
  closeEditClicked = false;
  editingAsset:any = null

  assets: any;
  mappedAssets: MappedAsset[] = [];
  IsCustomerTenant;
  tenantWallets: any;

  constructor(
    private loaderService: LoaderService,
    public helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private walletsService: WalletsService,
    private router: Router,
    private customerService: CustomerService,
    fb:FormBuilder
  ) {
    let conf = this.loaderService.clientConfig.initProvider.tenant_wallets;
    this.tenantWallets = conf;
    this.InitializeWallets();


  }

  ngOnInit(): void { }

  InitializeWallets(): void {
    this.routeType = this.activatedRoute.snapshot.data["routeType"]

      this.customerService.currentIsCustomerTenantStatus.subscribe(status => {
        this.IsCustomerTenant = status;
        console.log('IsCustomerTenant status', this.IsCustomerTenant)
      })

    combineLatest([
      this.activatedRoute?.params,
      this.activatedRoute.parent?.parent?.params
    ]).subscribe((params: any) => {
      let walletType = params[0].walletType || params[1].walletType
      this.walletType = walletType
      this.customerId = params[1].customerId
      // if (this.customerId) {
      //   this.getWalletsByCustomerId(this.customerId, walletType)
      // } else
      this.loading = true;
      if(this.IsCustomerTenant == 'i_n') {
        this.getWalletsByCustomerId(this.customerId, walletType)
      }
      else if(this.IsCustomerTenant == 'b_n') {
        console.log('business customer path assets', this.customerId)
        this.getWalletsByOrgId(this.customerId, walletType)
      }
      else {
        this.walletId = this.tenantWallets[walletType] || "";
        this.getAssets();
      }
      console.log('activated route', params)
    })
  }

  getWalletsByCustomerId(customerId, walletType) {
    this.walletsService.getWalletsByCustomerId(customerId)
      .subscribe((res: any) => {
        console.log('get wallet by cust id response',res);
        let wallet = res.find(i => i.purpose == walletType)
        if (wallet) {
          this.walletId = wallet.id
        } else {
          // this.walletId = wallets['general-hot']
          this.helperService.notify("error","wallet not found");
        }
        this.getAssets();
      });
    //this.getAssets();
  }

  getWalletsByOrgId(orgId, walletType) {
    //this.loading = true;
    this.walletsService.getWalletsByOrgId(orgId)
      .subscribe((res: any) => {
        console.log('wallt by orgId from asset component',res);
        let wallet = res.find(i => i.purpose == walletType) //web3, general_purpose, spot_trading
        if (wallet) {
          this.walletId = wallet.id
        } else {
          this.helperService.notify("error","wallet not found");
        }
        this.getAssets();
      });
  }

  getAssets() {
    //this.loading = true;
    this.mappedAssets = [];
    this.walletsService.getAssets(this.walletId)
      .subscribe((res: any) => {
        this.loading = false;
        this.assets = res;
        console.log('assets from be', this.assets)
        if (this.assets) {
          this.mapAssets(this.assets);
          this.mappedAssets.sort(function(a,b){
              return b.type -a.type
            });
        }
      });
    //this.tableData = this.assetFakeData;
  }

  // map response assets into table fields
  mapAssets(assets: any[]) {

    for (const asset of assets) {

      const cardsAssetShortName = asset.allowedOperations?.length ? asset.allowedOperations[0].operationDestination?.operationAddresses?.length ? asset.allowedOperations[0].name ?? '' : '' : '';
      const mappedAsset: MappedAsset = {
        purpose: asset.purpose,
        type: asset.type,
        instanceName:  asset.instanceBase?.shortname ?? cardsAssetShortName,
        status: asset.status,
        currentBalance: asset.currentBalance?.value ?? '0',
        availableBalance: asset.availableBalance?.value ?? '0',
        createdAt: asset.createdAt,
        updatedAt: asset.updatedAt,
        id: asset.id.entityId.id,
      };
      //this.mappedAssets = mappedAsset;
      this.mappedAssets.push(mappedAsset);
    }
  }

  onLazyLoad(e) { console.log(e) };
  selectAllChange(e) { console.log(e) };

  // onActionClick(event: any) {
  //   let fixedUrl = ""
  //   switch(this.routeType){
  //     case "business" : {
  //       fixedUrl = `shared/business_customers/business_customers_details/${this.customerId}/wallet/fee-operations`
  //       break
  //     }
  //     case "individual" : {
  //       fixedUrl = `shared/individual_customers/individual_customers_details/${this.customerId}/wallet/fee-operations`
  //       break
  //     }
  //     default:{
  //       fixedUrl = `shared/fee-operations`
  //       break
  //     }
  //   }

  //   console.log(event)
  //   switch(event.eventName){
  //     case "redirect-details":{
  //       console.log(fixedUrl,this.walletType, event.row.id)
  //       //this.router.navigate([fixedUrl,this.walletType, event.row.id]);
  //       break
  //     }
  //     case "edit":{
  //       console.log(event)
  //       this.addEditAsset(event.row)
  //       break
  //     }

  //   }
  // }



  onCancel(event: any) {

  }
  onEditCancel(event: any) {

  }

  onOperationsClick(event: any) {
    let fixedUrl = ""
    switch(this.routeType){
      case "business" : {
        fixedUrl = `shared/business_customers/business_customers_details/${this.customerId}/wallet/fee-operations`
        break
      }
      case "individual" : {
        fixedUrl = `shared/individual_customers/individual_customers_details/${this.customerId}/wallet/fee-operations`
        break
      }
      default:{
        fixedUrl = `shared/fee-operations`
        break
      }
    }

    //console.log('operations click',fixedUrl,this.walletType, event.id)
    // shared/fee-operations/general-hot/id
    this.router.navigate([fixedUrl,this.walletType, event.id]);
  }

  // onActionClick(row: any, eventName: string) {
  //   console.log(row)
  //   switch(eventName){
  //     case "redirect-details":{
  //       console.log(eventName)
  //       this.router.navigate(['shared/business_customers/business_customers_details',row.id]);
  //       break
  //     }
  //   }
  // }

  addAssetFake() {
    this.tableData.push({
        "purpose": "spot-trading",
        "type":"crypto-currency",
        "customerName":"XETH",
        "status":"new",
        "assetBalances1":"500",
        "assetBalancesAvailable":"1200",
        "createdAt":"",
        "updatedAt":"",
        "createdBy":"John Doe"

    })
  }

  addAsset(asset){
    this.isAssetModelOpen = true
    //this.editingAsset = asset || null
  }

  onEditClick(event: any) {
    console.log('edit clicked',event)
    this.isEditAssetModelOpen = true;
    //this.editingAsset = event || null
    //this.addEditAsset(event.row)

    // setTimeout(() => {
    //   this.editingAsset = event || null
    // }, 0);

    // better to send the item to edit via service..
    this.walletsService.setAsset(event);
  }

  onAddAssetFinish(event) {
    setTimeout(() => {
      this.isAssetModelOpen = false;
    }, 500);
  }

  onEditAssetFinish(event) {
    setTimeout(() => {
      this.isEditAssetModelOpen = false;
    }, 500);
  }

}
