import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { from, iif, Observable, of } from "rxjs";
import { map, mergeMap, take } from "rxjs/operators";
import { AuthenticationService } from "../services/authentication.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authenticationService.isAuthenticated().pipe(
      mergeMap((isLogged) =>
        iif(
          () => isLogged,
          of(true),
          from(this.authenticationService.isAuthenticatedOnServer()).pipe(
            map((serverLogged) => {
              if (serverLogged) {
                return true;
              } else {
                if (route.data && route.data["failRedirect"]) {
                  this.router.navigate([route.data["failRedirect"]]);
                }
                return true;
              }
            })
          )
        )
      )
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class AuthCheckGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authenticationService.isAuthenticated().pipe(
      mergeMap((isLogged) =>
        iif(
          () => isLogged,
          of(true),
          from(this.authenticationService.isAuthenticatedOnServer()).pipe(
            map((serverLogged) => {
              if (serverLogged) {
                if (route.data) {
                  this.router.navigate([route.data["successRedirect"]]);
                }
              } else {
              }
              return true;
            })
          )
        )
      )
    );
  }
}
