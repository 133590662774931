import { CursorError } from '@angular/compiler/src/ml_parser/lexer';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'sepa/framework/src/lib/services/event.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private events: EventsService,
    private router: Router
    ) {
      this.events.getEventObservable().subscribe(event => {
        if (event.evname === "individual_customers:details") {
          this.router.navigate(['shared/individual_customers/individual_customers_details']);
        }
        if (event.evname === "business_customers:details") {
          this.router.navigate(['shared/business_customers/business_customers_details']);
        }
        if (event.evname === "currency_management:edit") {
          alert("Modify currency pairs for exchange Dialog");
        }
        if (event.evname === "targets:edit") {
          alert("Modify targets value Dialog");
        }
      });
    }
}
