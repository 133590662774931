<form [formGroup]="editFormGroup" (ngSubmit)="onEditFormSubmit()">
  <div class="ui-fluid ui-corner-all modal-body m-1 m-lg-2 pt-0">
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="type" class="block">Type</label>
      </div>
      <div class="ui-g-12">
        <input placeholder="Type" formControlName="type" pInputText />
      </div>
    </div>
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="instance" class="block">Currency Unit</label>
      </div>
      <div class="ui-g-12">
        <input placeholder="Currency Unit" formControlName="instance" pInputText />
      </div>
    </div>
    <div class="ui-g row mx-2 col">
      <div class="ui-g-12 mb-2">
        <label for="status" class="block">Status</label>
      </div>
      <div class="ui-g-12">
        <p-dropdown [options]="editFormOptions.assetStatus" formControlName="status" placeholder="Select Status"
                        optionLabel="name"></p-dropdown>
                        <ng-container *ngIf="editFormGroup.get('status')?.touched || editFormSubmited">
                          <small class="p-error block"
                              *ngFor="let errorMsg of editFormGroup.controls['status'].errors | keyvalue">
                              {{"Status" | errortostring : errorMsg}}
                          </small>
                      </ng-container>
      </div>
    </div>
    <div style="display:flex; justify-content: center; padding-top: 2rem;">
      <button pButton type="submit" label="Update"></button>
    </div>

  </div>
    <!-- <div class="modal-body m-1 m-lg-2 pt-0">
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="type" class="block">Type</label>
                    <input placeholder="Type" formControlName="type" pInputText />
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="instance" class="block">Currency Unit</label>
                    <input placeholder="Currency Unit" formControlName="instance" pInputText />
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="status" class="block">Status</label>
                    <p-dropdown [options]="editFormOptions.assetStatus" formControlName="status" placeholder="Select Status"
                        optionLabel="name"></p-dropdown>
                        <ng-container *ngIf="editFormGroup.get('status')?.touched || editFormSubmited">
                          <small class="p-error block"
                              *ngFor="let errorMsg of editFormGroup.controls['status'].errors | keyvalue">
                              {{"Status" | errortostring : errorMsg}}
                          </small>
                      </ng-container>
                </div>
            </div>
        </div>
        <div style="display:flex; justify-content: center; margin-right: 2rem">
          <button pButton type="submit" label="Update"></button>
        </div>

    </div> -->
</form>
