import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    public http : ApiHttpService,
  ) { }

  allTickets = [];
    getAllTickets(pageObject){
        const url = apis.ticket.getAllTickets;
        return this.http.post(url,pageObject).pipe(
            tap((res:any)=>this.allTickets = res.data)
        );
    }

    getTicketById(ticketId){
        const body = {ticketId};
        const url = apis.ticket.getTicketById;
        return this.http.post(url, body);
    }

    create(ticketObj){
        const body = ticketObj;
        const url = apis.ticket.create;
        return this.http.post(url, body);
    }

    updateTicketById(ticketObj){
        const body = ticketObj;
        const url = apis.ticket.updateTicketById;
        return this.http.put(url, body);
    }

    deleteTicketById(ticketId){
        const url = apis.ticket.deleteTicketById;
        return this.http.delete(url, {ticketId : ticketId});
    }
}
