// Tenant Ids
// Cyrexa = 3f945056-aff1-11ec-b909-0242ac120002
// BLK = 45412fc4-aff1-11ec-b909-0242ac120002
// Seven Group = 498ab974-aff1-11ec-b909-0242ac120002
// Paladis = 4cf48a72-aff1-11ec-b909-0242ac120002
// SEPA Cyber = 5085340c-aff1-11ec-b909-0242ac120002

import {tenant} from "./tenants"
export const environment = {
  production: false,
  serverUrl: tenant.host.jsonServerUrl,
  proxyUrl: tenant.host.proxyServerUrl,
  etherScanUrl: tenant.host.etherScanUrl,
  polygonScanUrl: tenant.host.polygonScanUrl,
  // serverUrl: 'https://microservices-json-db-admin-v2.local.sepa-cyber.com/',
  // mockDataUrl: 'https://microservices-json-db-admin-v2.local.sepa-cyber.com/',

  serverPrefix: '',
  tokenKey: 'api-token',
  idKey: 'userId',
  ...tenant
};
