export enum HttpMethod {
  Get,
  Post,
  Delete,
  Put,
  Head,
  Patch
}

export enum HttpStatusCode {
  Ok = 200,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  ClientClosedRequest = 499,
}

export class ApiEndpoint {
  public route: string;
  public method: HttpMethod;
  public options?: any;
  public urlParams?: any;
  public queryParams?: any;
  public bodyParams?: string[];
  public dontUseServerUrl: boolean; // used for testing purposes. If you set it to true only the route will be used as the endpoint url,
  // so you can use it for local tests.
  public apiVersion?: string;

  constructor(route: string, method: HttpMethod, options?: any, urlParams?: any, queryParams?: any, bodyParams?: any,
    dontUseServerUrl: boolean = false, apiVersion?: string) {
    this.route = route;
    this.method = method;
    this.options = options;
    this.urlParams = urlParams;
    this.queryParams = queryParams;
    this.bodyParams = bodyParams;
    this.dontUseServerUrl = dontUseServerUrl;
    this.apiVersion = apiVersion
  }
}

export class HttpRequestData {
  public urlParams?: Object;
  public urlAppend?: String;
  public queryParams?: Object;
  public bodyParams?: Object;


  constructor() {
    this.urlParams = new Object();
    this.queryParams = new Object();
    this.bodyParams = new Object();
    this.urlAppend = new String;
  }
}
