import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { STATIC_DI } from "../staticdi";
import { Store } from "./store.service";
import { InstrumentationService } from "./instrumentation.service";
import { BackendApiService } from "./backend-api.service";

@Injectable()
export class LoaderService {
  public env: any = STATIC_DI.ENVIRONMENT;
  private _clientConfig: any = {};

  constructor(
    private http: HttpClient, 
    private store: Store,
    private instrumentationService: InstrumentationService,
    private api: BackendApiService,
    ) {}

  get clientConfig() {
    return this._clientConfig;
  }

  loadConfig(): Observable<any> {
    return this.http.get(`tenants/${this.env.tenant}.json`);
  }

  setClientConfigs(clientConfig: any) {
    this._clientConfig = clientConfig;
    const initProvider = this._clientConfig.initProvider;
    this.store.tenantId = initProvider.tenantId;
    this.store.defaultOrgId = initProvider.defaultOrgId;
    this.store.application = initProvider.application;
    this.api.initApi();

    // set client info for logging
    const customerData = this.store.customerData.value;
    const tags = [
      {
        key: "application",
        value: this.store.application,
      },
      {
        key: "tenantId",
        value: this.store.tenantId,
      },
    ];
    this.instrumentationService.configureSentry(customerData, tags, []);
  }

}
