import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { TitleCardComponent } from '../cards/title-card/title-card.component';
import { MainCardComponent } from '../elements/card/card.component';

@NgModule({
  declarations: [MainCardComponent, TitleCardComponent],
  imports: [
    CardModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressBarModule,
    ChartModule,
    CommonModule,
  ],
  exports: [MainCardComponent, TitleCardComponent],
})
export class ComponentsModule {}
