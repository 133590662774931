<p-card *ngIf="!supportCounter && !email" [style]="{height: '26rem'}">

    <ng-template pTemplate="header" class="pb-0">
        <div class="text-xl p-2" style="background-color:'var(--surface-border)'">
            {{titleCard}}
        </div>
    </ng-template>

    <!--Bar Chart-->
    <p-chart *ngIf="basicData && !isPieChart && !isProgressBar" type="bar" [data]="basicData" [options]="basicOptions"
        [height]="'300'"></p-chart>

    <!--Pie Chart-->
    <p-chart *ngIf="basicData && isPieChart && !isProgressBar" type="pie" [data]="basicData" [height]="'300'"></p-chart>

    <!-- Progress Bar-->
    <div *ngIf="isProgressBar && !isPieChart" style="height: 308px">
        <div *ngFor="let ticket of basicData" class="mb-4">
            <h5 class="mt-0 mb-2">{{ticket.label}}</h5>
            <p-progressBar [value]="ticket.value"></p-progressBar>
        </div>
    </div>
</p-card>

<p-card *ngIf="supportCounter && !email" [style]="{height: '8rem'}">
    <ng-template pTemplate="header" class="pb-0">
        <div class="text-xl p-2" style="background-color:'var(--surface-border)'">
            {{titleCard}}
        </div>
    </ng-template>
    <div class="flex justify-content-end">
        <div style="margin-top: -26px; margin-left: auto;" class="text-5xl">{{supportCounter}}</div>
    </div>
</p-card>

<p-card *ngIf="email && !supportCounter">
    <ng-template pTemplate="header">
            <div class="text-xl ml-2">
                {{titleCard}}
            </div>
            <div style="margin-left: auto;">
                <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-text" (click)="emitData()"></button>
            </div>
    </ng-template>
    <div class="flex justify-content-start">
        <div style="margin-top: -20px; font-size: 1.2rem; font-weight: 500;" >{{email}}</div>
    </div>
</p-card>