<div class="grid no-margin no-padding">
  <div class="col-12" style="background-color:'var(--surface-card)'">
    <app-header></app-header>
  </div>
  <div class="col-12">
    <div class="grid">
      <div class="col-12 md:col-3 lg:col-2">
        <app-sidemenu></app-sidemenu>
      </div>
      <div class="col-12 md:col-9 lg:col-10">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>