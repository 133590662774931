import { PrimeNGConfig } from 'primeng/api';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Country } from '../models/country.model';

@Injectable({ providedIn: 'root' })
export class LanguageService {

    selectedLanguage = new Subject<Country>();

    constructor(private config: PrimeNGConfig, private translateService: TranslateService) {
        this.config.setTranslation({
            accept: 'Accept',
            reject: 'Cancel',
            //translations
        });
        this.translateService.setDefaultLang('en');
    }

    changeLanguage(lang: Country) {
        // Todo: add validation match check based on supported languages
        this.translateService.use(lang.languageCode);
        this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
        this.selectedLanguage.next(lang);
    }
}