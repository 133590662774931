import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

    constructor(
        public http : ApiHttpService,
    ) { }

    getCustomersByCountry(filters = {}){
        const url = apis.chart.pie.customersByCountry;
        return this.http.get(url,filters);
    }

    getCustomersByKycStatus(filters = {}){
        const url = apis.chart.pie.customersByKycStatus;
        return this.http.get(url,filters);
    }

    getCustomersByBusinesss(filters = {}){
        const url = apis.chart.pie.customersByBusinesss;
        return this.http.get(url,filters);
    }

    getCustomersByMonth(filters = {}){
        const url = apis.chart.bar.customersByMonth;
        return this.http.post(url,filters);
    }

}
