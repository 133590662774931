
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
})
export class ConfirmationDialogComponent implements OnInit {

  public data:any;
  mobileMedia:any=window.matchMedia("(max-width:520px)")
  constructor(public config: DynamicDialogConfig,public ref: DynamicDialogRef) { 
    this.config.width=this.getSize()
  }

  ngOnInit(): void {
    console.log(this.config.data)
    this.data = this.config.data
  }

  public getSize(){
    if (this.mobileMedia.matches) {
      return '95%'
    }else{
      return '30%';
    }
  }

  public onCloseDialog(){
    this.ref.close();
  }

}
