import { filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, OnDestroy, Type } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { messages } from 'src/app/shared/defaults';
import { HelperService, WalletsService } from 'src/app/shared/services';

export interface MappedCurrency {
    Purpose: any,
    Type: any,
    Status: any,
    AliaseId: any,
    CurrencyId: any
  //   instanceBase: aliase.id,
  //   availableBalance: {
  //     balanceType: 'available',
  //     name: 'currency',
  //     value: '0',
  //     instanceRef: currency.id,
  //     instanceBaseRef: aliase.id,
}

@Component({
  selector: 'app-edit-asset-model',
  templateUrl: './edit-asset-model.component.html'
})
export class EditAssetModelComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();
  @Input() editingAsset: any;
  @Input() walletId: any;
  @Input() cancelEditClicked: any;
  @Output() onEditClose = new EventEmitter<any>();
  currencyInstances: any;
  mappedCurrencies: MappedCurrency[] = [];
  assetToEdit: any;

  editFormOptions = {
    assetType : [
      {name: 'FIAT Currency', code: 'fiat', type:'currency' },
      {name: 'Crypto Currency', code: 'crypto', type: 'crypto-currency'},
      // {name: 'Bank-Card', code: 'bank-card'},
      // {name: 'Transport-Card', code: 'transport-card'},
      // {name: 'ID-Card', code: 'id-card'},
      // {name: 'QR-Card', code: 'qr-code'},
      // {name: 'Linked-Account', code: 'linked-account'},
      // {name: 'Linked-External-Account', code: 'linked-external-account'},
    ],
    instance: [
      {name: 'Ether', type: 'crypto'},
      {name: 'Bitcoin', type: 'crypto'},
      {name: 'EURO', type: 'fiat'},
    ],
    assetStatus:[
      {name: 'New', code: 'new'},
      {name: 'Active', code: 'available'},
      {name: 'InActive', code: 'pending'},

    ]
  }

  isEditMode = true;
  editFormGroup!: FormGroup;
  editFormSubmited = false;

  constructor(
    private fb: FormBuilder,
    public walletsService: WalletsService,
    private helperService: HelperService
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {


    this.subscriptions.add(
      this.walletsService.getCurrencies().subscribe(currencies => {
        this.currencyInstances = currencies;
        //this.mapCurrencies(this.currencyInstances);
        console.log('currency instances',this.currencyInstances)
      })
    );
    this.subscriptions.add(
      this.walletsService.currentAsset.subscribe(asset => {
        this.assetToEdit = asset;
        const selectedType = this.assetToEdit.type;
        //const instancesOfSelectedType =this.editFormOptions.instance.filter(c => c.name == this.assetToEdit.instanceName)
        const selectedInstance = this.assetToEdit.instanceName;
        console.log('selected instance disabled to be', selectedInstance)
        this.editFormGroup = this.fb.group({
          type: [{value: selectedType, disabled: true}],
          instance: [{value: selectedInstance, disabled: true}],
          status: ["", [Validators.required]]
        });
        console.log('asset to edit',this.assetToEdit)
        console.log('form edit', this.editFormGroup)
      })
    );

  }

  filterByType(option: any) {
    console.log('selectedType',option)
    const filteredOptions = this.currencyInstances.filter(c => c.type == option.code)
    console.log('filteredOptions',filteredOptions)
    this.editFormOptions.instance = filteredOptions;
  }



  ngOnChanges(changes: SimpleChanges): void {
    //this.isEditMode = false;
    // let currentValue = changes['editingAsset'].currentValue;
    // if (currentValue) {
    //   console.log('current value', currentValue)
    //   const filteredAsset = this.editFormOptions.assetType.filter(a => a.type == currentValue.type)
    //   const filteredCurrencies = this.currencyInstances.filter(c => c.type == filteredAsset[0].code)
    //   // filter from assetTypes by assettype.type == currentvalue.type.
    //   //filter from currencyInstatces by cuurInst.type ==  assettype.code
    //   console.log('filteredCurrencies',filteredCurrencies)
    //   const selectedCurrency = this.currencyInstances.filter(c => c.type == currentValue.instanceName)
    //   console.log('selected currency', selectedCurrency)
    //   //this.editFormOptions.instance = filteredCurrencies;
    // }
    //let currentCancelStatus = changes['cancelEditClicked'].currentValue;
    // if(currentCancelStatus) {
    //     this.formSubmited = false;
    //     this.editFormGroup.reset();
    //     this.editFormGroup.markAsPristine();
    //     this.editFormGroup.controls['purpose'].setValue('spot-trading');
    // }
  }

  onEditFormSubmit() {
    this.editFormSubmited = true;
    let formData = this.editFormGroup.getRawValue();
    console.log('edit asset form data',formData)

    if (this.editFormGroup.status == 'VALID') {
      ;
      let httpService;
      let successMessage;
      if (this.isEditMode) {
        const assetId = this.assetToEdit.id;
        const payload = {
          purpose: formData.purpose,
          status: formData.status.code,
        };
        console.log('edit asset payload', payload);
        //httpService = this.walletsService.createAsset(this.walletId,payload);
        httpService = this.walletsService.updateWalletAssetById(this.walletId, assetId, payload);
        successMessage = messages.success.updateAssetById;
      }
      httpService.subscribe((res: any) => {
        if(res.isOk) {
          this.helperService.notify('success', successMessage);
          this.onEditClose.emit('success');
        }
        else {
          this.helperService.notify('error', 'Unable to edit asset');
          this.onEditClose.emit('close');
        }
        //this.modalRef.hide();
      }, (error) => {
        this.helperService.notify('error', 'Unable to edit asset');
        this.onEditClose.emit('close');
      });
      this.onEditClose.emit('close');
      setTimeout(() => {
        this.editFormSubmited = false;
      }, 500);
    }
    //console.log('submit', this.formGroup.value)
  }


}

function getAliaseId(instance: any) {
  let currencyAliasId = '';
    switch (instance.name) {
      case 'Ether':
        currencyAliasId = instance.aliases.find((currencyAlias: any) => currencyAlias.name == 'Ether')?.id ?? '';
        break;
      case 'Bitcoin':
        currencyAliasId = instance.aliases.find((currencyAlias: any) => currencyAlias.name == 'BTC')?.id ?? '';
        break;
      case 'EURO':
        currencyAliasId = instance.aliases.find((currencyAlias: any) => currencyAlias.name == 'EUR')?.id ?? '';
        break;
    }
    return currencyAliasId;
}

