import { LoadingStatus } from "./models/app-enums.model";
import { Component, OnInit } from "@angular/core";
import { AccessGroupsService } from "./services/access-groups.service";
import { AuthenticationService } from "../public-api";
import { Store } from "./services/store.service";

@Component({
  selector: "sepa-framework",
  templateUrl: "./sepaframework.component.html",
})
export class SepaFrameworkComponent implements OnInit {
  LoadingStatus = LoadingStatus;
  loadingStatus = LoadingStatus.loading;

  constructor(
    private store: Store,
    private accessGroupsService: AccessGroupsService, 
    private authenticationService: AuthenticationService
    ) {}

  ngOnInit(): void {
    this.authenticationService.initValues();
    if (this.store.userId) this.getUserAccess();
    else this.loadingStatus = LoadingStatus.success;
  }

  getUserAccess() {
    this.accessGroupsService.getUserAccessGroups().subscribe({
      next: (response) => {
        this.loadingStatus = LoadingStatus.success;
        this.accessGroupsService.setUserAccessGroups(response);
      },
      error: (error) => {
        this.loadingStatus = LoadingStatus.error;
      },
    });
  }
}
