<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="modal-body m-1 m-lg-2 pt-0">
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="purpose" class="block">Purpose</label>
                    <input id="purpose" placeholder="Enter Purpose" formControlName="purpose"
                        aria-describedby="username2-help" pInputText />
                    <ng-container *ngIf="formGroup.get('purpose')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['purpose'].errors | keyvalue">
                            {{"Purpose" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="type" class="block">Type</label>
                    <p-dropdown #type1 [options]="formOptions.assetType" formControlName="type" placeholder="Select a Type"
                        optionLabel="name" (onClick)="filterByType(type1.selectedOption)"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('type')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['type'].errors | keyvalue">
                            {{"Type" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="instance" class="block">Instance</label>
                    <p-dropdown [options]="formOptions.instance" formControlName="instance"
                        placeholder="Select Instance" optionLabel="name"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('instance')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['instance'].errors | keyvalue">
                            {{"Instance" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="col">
                <div class="">
                    <label for="status" class="block">Status</label>
                    <p-dropdown [options]="formOptions.assetStatus" formControlName="status" placeholder="Select Status"
                        optionLabel="name"></p-dropdown>
                    <ng-container *ngIf="formGroup.get('status')?.touched || formSubmited">
                        <small class="p-error block"
                            *ngFor="let errorMsg of formGroup.controls['status'].errors | keyvalue">
                            {{"Status" | errortostring : errorMsg}}
                        </small>
                    </ng-container>
                </div>
            </div>
        </div>
        <div style="display:flex; justify-content: center; margin-right: 2rem">
          <button pButton type="submit" label="Create"></button>
        </div>

    </div>
</form>
