import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { LoaderService } from 'sepa/framework/src/lib/services/loader.service';
import { CustomerService, HelperService, WalletsService } from 'src/app/shared/services';

export interface MappedOperation {
  assetId: any,
  assetType?: any,
  createdAt?: any, //
  id?: any,
  asset?: any,  //Asset column -> name
  operationType?: any, //operation column
  updatedAt?: any, //
  operationDestination: any // Destination column -> operationDestination.source
}

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html'
})
export class OperationsComponent implements OnInit {

  @Input() walletId: string = ""
  loading: boolean = false;
  pageConfig: any = {};
  tableConfig: any = {};
  tableData: any[] = [];
  totalRecords: number = 1;
  walletType:string = ""
  routeType:string = ""
  customerId:string = ""

  isOperationModelOpen = false
  isEditOperationModelOpen = false
  isAddOperationModelOpen = false
  editingOperation:any = null

  operations: any;
  mappedOperations: MappedOperation[] = [];
  IsCustomerTenant;
  tenantWallets: any;

  constructor(
    private loaderService: LoaderService,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private walletsService: WalletsService,
    private router: Router,
    private customerService: CustomerService,
  ) { }

  ngOnInit(): void {
    let conf = this.loaderService.clientConfig.initProvider.tenant_wallets;
      this.tenantWallets = conf;
      this.initializeWalletOperations();
  }

  initializeWalletOperations(): void {
    this.routeType = this.activatedRoute.snapshot.data["routeType"]

      this.customerService.currentIsCustomerTenantStatus.subscribe(status => {
        this.IsCustomerTenant = status;
        console.log('IsCustomerTenant status', this.IsCustomerTenant)
      })


    combineLatest([
      this.activatedRoute?.params,
      this.activatedRoute.parent?.parent?.params
    ]).subscribe((params: any) => {
      let walletType = params[0].walletType || params[1].walletType
      this.walletType = walletType
      this.customerId = params[1].customerId
      // if (this.customerId) {
      //   this.getWalletsByCustomerId(this.customerId, walletType)
      // } else
      this.loading = true;
      if(this.IsCustomerTenant == 'i_n') {
        this.getWalletsByCustomerId(this.customerId, walletType)
      }
      else if(this.IsCustomerTenant == 'b_n') {
        console.log('business customer path assets', this.customerId)
        this.getWalletsByOrgId(this.customerId, walletType)
      }
      else {
        this.walletId = this.tenantWallets[walletType] || "";
        this.getOperations();
      }
      console.log(params)
    })
  }

  getWalletsByCustomerId(customerId, walletType) {
    this.walletsService.getWalletsByCustomerId(customerId)
      .subscribe((res: any) => {
        console.log(res);
        let wallet = res.find(i => i.purpose == walletType)
        if (wallet) {
          this.walletId = wallet.id
        } else {
          // this.walletId = wallets['general-hot']
          this.helperService.notify("error","wallet not found");
        }
        this.getOperations();
      });
    //this.getOperations();
  }

  getWalletsByOrgId(orgId, walletType) {
    this.walletsService.getWalletsByOrgId(orgId)
      .subscribe((res: any) => {
        //console.log(res);
        let wallet = res.find(i => i.purpose == walletType) //web3, general_purpose, spot_trading
        if (wallet) {
          this.walletId = wallet.id
        } else {
          this.helperService.notify("error","wallet not found");
        }
        this.getOperations();
      });
  }


  fakeOpData= [
   {
     "name":"XETH",
     "operationType":"buy",
     "description":"",
     "source":"test",
     "destination":"test",
     "createdAt":"",
     "updatedAt":"",
     "createdBy":"3eferoo5eeh32n2234nnn",
   },{
    "name":"XETH",
    "operationType":"sell",
    "description":"",
    "source":"",
    "destination":"",
    "createdAt":"",
    "updatedAt":"",
    "createdBy":"",
   }
  ];

  getOperations() {
    this.mappedOperations = [];
    this.walletsService.getOperations(this.walletId)
      .subscribe((res: any) => {
        this.loading = false;
        console.log(res);
        this.operations = res;
        if(this.operations) {
          this.mapOperations(this.operations);
          console.log('mappedOperations: ', this.mappedOperations);
        }
        //this.tableData = res;
      });
    //this.tableData = this.fakeOpData;
  }

  //map response operations to table fields
  mapOperations(operations: any[]) {
    for(const operation of operations) {
      const mappedOperation: MappedOperation = {
        assetId: operation.assetId,
        assetType: operation.assetType,
        createdAt: operation.createdAt, //
        id: operation.id,
        asset: operation.name,  //Asset column -> name
        operationType: operation.operationType, //operation column
        updatedAt: operation.updatedAt, //
        operationDestination: operation?.operationDestination?.direction // Destination column -> operationDestination.source
      };
      this.mappedOperations.push(mappedOperation);
    }
  }

  onLazyLoad(e) { console.log(e) };
  selectAllChange(e) { console.log(e) };

  onActionClick(event: any) {
    let fixedUrl = ""
    switch(this.routeType){
      case "business" : {
        fixedUrl = `shared/business_customers/business_customers_details/${this.customerId}/wallet/fee-operations`
        break
      }
      case "individual" : {
        fixedUrl = `shared/individual_customers/individual_customers_details/${this.customerId}/wallet/fee-operations`
        break
      }
      default:{
        fixedUrl = `shared/fee-operations`
        break
      }
    }

    console.log(event)
    switch(event.eventName){
      case "redirect-fees":{
        this.router.navigate(['shared/management-fee',this.walletType, event.row.id]);
        break
      }
      case "redirect-limits":{
        this.router.navigate(['shared/management-limits',this.walletType, event.row.id]);
        break
      }
      case "edit":{
        this.addEditOperation(event.row)
        break
      }
    }
  }

  addEditOperation(operation){
    this.isOperationModelOpen = true
    this.editingOperation = operation || null
  }

  addOperation(operation){
    this.isAddOperationModelOpen = true
    this.editingOperation = operation || null
  }

  onAddOperationFinish(event) {
    setTimeout(() => {
      this.isAddOperationModelOpen = false;
    }, 500);
  }

  onEditClick(operation: any) {
    this.isEditOperationModelOpen = true
    this.editingOperation = operation || null
  }

  onEditOperationFinish(event) {
    setTimeout(() => {
      this.isEditOperationModelOpen = false;
    }, 500);
  }

  onFeesClick(event: any) {

  }
  onLimitsClick(event: any) {

  }
}
