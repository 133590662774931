import { LoadingStatus } from "../../../models/app-enums.model";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { STATIC_DI } from "../../../staticdi";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.css"],
})
export class LoadingComponent implements OnInit {
  @Input() loadingStatus!: LoadingStatus;
  @Output() onLoadAnyWay = new EventEmitter();
  LoadingStatus = LoadingStatus;
  environment = STATIC_DI.ENVIRONMENT;

  constructor() {}

  ngOnInit(): void {}
}
