import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CurrencyManagementService {

  constructor(
    public http : ApiHttpService,
  ) { }

  allCurrency = [];
    getAllCurrency(pageObject){
        const url = apis.currency.getAllCurrency;
        return this.http.post(url,pageObject).pipe(
            tap((res:any)=>this.allCurrency = res.data)
        );
    }

    getCurrencyById(currencyId){
        const body = {currencyId};
        const url = apis.currency.getCurrencyById;
        return this.http.post(url, body);
    }

    create(currencyObj){
        const body = currencyObj;
        const url = apis.currency.create;
        return this.http.post(url, body);
    }

    updateCurrencyById(currencyObj){
        const body = currencyObj;
        const url = apis.currency.updateCurrencyById;
        return this.http.put(url, body);
    }

    deleteCurrencyById(currencyId){
        const url = apis.currency.deleteCurrencyById;
        return this.http.delete(url, {currencyId : currencyId});
    }
}
