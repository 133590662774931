import { Subscription } from "rxjs";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { VerificationService } from "../../../services/verification.service";
import { AccessGroupsService } from "../../../services/access-groups.service";
import { LoaderService } from "../../../services/loader.service";
import { AccessRights } from "../../../models/app-enums.model";
import { ComponentRole } from "../../../models/access-group.model";

@Component({
  selector: "app-sidemenu",
  templateUrl: "./sidemenu.component.html",
  styleUrls: ["./sidemenu.component.scss"],
})
export class SidemenuComponent implements OnInit, OnDestroy {

  userAccessSubscription?: Subscription;
  items: any[] = [];
  applicationType = 'b2b_wallet';


  constructor(
    private ls: LoaderService,
    private verificationService: VerificationService,
    private accessGroupService: AccessGroupsService
  ) { }

  ngOnInit() {
    this.applicationType = this.ls.clientConfig.initProvider.application;
    this.setSideMenuItems();
    this.items = this.ls.clientConfig.sideMenu?.data?.items ?? this.items;
    this.userAccessSubscription =
      this.accessGroupService.subscribeUserAccessRoles((newAccessRoles) =>
        this.updateMenuItemsWithRoles(newAccessRoles)
      );
  }

  setSideMenuItems() {
    this.items = [
      {
        "label": "Account",
        "icon": "pi pi-pw pi-home",
        "routerLink": [
          "account"
        ]
      }, {
        "label": "Payment",
        "icon": "pi pi-wallet",
        "routerLink": "payment"
      }, {
        "label": "Profile",
        "icon": "pi pi-user",
        "routerLink": "profile/business-details",
        "items": [
          this.applicationType === 'b2b-Wallet' ? { "label": "Business Details", "routerLink": "profile/business-details" } : {
            "label": "Personal Details",
            "routerLink": "profile/personal-detail"
          }
        ]
      }, {
        "label": "Settings",
        "icon": "pi pi-cog",
        "routerLink": "settings"
      }, {
        "label": "Notifications",
        "icon": "pi pi-bell",
        "routerLink": "notifications"
      }, {
        "label": "About us",
        "icon": "pi pi-envelope",
        "routerLink": "aboutus"
      }, {
        "label": "Support",
        "icon": "pi pi-comment",
        "routerLink": "support"
      }
    ];
  }

  onMenuSelected(event: any) {
    this.verificationService.sideMenuOptionSelected.next({
      url: event.target.href,
      pageName: event.target.innerText,
    });
  }

  updateMenuItemsWithRoles(accessRoles: ComponentRole[]) {
    for (const userRole of accessRoles) {
      // Remove menu item if only the application matches and the role is no visiblity

      if (
        this.ls.clientConfig.initProvider?.application?.trim() &&
        userRole.application?.startsWith(this.ls.clientConfig.initProvider?.application) &&
        userRole.accessRight == AccessRights.no_visibility
      ) {
        this.items = this.items.filter(
          (menuItem: any) => menuItem.label != userRole.componentName
        );
        // check for nested menu items
        for (const menuItem of this.items) {
          const hasChildMenus = menuItem.items?.length;
          if (hasChildMenus) {
            menuItem.items = menuItem.items.filter(
              (menuItem: any) => menuItem.label != userRole.componentName
            );
          }

          // if the menu item had child menus, but if now all are removed, remove the parent menu ite too.
          if (hasChildMenus && !menuItem.items?.length) {
            this.items = this.items.filter(
              (item: any) => item.label != menuItem.label
            );
          }
        }
      }
    }
  }



  ngOnDestroy(): void {
    this.userAccessSubscription?.unsubscribe();
  }
}
