import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { HelperService } from 'src/app/shared/services';

@Component({
  selector: 'sepa-table',
  templateUrl: './sepa-table.component.html',
  styleUrls: ['./sepa-table.component.css']
})
export class SepaTableComponent implements OnInit,OnChanges {

  @Input() leadingTableIcons?: TemplateRef<any>;
  @Input() trailingTableIcons?: TemplateRef<any>;
  @Input() customCaptionComponent?: TemplateRef<any>;

  @Output() onLazyLoad = new EventEmitter<any>() ;
  @Output() selectAllChange = new EventEmitter<any>() ;
  @Output() onAction = new EventEmitter<any>() ;
  
  @Input() loading: boolean = true;
  @Input() pageConfig: any = {  };
  @Input() tableConfig: any = {};
  @Input() tableData: any[] = [];
  @Input() totalRecords: number = 0;

  public columns!: {
    name: string,
    field: string,
    default: string,
    subfielddata: string,
    sort: string,
    isButton: boolean,
    buttonName: string,
    buttonEvent: string
  }[];

  apiEndpoints: any;
  title?: string;
  filterList?: string[];
  captionCheckboxText?: string;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public helperService: HelperService,
  ) {


  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void{
    // console.log(this.tableConfig);
    this.columns =this.tableConfig?.columns;
  }

  clear(table: Table) {
    table.clear();
  }

  onActionClick(row: any, eventName: string) {
    this.onAction.emit({eventName, row});
  }

}
