import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-container',
  templateUrl: './wallet-container.component.html'
})
export class WalletContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
