import { Injectable } from '@angular/core';
import { apis } from 'src/app/shared/defaults';
import { ApiHttpService } from 'src/app/shared/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FeeService {

    constructor(
        public http : ApiHttpService,
    ) { }

    allFees = [];
    getAllFees(pageObject){
        const url = apis.fee.getAllFees;
        return this.http.post(url,pageObject).pipe(
            tap((res:any)=>this.allFees = res.data)
        );
    }

    getFeeById(feeId){
        const body = {feeId};
        const url = apis.fee.getFeeById;
        return this.http.post(url, body);
    }

    create(feeObj){
        const body = feeObj;
        const url = apis.fee.create;
        return this.http.post(url, body);
    }

    updateFeeById(feeObj){
        const body = feeObj;
        const url = apis.fee.updateFeeById;
        return this.http.put(url, body);
    }

    deleteFeeById(feeId){
        const url = apis.fee.deleteFeeById;
        return this.http.delete(url, {feeId : feeId});
    }
}
