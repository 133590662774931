<p-dialog
  [(visible)]="display"
  header="Session Expiring Soon"
  [modal]="true"
  [closable]="false"
  [dismissableMask]="false"
  [baseZIndex]="10000"
  [style]="{ width: '30rem' }"
  [draggable]="false"
  [resizable]="false"
  [autoZIndex]="true"
  [blockScroll]="true"
  [focusOnShow]="true"
  [transitionOptions]="'150ms cubic-bezier(0, 0, 0.2, 1)'"
  contentStyleClass="modal__content modal__content--warning"
>
  <div class="modal__body">
    <p class="modal__message modal__message--warning">
      Your session is about to expire in <strong>< 5 minutes ></strong> for
      security reasons.
    </p>
    <p>To continue, please extend your session.</p>
  </div>
  <p-footer class="modal__footer">
    <button
      type="button"
      class="modal__button modal__button--logout"
      (click)="onLogout()"
    >
      Logout
    </button>
    <button
      type="button"
      class="modal__button modal__button--extend"
      (click)="onExtendSession()"
    >
      Extend Session
    </button>
  </p-footer>
</p-dialog>
